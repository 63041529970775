<template>
   <div class="autocomplete">
      <div class="input-group">
         <input type="text" 
            @blur="onChange"
            @input="onDigit"
            v-model="search"
            :disabled="arrowCounter != 0"
            @click="showAll"
            ref="inputAutoComplete"
            placeholder="Pesquisar..." 
            class="form-control" />
            <span class="input-group-text" type="button" @click="clearInput">
               <span class="material-icons-outlined" style="font-size: 14px;">{{ iconType }}</span>
            </span>
      </div>
      <ul id="autocomplete-results" v-show="isOpen" ref="scrollContainer" class="autocomplete-results">
         <li class="loading" v-if="isLoading">Carregando...</li>
         <li ref="options" 
            v-else v-for="(result, i) in filterResults" :key="i" @click="setResult(result, i)" 
            class="autocomplete-result" 
            :class="{ 'is-active': i === arrowCounter }">
               {{ result }}
         </li>
      </ul>
   </div>
</template>

<script>
export default {
   name: 'Autocomplete',
   props: {
      items: {
         type: Array,
         required: true,
      },
      isAsync: {
         type: Boolean,
         required: false,
         default: false
      }
   },

  data() {
      return {
         isOpen: false,
         results: [],
         resultsFiltered : [],
         search: "",
         isLoading: false,
         arrowCounter: 0,
         iconType: "expand_more",
      };
  },

   methods: {
      onChange() {
         this.$emit("input", this.search);
         this.$emit("change", this.search);
      },

      onDigit() {
         const categories = this.items;
         const parteOfName = this.removeAccents(this.search.toLowerCase());
         this.resultsFiltered = categories.filter(category => this.removeAccents(category.toLowerCase()).includes(parteOfName));
         this.isOpen = true;
      },

      setResult(result, i) {
         this.arrowCounter = i;
         this.search = result;
         this.isOpen = false;
         this.resultsFiltered = [];
         this.iconType = "close";
         this.onChange();
      },

      clearInput() {
         this.search = "";
         this.arrowCounter = 0;
         this.iconType = "expand_more";
         this.showAll();
         this.onChange();
      },

      showAll() {
         this.isOpen = !this.isOpen;
         (this.isOpen) ? this.results = this.items : this.results = [];
      },

      removeAccents(str) {
         return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      },
   },

   computed: {
      filterResults() {
         return (this.search.length > 0) ? this.resultsFiltered : this.results;
      },
   },

   watch: {
      items: function(val, oldValue) {
         if (val.length !== oldValue.length) {
            this.results = val;
            this.isLoading = false;
         }
      }
   },

   mounted() {
      document.addEventListener("click", this.handleClickOutside);
   },

   destroyed() {
      document.removeEventListener("click", this.handleClickOutside);
   }
}
</script>

<style lang="css" scoped>
.input-group-text {
   background-color: rgb(248, 248, 248);
}

.autocomplete {
  position: relative;
  width: 100%;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: 140px;
  overflow: auto;
  width: 100%;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: #eee;
}
</style>