<template>
   <div class="d-flex">
      <SidebarLeft />
      <div class="pages">
         <div class="container-fluid">
            <div class="page-title">
               <h5>Editar Material</h5>
               <span>{{ $store.state.library.name }}</span>
            </div>
            <div class="row mb-4">
               <div class="card" style="padding:20px">
                  <form @submit.prevent="updateBook" method="post" enctype="multipart/form-data">

                     <div class="row mb-4">
                        <div class="col-md-2">
                           <div v-if="coverFilePreview" class="text-center mb-2">
                              <img :src="coverFilePreview" alt=""
                                 style="width:120px; height:120px; object-fit:cover; border-radius:12px;">
                           </div>
                           <div v-else-if="book.cover">
                              <img :src="book.cover" alt="" style="width: 80px; height: 90px; background-size: cover;">
                           </div>
                           <div v-else
                              style="height:100px; width:150px; background-color: #d3d3d3; border-radius: 12px">
                              <span class="material-icons-round"
                                 style="position:absolute; left:80px; top:58px">menu_book</span>
                           </div>
                        </div>

                        <!-- Cover from url -->
                        <div v-if="book.cover" class="col-md-6 mb-3">
                           <label for="cover">URL da Capa</label>
                           <div class="mb-3">
                              <input id="cover" v-model="book.cover" placeholder="Cole a URL aqui" required
                                 class="form-control">
                           </div>
                        </div>

                        <!-- Cover from upload -->
                        <div v-else class="col-md-6 mb-3">
                           <div>
                              <label for="cover-input">Upload da capa</label>
                              <input id="cover-input" type="file" class="form-control" @change="coverUpload($event)">
                           </div>
                        </div>

                        <div class="col-md-4">
                           <label for="call_number">Número de chamada</label>
                           <input id="call_number" class="form-control" disabled :value="book.call_number">
                        </div>
                     </div>

                     <div class="row">
                        <div class="col-md-12 mb-3">
                           <label for="title" class="text-danger">Título da obra</label>
                           <input id="title" v-model="book.title" placeholder="Título da obra" required
                              class="form-control">
                        </div>

                        <div class="col-md-12 mb-3">
                           <label for="subtitle">Subtítulo</label>
                           <input id="subtitle" v-model="book.subtitle" placeholder="Subtítulo" class="form-control">
                        </div>

                        <div class="col-md-8 mb-3">
                           <label for="author" class="text-danger">Autor</label>
                           <input id="author" v-model="book.author" placeholder="Autor" class="form-control">
                        </div>

                        <div class="col-md-4 mb-3">
                           <label for="cutter">Cutter</label>
                           <input id="cutter" v-model="book.cutter" placeholder="Código Cutter" class="form-control">
                        </div>

                        <div class="col-md-12 mb-3">
                           <label for="authors_others">Outros autores (separado por vírgula e espaço)</label>
                           <input id="authors_others" v-model="book.authors_others" placeholder="Outros autores"
                              class="form-control">
                        </div>

                        <div class="col-md-4 mb-3">
                           <label for="isbn_code">Código ISBN</label>
                           <input id="isbn_code" v-model="book.isbn_code" placeholder="Código ISBN"
                              class="form-control">
                        </div>

                        <div class="col-md-4 mb-3">
                           <label for="year" class="text-danger">Ano de Publicação</label>
                           <input id="year" v-model="book.year" placeholder="Ano de Publicação" class="form-control">
                        </div>

                        <div class="col-md-4 mb-3">
                           <label for="" class="text-danger">Editora</label>
                           <input type="text" v-model="book.publisher" placeholder="Editora" class="form-control">
                        </div>

                        <div class="col-md-4 mb-3">
                           <label for="place_of_publication">Local de Publicação</label>
                           <input id="place_of_publication" class="form-control" v-model="book.place_of_publication">
                        </div>

                        <div v-if="$store.state.library.classification_type === 'cdu'" class="col-md-4 mb-3">
                           <label class="text-danger" for="cdu_category_id">Classificação CDU</label>
                           <select v-model="book.cdu_category_id" id="cdu_category_id" class="form-select">
                              <option v-for="item in cduCodes" :key="item.id" :value="item.id"
                                 :selected="item.cdu_category_id === book.cdu_category_id">
                                 {{ `${item.code} - ${item.name}` }}
                              </option>
                           </select>
                        </div>

                        <div v-if="$store.state.library.classification_type === 'cdd'" class="col-md-4 mb-3">
                           <label class="text-danger" for="">Classificação Dewey</label>
                           <select class="form-select" v-model="book.dewey_category_id">
                              <option v-for="option in dewey" v-bind:value="option.id" v-bind:key="option.id">
                                 {{ option.name }}
                              </option>
                           </select>
                        </div>

                        <div v-if="$store.state.library.classification_type === 'cdd'" class="col-md-4 mb-3">
                           <label for="">Classificação Centesimal Dewey</label>
                           <input type="text" v-model="book.dewey_centesimal" class="form-control">
                        </div>

                        <div class="col-md-4 mb-3">
                           <label for="copies">Número de Cópias</label>
                           <input id="copies" :value="book.copies" disabled class="form-control">
                        </div>

                        <div class="col-md-4 mb-3">
                           <label class="text-danger" for="">Pode ser emprestado</label>
                           <select class="form-select" v-model="book.available">
                              <option value="1">Sim</option>
                              <option value="0">Não</option>
                           </select>
                        </div>

                        <div class="col-md-4 mb-3">
                           <label for="">Páginas</label>
                           <input type="text" v-model="book.total_pages" class="form-control">
                        </div>
                        <div class="col-md-4 mb-3">
                           <label for="">Edição</label>
                           <input type="string" v-model="book.edition" class="form-control">
                        </div>
                        <div class="col-md-4 mb-3">
                           <label for="">Idioma</label>
                           <input type="text" v-model="book.language" class="form-control">
                        </div>
                        <div class="col-md-12 mb-3">
                           <label for="">Resumo da obra (até 240 caracteres)</label>
                           <textarea 
                              v-model="book.resume" 
                              rows="2" 
                              placeholder="Você pode falar sobre este material em até 240 caracteres" 
                              maxlength="240" 
                              class="form-control">
                           </textarea>
                        </div>
                        <div class="col-md-12 mb-3">
                           <label for="">Descrição Física</label>
                           <textarea v-model="book.description" rows="2" class="form-control"></textarea>
                        </div>
                        <div class="col-md-12 mb-3">
                           <label for="">Anotações Internas</label>
                           <textarea v-model="book.internal_notes" rows="2" class="form-control"></textarea>
                        </div>
                        <div class="col-md-12 mt-2">
                           <button type="button" @click="$router.push('/acervo')" class="btn btn-outline-secondary"
                              style="margin-right:15px">Cancelar</button>
                           <button type="button" @click="update" class="btn btn-primary">Atualizar</button>
                        </div>
                     </div>
                  </form>
               </div>
            </div>

         </div>
      </div>
   </div>
</template>

<script>
import Env from '../../../env.js'
import SidebarLeft from '../../components/SidebarLeft.vue'

export default {
   name: 'AcervoEditarComponent',
   components: {
      SidebarLeft
   },
   data() {
      return {
         book: {
            cdu_code: null,
            cdu_category_id: null,
            cover: '',
            cover_file: null,
            cutter: null,
            place_of_publication: null,
            resume: null,
            available: null,
         },
         dewey: [],
         cduCodes: [],
         coverUploaded: false,
         coverFilePreview: null,
      }
   },

   created() {
      this.dewey = Env.Dewey;
      this.fetchBook();
      this.fetchCduCategories();
   },

   methods: {

      async fetchBook() {
         this.$vs.loading()

         try {
            const response = await this.$axios.get(`/libraries/books/${this.$route.params.id}`)
            this.book = response.data;
            if (this.book.available === true) {
               this.book.available = 1;
            }
            if (this.book.available === false) {
               this.book.available = 0;
            }
            this.coverUploaded = (response.data.cover != null) ? true : false;

            this.$vs.loading.close();

         } catch (error) {
            this.$vs.loading.close();

            this.$vs.notify({
               title: 'Erro',
               text: error.response.data.data.message,
               color: 'danger',
               position: 'top-right'
            })
         }
      },

      async coverUpload(event) {

         if (event.target.files[0].type != 'image/jpeg' && event.target.files[0].type != 'image/png') {
            return this.notificationError({ title: "Formato inválido", message: "Deve ser uma imagem JPG ou PNG" });
         }

         if (event.target.files[0].size > 2097152) {
            return this.notificationError({ title: "Tamanho inválido", message: "A imagem deve ser menor que 2MB" });
         }

         this.book.cover_file = event.target.files[0];
         this.coverFilePreview = URL.createObjectURL(event.target.files[0]);
      },

      async fetchCduCategories() {
         this.$vs.loading()

         try {
            const response = await this.$axios.get(`/libraries/cdu-categories`);
            this.cduCodes = response.data;
            this.$vs.loading.close();

         } catch (error) {
            console.log(error);
            this.notificationError({ message: error.response.data.data.message });
         }
      },

      async update() {
         this.$vs.loading();

         try {

            const headers = { 'Content-Type': 'multipart/form-data' };

            const formData = new FormData();
            formData.append('_method', 'PUT');
            for (const key in this.book) {
               if (!this.book[key]) continue;
               if (key == 'list_copies' || key == 'collections') continue;
               formData.append(key, this.book[key]);
            }

            if (this.book.cover_file) {
               formData.delete('cover');
            }

            const response = await this.$axios.post(`/libraries/books/${this.$route.params.id}`, formData, { headers });

            this.$vs.notify({
               title: 'Sucesso',
               text: response.data.data.message,
               color: 'success',
               position: 'top-right'
            });
            this.$vs.loading.close();

         } catch (error) {
            this.$vs.notify({
               title: 'Erro',
               text: error.data.data.message,
               color: 'danger',
               position: 'top-right'
            });
            this.$vs.loading.close();
         }
      },
   },
}
</script>

<style lang="scss" scoped></style>
