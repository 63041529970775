<template>
  <div class="d-md-flex">
    <SidebarLeft />
    <div class="pages">
      <div class="container-fluid">
        <div class="page-title">
          <h5>Leitores</h5> 
          <span>{{ $store.state.library.name }}</span>
        </div>
        <div class="row mb-4">
          <div class="card" style="padding:20px">
            <form @submit.prevent="search" class="row">
              <div class="col-md-5">
                <label for="document">Nome ou usuário</label>
                <input id="document" v-model="searchQuery.name" placeholder="Buscar..." class="form-control">
              </div>
              <div class="col-md-2">
                <label for="filter">Filtro</label>
                <select id="filter" class="form-select" v-model="searchQuery.filter">
                  <option value="name">Nome</option>
                  <option value="username">Usuário</option>
                </select>
              </div>
              <div class="col-md-3">
                <label for="status">Status</label>
                <select id="status" class="form-select" v-model="searchQuery.status" @change="search">
                  <option value="all">Todos</option>
                  <option value="pending">Pendente</option>
                  <option value="active">Aprovado</option>
                  <option value="removed">Removido</option>
                </select>
              </div>
              <div class="col-md-2">
                <div class="d-grid">
                  <label for="">&ensp;</label>
                  <button type="button" @click="search" class="btn btn-primary">Buscar</button>
                </div>
              </div>
            </form>
            <div class="row">
              <div class="col-12 mt-3">
                <div class="text-secondary">
                  Mostrando os primeiros <strong>{{ readers.length }} leitores</strong> encontrados.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-120"></div>

        <div class="row">
          <div class="card">
            <div v-if="readers.length > 0">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Nome</th>
                      <th scope="col">Solicitação</th>
                      <th scope="col">Tipo de Leitor</th>
                      <th scope="col">Status</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(reader, index) in readers" :key="index">
                      <td>
                        <b>{{ reader.user.name }}</b><br>
                        {{ reader.user.username ?? "criado com e-mail" }}
                        <span v-if="reader.custom_description" 
                              class="tooltip-multiline" 
                              :data-tooltip="reader.custom_description?.slice(0,140)">ⓘ</span>
                      </td>
                      <td>{{ $dayjs(reader.created_at).format("DD/MM/YYYY HH:mm") }}</td>
                      <td>
                        <span v-if="reader.type_reader == 1" class="badge bg-primary">Comum</span>
                        <span v-if="reader.type_reader == 2" class="badge bg-secondary">Interno</span>
                      </td>
                      <td>
                        <span
                            v-if="reader.status == 'pending'"
                            @click="approveClick(index)"
                            class="badge bg-warning"
                            style="cursor:pointer"
                            data-bs-toggle="modal"
                            data-bs-target="#readerApproveModal">
                            Pendente</span>
                        <div
                            v-if="reader.status == 'removed'"
                            @click="approveClick(index)"
                            class="badge bg-danger"
                            style="cursor:pointer"
                            data-bs-toggle="modal"
                            data-bs-target="#readerApproveModal">
                            Removido</div>
                        <span
                            v-else-if="reader.status == 'active'"
                            @click="removeClick(index)"
                            class="badge bg-success"
                            style="cursor:pointer"
                            data-bs-toggle="modal"
                            data-bs-target="#readerRemoveModal">
                            Ativo</span>
                        <span
                            v-else
                            class="badge bg-grey">
                            {{reader.status}}</span>
                      </td>
                      <td class="text-end">
                        <span
                          @click="$router.push(`/leitores/${reader.id}/editar`)"
                          style="cursor:pointer"
                          class="badge bg-secondary me-3">
                          Editar
                        </span>
                        <span
                          @click="$router.push(`/leitores/${reader.id}/ver`)"
                          style="cursor:pointer"
                          class="badge bg-secondary px-3">
                          Ver
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else>
              <div class="text-center p-5">
                <div v-if="!searchQuery.actived">
                  <img src="@/assets/icons/stones.png" alt="">
                  <p class="fw-light mt-2">Nenhuma matrícula pendente.</p>
                </div>
                <span v-else>Nenhum usuário aprovado.</span>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-60"></div>

        <!-- approve users -->
        <div class="modal fade" id="readerApproveModal" data-bs-keyboard="false" tabindex="-1" aria-labelledby="readerApproveModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="readerApproveModalLabel">Aprovar Leitor</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <p>Leitores <b>aprovados podem solicitar empréstimo</b> de material.
                  Confira se os dados do leitor estão corretos.</p>

                <ul class="list-group">
                  <li class="list-group-item">Nome: {{ approveItem.name}}</li>
                  <li class="list-group-item">Usuário: {{ approveItem.username}}</li>
                  <li class="list-group-item">CPF: {{ approveItem.document }}</li>
                  <li class="list-group-item">Telefone: {{ approveItem.phone }}</li>
                  <li class="list-group-item">Email: {{ approveItem.email }}</li>
                  <li class="list-group-item">ID externo: {{ approveItem.external_id }}</li>
                  <li class="list-group-item">Anotações: {{ approveItem.custom_description }}</li>
                </ul>
              </div>
              <div class="modal-footer">
                <button 
                  v-on:click="modalClose"
                  type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                <button 
                  v-on:click="approveReaderEnroll"
                  data-bs-dismiss="modal"
                  type="button" class="btn btn-primary">Aprovar</button>
              </div>
            </div>
          </div>
        </div>

        <!-- Remove users -->
        <div class="modal fade" id="readerRemoveModal" data-bs-keyboard="false" tabindex="-1" aria-labelledby="readerRemoveModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="readerRemoveModalLabel">Remover Leitor</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <p>Um leitor removido <b>não conseguirá solicitar empréstimo</b> de material.
                  Esta ação poderá ser revertida por você a qualquer momento.
                </p>

                <ul class="list-group">
                  <li class="list-group-item">Nome: {{ removeItem.name}}</li>
                  <li class="list-group-item">Usuário: {{ removeItem.username ?? 'Não informado' }}</li>
                  <li class="list-group-item">CPF: {{ removeItem.document }}</li>
                  <li class="list-group-item">Telefone: {{ removeItem.phone }}</li>
                  <li class="list-group-item">Email: {{ removeItem.email }}</li>
                  <li class="list-group-item">ID externo: {{ removeItem.external_id }}</li>
                  <li class="list-group-item">Anotações: {{ removeItem.custom_description }}</li>
                </ul>
              </div>
              <div class="modal-footer">
                <button 
                  v-on:click="modalClose"
                  type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                <button 
                  v-on:click="removeReaderEnroll"
                  data-bs-dismiss="modal"
                  type="button" class="btn btn-danger">Remover</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import SidebarLeft from '@/components/SidebarLeft.vue';
  
  export default{
    name: 'LeitoresComponent',
    components: {
      SidebarLeft,
    },
    data() {
      return {
        modalOpen: true,
        readers: [],
        
        searchQuery: {
          name: '',
          filter: 'name',
          status: 'all',
        },
        
        approveItem: {
          id: null,
          index: null,
          status: null,
        },

        removeItem: {
          status: null,
          id: null,
          index: null,
        }
      };
    },
    
    mounted() {
      this.fecthReaders();
    },

    methods: {
      modalClose() {
        this.approveItem = {};
        this.removeItem = {};
      },

      async fecthReaders() {
        this.$vs.loading();

        try {
          const response = await this.$axios.get(`/libraries/readers?status=all`);
          this.readers = response.data;
          this.$vs.loading.close();

        } catch (error) {
          this.$vs.loading.close();
          console.log(error);
        }
      },

      async search() {
        this.$vs.loading();
        
        let query = '';

        if(this.searchQuery.name) {
          query = '&name=' + this.searchQuery.name;
        }

        query += `&filter=${this.searchQuery.filter}`;

        query += `&status=${this.searchQuery.status}`;
        
        try {
          const response = await this.$axios.get(`/libraries/readers?${query}`);
          this.readers = response.data
          this.$vs.loading.close();
        
        } catch(error) {
          this.$vs.notify({
            title:'Ops',
            text: error.response.data.message,
            color:'danger',
            position:'top-right'
          });
          this.$vs.loading.close();
        }
        
      },
      
      async approveReaderEnroll() {
        this.$vs.loading();
        
        try {
          const response = await this.$axios.put(`/libraries/readers/${this.approveItem.id}/active`);
        
          this.successNotification(response.data.message);
          this.readers[this.approveItem.index].status = 'active';
          this.approveItem = {};
          
          this.$vs.loading.close();
          
        } catch(error) {
          this.errorNotification(error.response.data.message);
          this.$vs.loading.close();
        }

      },

      approveClick(index) {
        this.approveItem.id = this.readers[index].id;
        this.approveItem.document = this.readers[index].user.document;
        this.approveItem.phone = this.readers[index].user.phone;
        this.approveItem.email = this.readers[index].user.email;
        this.approveItem.username = this.readers[index].user.username;
        this.approveItem.name = this.readers[index].user.name;
        this.approveItem.external_id = this.readers[index].external_id ?? '';
        this.approveItem.custom_description = this.readers[index].custom_description ?? '';
        this.approveItem.index = index;
      },

      removeClick(index) {
        this.removeItem.id = this.readers[index].id;
        this.removeItem.name = this.readers[index].user.name;
        this.removeItem.email = this.readers[index].user.email;
        this.removeItem.username = this.readers[index].user.username;
        this.removeItem.phone = this.readers[index].user.phone;
        this.removeItem.document = this.readers[index].user.document;
        this.removeItem.external_id = this.readers[index].external_id ?? '';
        this.removeItem.custom_description = this.readers[index].custom_description ?? '';
        this.removeItem.index = index;
      },

      async removeReaderEnroll() {
        this.$vs.loading();
        
        try {
          const response = await this.$axios.put(`/libraries/readers/${this.removeItem.id}/remove`);
        
          this.successNotification(response.data.message);
          this.readers[this.removeItem.index].status = 'removed';
          this.removeItem= {};
          
          this.$vs.loading.close();
          this.$router.push('/leitores'); 
        } catch (error) {
          this.errorNotification(error.response.data.message);
          this.$vs.loading.close();
        }
      },

      successNotification(message){
        this.$vs.notify({
          title:'Sucesso',
          text:  message,
          color:'success',
          position:'top-right'
        });
      },

      errorNotification(message) {
        this.$vs.notify({
          title: 'Erro',
          text: message,
          color:'danger',
          position:'top-right'
        });
      },
    },
  }
</script>

<style lang="scss"/>