<template>
  <div class="d-md-flex">
    <SidebarLeft />
    <div class="pages">
      <div class="container-fluid">
        <div class="page-title">
          <h5>Empréstimos</h5> 
          <span>{{ $store.state.library.name }}</span>
        </div>
        
        <div class="row mb-4">
          <div class="card" style="padding:20px">
            <form @submit.prevent="searchLoan" class="row">
              <div class="col-md-4">
                <label for="document">Nome, exemplar ou usuário</label>
                <input id="document" v-model="searchQuery.name" placeholder="Buscar..." class="form-control">
              </div>
              <div class="col-md-3">
                <label for="">Tipo</label>
                <select v-model="searchQuery.filter" class="form-select" aria-label="Filtro">
                  <option value="name">Nome</option>
                  <option value="copy_number">Exemplar</option>
                  <option value="username">Usuário</option>
                </select>
              </div>
              <div class="col-md-3">
                <label for="">Situação</label>
                <select 
                  v-model="searchQuery.status"
                  @change="searchLoan"
                  class="form-select" aria-label="Filtro">
                  <option value="all">Todos</option>
                  <option value="pending_or_authorized">Pendentes e Autorizados</option>
                  <option value="pending">Pendente</option>
                  <option value="authorized">Autorizado</option>
                  <option value="overdue">Em Atraso</option>
                  <option value="canceled">Cancelado</option>
                  <option value="returned">Devolvido</option>
                </select>
              </div>
              <div class="col-md-2">
                <div class="d-grid">
                  <label for="">&ensp;</label>
                  <button @click="searchLoan" type="button" class="btn btn-primary">Buscar</button>
                </div>
              </div>
            </form>
            <div class="row">
              <div class="col-12 mt-3">
                <div class="text-secondary">
                  Mostrando os primeiros <strong>{{ loans.length }} empréstimos</strong> encontrados.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-120"></div>

        <div class="row">
          <div class="card">
            <div class="table-responsive">
              <table v-if="loans.length > 0" class="table" style="min-height: 185px;">
                <thead>
                  <tr>
                    <th class="text-center">Exemplar</th>
                    <th scope="col">Nome</th>
                    <th scope="col">Material</th>
                    <th scope="col">Período</th>
                    <th scope="col">Situação</th>
                    <th scope="col">Multa</th>
                    <th scope="col" style="text-align:center">Devolvido</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(loan, index) in loans" v-bind:key="loan.id">
                    <td class="text-center">
                      <span v-if="loan.loan_data.book?.material_type == 'ebook'" class="badge bg-primary px-3">Digital</span>
                      <span v-if="loan.copy?.copy_number">{{ loan.copy?.copy_number }}</span>
                    </td>
                    <td>
                      {{ loan.loan_data.user.name  }}
                    </td>
                    <td>
                      {{ loan.loan_data.book.title }}<br>
                      <span class="text-secondary">({{ loan.loan_data.book.author }}) </span>
                    </td>
                    <td>
                      {{ this.$dayjs(loan.date_start).format("DD/MM/YYYY") }} <br>
                      <b>{{ this.$dayjs(loan.date_end).format("DD/MM/YYYY") }}</b></td>
                    <td>
                      <span 
                          v-if="(loan.status === 'returned')" 
                          class="badge bg-success">
                          Devolvido</span>
                      <span 
                          v-else-if="loan.status == 'pending'" 
                          @click="authorizeClick(index)" 
                          class="badge bg-warning" 
                          style="cursor:pointer"
                          data-bs-toggle="modal" 
                          data-bs-target="#loanApproveModal">
                          Solicitado</span>
                      <span 
                          v-else-if="loan.status === 'authorized' && new Date(`${loan.date_end} 23:59:59`) > new Date()" 
                          class="badge bg-primary">
                          Emprestado em dias</span>
                      <span 
                          v-else-if="loan.status === 'authorized' && new Date(`${loan.date_end} 23:59:59`) < new Date()" 
                          class="badge bg-danger">
                          Emprestado em atraso</span>
                      <span 
                          v-else-if="loan.status === 'cancelled' || loan.status === 'canceled'" 
                          class="badge bg-dark">
                          Cancelado</span>
                      <span 
                          v-else>
                          {{loan.status}}</span>
                    </td>
                    <td style="min-width: 90px;">
                      R$ {{ loan.fine ?? fineValue(loan.fine_days).toFixed(2) }}
                    </td>
                    <td style="text-align:center; width:50px">
                      <div v-if="loan.status == 'returned'">
                        <span>
                          {{ $dayjs(loan.returned_date).format('DD/MM/YYYY') }}
                        </span>
                        <span @click="receipt(loan)" class="btn btn-link btn-sm">comprovante</span>
                      </div>
                      <span v-else-if="loan.loan_data.book?.material_type == 'ebook'" 
                        style="cursor:pointer"
                        class="tooltip-multiline tooltip-bottom"
                        data-tooltip="Este é um empréstimo digital e será devovido automaticamente na data do vencimento.">
                        <span 
                          class="material-icons-outlined">
                          info
                        </span>
                      </span>
                      <span 
                          v-else-if="loan.status == 'authorized'" 
                          @click="returnedClick(index)" 
                          style="cursor:pointer" 
                          class="material-icons-outlined"
                          title="Devolver"
                          data-bs-toggle="modal" 
                          data-bs-target="#loanReturnedModal">
                          toggle_off</span>
                      <span v-else></span>
                    </td>
                    <td class="text-end" style="min-width: 100px;">
                      <span class="me-2"
                        role="button" 
                        @click="selectedLoan(index)" 
                        title="Alterar vencimento"
                        data-bs-target="#loanChangeDate" 
                        data-bs-toggle="modal">
                        <i class="material-icons-outlined">edit_calendar</i></span>

                      <span 
                        @click="$router.push({ path: `/emprestimos/${loan.id}/ficha` })" 
                        class="ms-2"
                        role="button"
                        data-bs-toggle="tooltip" 
                        data-bs-placement="top" 
                        title="Ficha do Empréstimo">
                        <span class="material-icons" style="font-size: 24px;">menu</span>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-else class="text-center" style="padding: 50px 0">
                <img src="@/assets/icons/stones.png" alt="">
                <p class="fw-light mt-2">Nenhum empréstimo para mostrar.</p>
              </div>
            </div>
          </div>
        </div>
        
        <div class="text-center mt-5">
          💡 <span role="button" class="text-primary" data-bs-toggle="modal" data-bs-target="#calendarModal">você pode ter uma visão geral dos empréstimos usando o calendário</span>
        </div>
        <div class="mt-60"></div>


        <!-- Modal Calendar -->
        <div class="modal fade" id="calendarModal" data-bs-keyboard="false" tabindex="-1" aria-labelledby="calendarModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="calendarModalLabel">Calendário de devoluções</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <EmprestimosCalendarioPage />
              </div>
              <div class="modal-footer">
                <button 
                  type="button" 
                  class="btn btn-secondary" 
                  data-bs-dismiss="modal">
                  Fechar</button>
              </div>
            </div>
          </div>
        </div>

        <!-- approve loans -->
        <div class="modal fade" id="loanApproveModal" data-bs-keyboard="false" tabindex="-1" aria-labelledby="loanApproveModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="loanApproveModalLabel">Aprovar Empréstimo</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <p>Confira os dados de contato antes de autorizar o empréstimo.</p>
                
                <div class="mb-2">
                  <label class="form-label">Código de Liberação (solicite ao leitor)</label>
                  <input 
                      v-model="authorization_code" 
                      v-mask="'XXXX'"
                      placeholder="Ex: abcd16"
                      class="form-control">
                </div>

                <div class="mb-4">
                  <label class="form-label">Confirme o número do exemplar que está sendo emprestado</label>
                  <input 
                      v-model="loaned_copy" 
                      type="number" 
                      placeholder="Ex: 110"
                      class="form-control">
                </div>
                
                <ul class="list-group">
                  <li class="list-group-item">Nome: {{ approveItem.name}}</li>
                  <li class="list-group-item">CPF: {{ approveItem.document }}</li>
                  <li class="list-group-item">Telefone: {{ approveItem.phone }}</li>
                  <li class="list-group-item">Email: {{ approveItem.email }}</li>
                </ul>

                <ul class="list-group mt-4">
                  <li class="list-group-item">Material: {{ approveItem.book }}</li>
                  <li class="list-group-item fw-bolder">Exemplar: {{ approveItem.copy_number }}</li>
                </ul>

                <ul class="list-group mt-4">
                  <li class="list-group-item">Devolução: {{ $dayjs(approveItem.date_end).format('DD/MM/YYYY') }}</li>
                </ul>
              </div>
              <div class="modal-footer">
                <button 
                  v-on:click="modalClose"
                  type="button" 
                  id="closeModalApprove"
                  class="btn btn-secondary" 
                  data-bs-dismiss="modal">
                  Fechar</button>
                <button 
                  v-on:click="loanCancel"
                  type="button" 
                  class="btn btn-danger">
                  Cancelar Empréstimo</button>
                <button 
                  v-on:click="approveLoan"
                  type="button" 
                  class="btn btn-primary">
                  Aprovar</button>
              </div>
            </div>
          </div>
        </div>

        <!-- return material -->
        <div class="modal fade" id="loanReturnedModal" data-bs-keyboard="false" tabindex="-1" aria-labelledby="loanReturnedModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="loanReturnedModalLabel">Confirmar Devolução</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                
                <div class="row">
                  <div class="col-md-7 mb-4">
                    <label for="fine" class="form-label">Cobrar multa</label>
                    <input id="fine" v-model="returned.fine" type="number" class="form-control" /> 
                  </div>

                  <div class="col-md-5 mb-4">
                    <label for="fine_days" class="form-label">Meio de Pagamento</label>
                    <select id="fine_days" v-model="returned.fine_payment_method" class="form-select">
                      <option :value="1">Dinheiro</option>
                      <option :value="2">Pix</option>
                      <option :value="3">Cartão de Crédito</option>
                      <option :value="4">Não cobrar</option>
                    </select>
                  </div>
                </div>

                <ul class="list-group">
                  <li class="list-group-item">Nome: {{ returned.loan_data?.user.name }}</li>
                  <li class="list-group-item">Usuário: {{ returned.loan_data?.user.username }}</li>
                  <li class="list-group-item">Material: {{ returned.loan_data?.book.title }}</li>
                </ul>

                <ul class="list-group mt-4 mb-4">
                  <li class="list-group-item">Vencimento em: {{ $dayjs(returned.date_end).format('DD/MM/YYYY') }}</li>
                  <li class="list-group-item">Dias em atraso: {{ returned.fine_days }}</li>
                </ul>

                <div v-if="waitingList.length > 0" class="border border-danger p-2 mb-3">
                  <div class="text-danger mb-2">Na lista de espera:</div>
                  <div v-for="item in waitingList" :key="item.id" class="text-danger">
                    {{ item.reader.user.name }}
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button 
                  v-on:click="modalClose"
                  type="button" 
                  id="closeModalReturn"
                  class="btn btn-secondary" 
                  data-bs-dismiss="modal">
                  Voltar</button>
                <button 
                  v-on:click="returnedLoan"
                  type="button" 
                  class="btn btn-primary">
                  Devolvido</button>
              </div>
            </div>
          </div>
        </div>

        <!-- Alterar data -->
        <div class="modal fade" id="loanChangeDate" data-bs-keyboard="false" tabindex="-1" aria-labelledby="loanChangeDateLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="loanChangeDateLabel">Alterar vencimento</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div>Material: {{ loan_change_form?.title }}</div>
                <div class="mb-3">Leitor: {{ loan_change_form?.user }}</div>
                <div class="mb-4">
                  <label for="changeDate" class="form-label">Selecione uma nova data</label>
                  <input id="changeDate" type="date" v-model="loan_change_form.date_end" class="form-control" /> 
                </div>
              </div>
              <div class="modal-footer">
                <button 
                  type="button" 
                  id="closeModalChangeDate"
                  class="btn btn-secondary" 
                  data-bs-dismiss="modal">
                  Voltar</button>
                <button 
                  @click="changeDate"
                  type="button" 
                  class="btn btn-primary">
                  Alterar</button>
              </div>
            </div>
          </div>
        </div>
      
      </div>
    </div>
  </div>
</template>

<script>
  import SidebarLeft from '../../components/SidebarLeft.vue'
  import EmprestimosCalendarioPage from './EmprestimosCalendarioPage.vue';
  
  export default{
    name: 'EmprestimosComponent',
    data() {
      return {
        loans: [],
        searchQuery: {
          status: 'pending_or_authorized',
          name: '', 
          filter: 'name'
        },
        loan_change_form: {
          date_end: null
        },
        authorization_code: "",
        loaned_copy: "",
        approveItem: {
          id: null,
          name: '',
          email: '',
          phone: '',
          book: '',
          return: '',
          index: null,
          copy_number: null
        },
        returned:{
          active: false,
          id: null,
          name: '',
          email: '',
          document: '',
          book: '',
          fine_days: 0,
          fine: null,
          index: null,
          fine_payment_method: 1,
        },
        waitingList: [],
      };
    },

    components: {
      SidebarLeft,
      EmprestimosCalendarioPage
    },

    methods: {
      fineValue(days) {
        if(this.$store.state.library.fine_type === 2) {
          return this.$store.state.library.fine_value;
        }
        return days * this.$store.state.library.fine_value;
      },

      async approveLoan() {
        if(this.authorization_code.length != 4) {
          return this.errorNotification('Informe o código de liberação.');
        }

        try {
          const response = await this.$axios.put(
            `/libraries/loans/${this.approveItem.id}/authorize`, {
              authorization_code: this.authorization_code,
              loaned_copy: this.loaned_copy,
            });
                                  
          this.successNotification(response.data.message);
          this.loans[this.approveItem.index].status = "authorized";
          this.authorization_code = '';
          document.getElementById('closeModalApprove').click();

        } catch(error) {
          this.errorNotification(error.response.data.message);
          this.$vs.loading.close();
        }
      },

      authorizeClick(index) {        
        this.approveItem.id = this.loans[index].id;
        this.approveItem.index = index;

        this.approveItem.copy_number = this.loans[index].copy?.copy_number ?? null;
        this.approveItem.book = this.loans[index].loan_data.book.title;
        this.approveItem.name = this.loans[index].loan_data.user.name;
        this.approveItem.email = this.loans[index].loan_data.user.email;
        this.approveItem.phone = this.loans[index].loan_data.user.phone;
        this.approveItem.document = this.loans[index].loan_data.user.document;
        this.approveItem.date_end = this.loans[index].date_end;
        
      },

      selectedLoan(index) {
        this.loan_change_form = {
          title: this.loans[index].loan_data?.book.title,
          id: this.loans[index].id,
          date_end: this.loans[index].date_end,
          user: this.loans[index].loan_data?.user.name,
        };
      },

      returnedClick(index) {
        this.returned = this.loans[index];
        this.returned.index = index;
        this.returned.fine = this.fineValue(this.returned.fine_days).toFixed(2);
        this.findInWaitingList(this.loans[index].book_id);
      },
      
      async returnedLoan() {
        this.$vs.loading();

        const response = await this.$axios.put(`/libraries/loans/${this.returned.id}/return`, this.returned);

        try {
          this.successNotification(response.data.message);
          this.loans[this.returned.index].status = "returned";
          this.loans[this.returned.index].returned_date = new Date();
          this.$vs.loading.close();
          document.getElementById('closeModalReturn').click();
        
        } catch(error) {
          this.errorNotification(error.response.data.message);
          this.$vs.loading.close();
        }  
      },

      async loanCancel() {
        this.$vs.loading();

        const response = await this.$axios.put(`/libraries/loans/${this.approveItem.id}/cancel`);

        try {
          this.successNotification(response.data.message);
          this.loans[this.approveItem.index].status = "canceled";
          this.$vs.loading.close();
          document.getElementById('closeModalApprove').click();
        
        } catch(error) {
          this.errorNotification(error.response.data.message);
          this.$vs.loading.close();
        }  
      },

      async receipt(item) {
        this.$vs.loading();
        const url = `${process.env.VUE_APP_API_URL}/libraries/loans/${item.id}/receipts/return`;
        const options = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + JSON.parse(localStorage.getItem('authorization')).token,
          },
        };
        await fetch(url, options)
          .then( res => res.blob() )
          .then( blob => {
            var file = window.URL.createObjectURL(blob);
            window.open(file, '_blank');
            this.$vs.loading.close();
          });
        this.$vs.loading.close();
      },

      successNotification(message){
        this.$vs.notify({
          title:'Sucesso',
          text:  message,
          color:'success',
          position:'top-right'
        });
      },

      errorNotification(message) {
        this.$vs.notify({
          title: 'Ops',
          text: message,
          color:'danger',
          position:'top-right'
        });
      },

      async searchLoan() {
        this.$vs.loading();
        
        let queryUrl = '';

        if(this.searchQuery.name) {
          queryUrl = `&name=${this.searchQuery.name}`;
        }
        queryUrl += `&filter=${this.searchQuery.filter}`;

        try {
          const response = await this.$axios.get(`/libraries/loans?status=${this.searchQuery.status}${queryUrl}`);

          this.loans = response.data.map(function (element) {
            const response = {
              id: element.id,
              book_id: element.book_id,
              date_start: element.date_start,
              date_end: element.date_end,
              loan_data: element.loan_data,
              status: element.status,
              loaned_copy: element.loaned_copy,
              copy: element.copy ?? null,
              returned_date: element.returned_date,
              fine_days: element.fine_days,
              fine: element.fine,
            };
            return response;
          });
          
          this.$vs.loading.close();

        } catch(error) {
          this.$vs.loading.close();
          this.errorNotification(error.response?.data.message ?? 'Erro ao buscar');
          console.log(error);
          this.loans = [];
        }
      },

      async findInWaitingList(bookId) {
        this.$vs.loading();
        this.waitingList = [];
        try {
          const response = await this.$axios.get(`/libraries/waiting-lists?book_id=${bookId}`);
          this.waitingList = response.data;
          this.$vs.loading.close();
        } catch(error) {
          this.$vs.loading.close();
          this.errorNotification(error.response.data.message);
        }
      },

      async changeDate() {
        this.$vs.loading();

        try {
          const response = await this.$axios.put(`/libraries/loans/${this.loan_change_form.id}/change-date`, this.loan_change_form);
          this.successNotification(response.data.message);
          this.loan_change_form = {
            title: '',
            id: '',
            date_end: '',
            user: '',
          };
          this.$vs.loading.close();
          document.getElementById('closeModalChangeDate').click();
        
        } catch(error) {
          this.$vs.loading.close();
          this.errorNotification(error.response.data.message);
        }  
      },
    },

    created() {
      this.$vs.loading()

      let hasCopy = '';

      if (this.$route.query.copy_id) {
        hasCopy = `&filter=copy&name=${this.$route.query.copy_id}`;
      }
      
      this.$axios.get(`/libraries/loans?status=pending_or_authorized${hasCopy}`)
      .then(response => {
        if (response.status == 200) {
          this.loans = response.data.map(function (element) {
            const response = {
              id: element.id,
              book_id: element.book_id,
              date_start: element.date_start,
              date_end: element.date_end,
              loan_data: element.loan_data,
              status: element.status,
              returned_date: element.returned_date,
              loaned_copy: element.loaned_copy,
              fine_days: element.fine_days,
              fine: element.fine,
              copy: element.copy,
            };
            return response;
          });
          
        }else{
          console.log(this.config.headers)
        }
        
        this.$vs.loading.close()
        
      });

    },
    
  }
</script>
