<template>
   <div class="d-md-flex">
     <SidebarLeft />
     <div class="pages">
       <div class="container-fluid">
         <div class="page-title">
           <h5>Status da Assinatura</h5> 
           <span>{{ $store.state.library.name }}</span>
         </div>
          <div class="card">
            <div 
                v-if="contract.status == 2" 
                class="col-md-6 mx-auto">
                <div class="text-center pt-4">
                  <img src="@/assets/icons/payment-calendar.svg" alt="" width="90">
                  <h4>Assinatura expirou</h4>
                </div>
              <div class="text-center pt-3 px-4 pb-4">
                A sua assinatura <strong>{{ contract.plan.name }}</strong> 
                <span v-if="contract.expires_at">
                  expirou <strong>em {{ $dayjs(contract.expires_at).format('DD/MM/YYYY') }}</strong>.
                </span>
              </div>
                <div class="mt-3 mb-3 text-center">
                  <router-link to="/assinatura/renovar" class="btn btn-primary">Renovar agora</router-link>
                </div>
                <div class="mb-4 text-center">
                  <router-link to="/ajuda" class="btn btn-link">Fale com o nosso suporte</router-link>
                </div>
            </div>

            <!-- se -->
            <div 
                v-if="contract.status == 1" 
                class="col-md-6 mx-auto">
                <div class="text-center pt-4">
                  <img src="@/assets/icons/payment-pending.svg" alt="" width="80">
                  <h4>Aguardando o pagamento</h4>
                  <span class="fw-normal text-danger">R$ {{ (contract.final_price).toFixed(2) }}</span>
                </div>
              
               <div class="mt-3 mb-4 text-center">
                  <button @click="reload" class="btn btn-outline-primary me-2">Verificar novamente</button>
                  <a :href="contract.payment_link" target="_blank" class="btn btn-primary">Pagar agora</a>
               </div>

               <div class="text-center px-4 pb-4">
                  <ul>
                     <li class="mb-2">Pagamentos com Pix ou cartão levam até 4 minutos para liberação no sistema.</li>
                     <li>Pagamentos com boleto são liberados em até 2 dias úteis.</li>
                  </ul>
                  <p><span>Contrato #{{ contract.id}}</span>
                  <span class="ms-1">{{ contract.plan.name }}</span><br/>
                  </p>
                  <router-link to="/ajuda" class="btn-link">Precisa de ajuda?</router-link>
              </div>

            </div>

            <!-- se -->
            <div 
                v-if="contract.status == 3" 
                class="col-md-6 mx-auto">
                <div class="text-center pt-4">
                  <img src="@/assets/icons/payment-calendar.svg" alt="" width="90">
                  <h4>Assinatura Cancelada</h4>
                </div>
              <div class="text-center pt-3 px-4 pb-4">
                O pedido {{  contract.id }} <b class="destacar">{{ contract.plan.name }}</b> 
                foi cancelado pelo motivo: <strong>{{ contract.reason }}</strong>.
              </div>
                <div class="mt-4 mb-4 text-center">
                  <router-link to="/assinatura/renovar" class="btn btn-primary">Ver Planos</router-link>
                </div>
            </div>
          </div>
         
         <div class="top-60"></div>
         
       </div>
     </div>
   </div>
 </template>
 
 <script>
   import dayjs from "dayjs";
   import SidebarLeft from '../../components/SidebarLeft'
 
   export default{
     name: 'PagamentoPendentePage',
     components: {
       SidebarLeft,
     },
     data() {
       return {
          contract: {
            plan: {
              name: ''
            },
            expires_at: '',
          },
          orderId: '',
       }
     },
 
      mounted() {
        this.orderId = this.$route.query.order_id ?? '';
        if (this.orderId.length < 1) {
          return this.$router.push('/home');
        }
        
        this.checkHasExpiredContract();

        this.getLimits();
      },
 
     computed: {
       contractExpires() {
         return dayjs(this.limits.expires).format('DD/MM/YYYY');
       },
     },
 
      methods: {

        reload() {
          return this.getLimits();
        },

        async getLimits() {
         this.$vs.loading();
         
         let requestUrl;

         if (this.orderId === 'null') {
           requestUrl = `/libraries/contracts?last`;
          } else {
           requestUrl = `/libraries/contracts?order_id=${this.orderId}`;
         }

         try{
           const response = await this.$axios.get(requestUrl);
           this.contract = response.data;
         } catch (error) {
           this.$vs.notify({
             title: 'Erro',
             text: error.response.data.message,
             color: 'danger',
             position: 'top-right',
           });
         } finally {
           this.$vs.loading.close();
         }
       },
 
       paymentStatusTranslate(status) {
         let translated;
         switch (status) {
           case 'pending': translated = 'Pagamento Pendente'; break;
           case 'paid': translated = 'Pago'; break;
           case 'cancelled': translated = 'Pedido Cancelado'; break;
         }
         return translated;
       },

       checkHasExpiredContract() {
        const compare = this.$dayjs(this.$store.state.contract.expires_at).isBefore(this.$dayjs(new Date()), 'day')
        if(!compare) {
          return this.$router.push('/home');
        }
      },
     }
     
   }
</script>