<template>
  <div class="d-md-flex">
    <SidebarLeft />
    <div class="pages">
      <div class="container-fluid">
        <div class="page-title">
          <h5>Concluir importação</h5> 
          <span>{{ $store.state.library.name }}</span>
        </div>
        
        <div class="card p-4" v-if="books">
          <table class="table table-response">
            <thead>
              <tr>
                <th scope="col">Título</th>
                <th scope="col">Autor</th>
                <th scope="col">Editora</th>
                <th scope="col">Ano</th>
                <th scope="col">Classificação</th>
                <th scope="col">Cópias</th>
                <th scope="col">Capa</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="book in books" v-bind:key="book.id">
                <td>{{ book.title }}</td>
                <td>{{ book.author }}</td>
                <td>{{ book.publisher }}</td>
                <td>{{ book.year }}</td>
                <td>{{ fetchDeweyName(book.dewey_category_id) }}</td>
                <td>{{ book.copies }}</td>
                <td class="text-center">{{ (book.cover.length > 0) ? 'Informada' : 'Sem capa' }}</td>
              </tr>
            </tbody>
          </table>
        
          <button 
            v-if="importData.status == 'pending'"
            class="btn btn-primary" 
            @click="storeBooks"
          >Importar</button>
        </div>

        <div v-if="importData.status == 'success'" class="mt-3">
            <div class="alert alert-success d-flex align-items-center mt-1 mb-2" role="alert">
              <span class="material-icons-round me-1">check_circle</span>
              <div>
                Tudo certo com a importação dos materiais.
              </div>
            </div>

            <router-link class="btn btn-outline-primary mt-2" to="/acervo/importar">
              Voltar para importações
            </router-link>
          </div>

      </div>
    </div>
  </div>
</template>

<script>
import SidebarLeft from '../../components/SidebarLeft'
import Env from '../../../env';

export default {
    name: 'AcervoGoogleDocsConcluir',
    components: {
        SidebarLeft,
        // BooksTable
    },
    data() {
      return {
        books: [],  
        importData: {
          status: 'pending',
        },
      }
    },
    
    mounted() {
      this.fetchImport();
    },
    
    methods: {

      async fetchImport() {
        this.$vs.loading();

        try {
          const response = await this.$axios.get(`/libraries/imports/${this.$route.params.id}`);
          this.importData = response.data.info;
          this.books = response.data.books;
          this.$vs.loading.close();

        } catch(error) {
          this.notificationError({message: error.response.data.message});
          this.$vs.loading.close();
        }
      },

      async storeBooks() {
        this.$vs.loading();

        try {
          const response = await this.$axios.post(`/libraries/imports/${this.$route.params.id}/books`);
          this.$vs.loading.close();
          this.importData.status = 'success';
          this.notificationSuccess({message: response.data.message});

        } catch(error) {
          this.notificationError({message: error.response.data.message});
          this.$vs.loading.close();
        }
      },

      fetchDeweyName(id) {
        return Env.Dewey.find(x => x.id === id).name;
      },

      notificationError({title='Ops', message}) {
        this.$vs.notify({
          title: title,
          text: message,
          color:'danger',
          position:'top-right'
        });
      },

      notificationSuccess({title='Sucesso!', message}) {
        this.$vs.notify({
          title: title,
          text: message,
          color:'success',
          position:'top-right'
        });
      }
    }
}
</script>

<style scoped>
  .card-importer {
    border-radius: 0;
    border: 1px solid #e9ecef;
    box-shadow: none;
    height: 220px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom:30px;
    border-radius: 12px;
    box-shadow: 0 4px 28px rgba(123,151,158,.25);
  }

  ::-webkit-scrollbar {
    width: 20px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
</style>