<template>
   <div class="d-md-flex">
     <SidebarLeft />
     <div class="pages">
       <div class="container-fluid">
         <div class="page-title">
           <h5>Integração</h5> 
           <span>{{ $store.state.library.name }}</span>
         </div>
         <div class="card" style="padding:20px">
            <div class="col-md-6 mx-auto text-center mt-3">
               <img src="@/assets/icons/colaboracao.png" alt="" style="width:100px" class="mb-3">
               <p>O Libmin disponibiliza uma API REST para que você possa integrar a sua biblioteca 
                  com outras plataformas, como um sistema para gestão escolar, financeiro, apps próprios, etc.</p>
               <router-link to="/ajuda">Solicite mais informações sobre como integrar</router-link>
            </div>
         </div>
       </div>
     </div>
   </div>
 </template>
 
 <script>
   import SidebarLeft from '../../components/SidebarLeft'
 
   export default{
      name: 'PerfilComponent',
      components: {SidebarLeft},
   
      data() {
         return {
            userProfile: {},
            integrators: [],
         }
      },
    
      methods: {
         async fetchIntegrators() {
            this.$vs.loading();
            try {
               const response = await this.$axios.get(`/libraries/integrators`);
               this.integrators = response.data;
               this.$vs.loading.close();

            } catch(error) {
               this.$vs.notify({
                  title:'Ops',
                  text: error.response.data.message,
                  color:'danger',
                  position:'top-right'
               });
               this.$vs.loading.close();
            }
         },
      }
   }
 </script>