<template>
  <div class="d-flex">
    <SidebarLeft />
    <div class="pages">
      
      <div v-if="$store.state.contract.plan_id == 5 && $store.state.quotes.ebooks.used >= 2" 
          class="container-fluid">
        <div class="card p-4">
          <div class="page-title text-center mt-4">
            <img src="@/assets/icons/ebook.png" class="mb-4" style="width:75px" alt="">
            <h5>Limite de livros atingido</h5> 
            <span>Faça upgrade e disponibilize mais e-books para os seus leitores.</span>

            <div class="mt-5">
              <button 
                @click="$router.push('/assinatura/melhorar')" 
                class="btn btn-sm btn-rounded px-3 btn-primary">conhecer planos</button>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="container-fluid">
        
        <div class="page-title">
          <h5>Cadastrar Material Digital</h5> 
          <span>{{ $store.state.library.name }}</span>
        </div>
        
        <div class="row mb-4">
          <div class="col-sm-6 col-md-3">
            <div class="card" style="padding:20px">
                <div class="col-md-12 mb-3">
                      <label class="text-danger mb-1" for="material_type">Tipo de Material</label>
                      <select id="material_type" class="form-select" v-model="bookBNData.material_type">
                        <option v-for="type in materialTypes" :key="type.code" :value="type.code">
                          {{ type.name }}
                        </option>
                      </select>
                  </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-6">
            <div class="card" style="padding:20px">
              <div v-if="bookBNData.material_type == 'url'">
                <label for="source" class="mb-1">
                  Link externo (URL)
                  <span class="tooltip-multiline" data-tooltip="Este é um recurso experimental.">ⓘ</span>
                </label>
                <input id="source" 
                  class="form-control mb-3" 
                  placeholder="Link para a página"
                  v-model="bookBNData.source" />
              </div>
              <div v-else>
                <label for="source" class="mb-1">Selecione o arquivo (PDF até 19mb)</label>
                <div class="mb-3">
                  <input type="file" id="source" class="form-control" @change="uploadFile" ref="file" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3">
            <div class="card" style="padding:20px">
              <div class="col-md-12 mb-3">
                <label for="isbn_code" class="mb-1">Código ISBN ou ISSN
                  <span class="tooltip-multiline" data-tooltip="ISBN-10, ISBN-13 e ISSN são aceitos. 
                  Deixe vazio caso não tenha esta informação.">ⓘ</span>
                </label>
                <input id="isbn_code" class="form-control" v-model="bookBNData.isbn_code">
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6 mb-4">
            <div class="card" style="padding:20px">
              <form class="row">
                
                <div class="col-md-12 mb-3">
                  <label class="text-danger" for="title">Título da Obra</label>
                  <input 
                    id="title"
                    required 
                    class="form-control"
                    v-model="bookBNData.title">
                </div>

                <div class="col-md-12 mb-3">
                  <label for="subtitle">Subtítulo</label>
                  <input 
                    id="subtitle"
                    class="form-control"
                    v-model="bookBNData.subtitle">
                </div>

                <div class="col-md-12 mb-3">
                  <label class="text-danger" for="author">Autor Principal</label>
                  <input 
                    id="author"
                    class="form-control"
                    v-model="bookBNData.author">
                </div>

                <div class="col-md-12 mb-3">
                  <label for="authors-others">Outros Autores</label>
                  <input id="authors-others" class="form-control" v-model="bookBNData.authors_others">
                </div>

                <div v-if="$store.state.library.classification_type === 'cdu'" class="col-md-12 mb-3">
                  <label for="cdu_code">Classificação CDU</label>
                  <select v-model="bookBNData.cdu_code" id="cdu_code" class="form-select">
                    <option 
                      v-for="item in cduCodes" 
                      :value="item.id"
                      :key="item.id">
                      {{ `${item.code} - ${item.name}` }}
                    </option>
                  </select>
                </div>

                <!-- Own, Dewey or CDU classification -->
                <div v-if="$store.state.library.classification_type === 'own'" class="col-md-12 mb-3">
                    <label for="classification_id" class="text-danger">Classificação</label>
                    <Autocomplete :items="classifications.map(item => item.code + ' ' + item.name)"
                      v-model="bookBNData.classification_id" 
                      @input="setClassification($event)"/>
                </div>

                <div v-if="$store.state.library.classification_type === 'own' && bookBNData.classification_id" class="col-md-12 mb-3">
                    <label class="mb-1">Subclassificação</label>
                    <Autocomplete :items="subclassifications.map(item => item.code + ' ' + item.name)"
                      @input="setSubclassification($event)"/>
                </div>

                <div v-if="$store.state.library.classification_type === 'cdu'" class="col-md-12 mb-3">
                    <label for="cdu_category_id" class="text-danger">Classificação CDU</label>
                    <select v-model="bookBNData.cdu_category_id" id="cdu_category_id" class="form-select">
                      <option v-for="item in cduCodes" :value="item.id" :key="item.id">
                          {{ `${item.code} - ${item.name}` }}
                      </option>
                    </select>
                </div>

                <div v-if="$store.state.library.classification_type === 'cdd'" class="col-md-12 mb-3">
                    <label for="dewey_category_id" class="text-danger mb-1">Classificação Dewey</label>
                    <div class="d-flex gap-1 mb-3">
                      <span type="button" 
                          @click="chatGptHelper()"
                          data-bs-toggle="modal" 
                          data-bs-target="#chatGptModal"
                          title="Pedir ajuda à IA para classificação"
                          class="ai-suggestions">
                          <span class="material-icons text-primary" style="font-size: 26px; padding: 3px">auto_awesome</span>
                      </span>
                      <select id="dewey_category_id" class="form-select" @change="fetchDeweyCentesimal($event)"
                          v-model="bookBNData.dewey_category_id">
                          <option v-for="option in DeweyCodes" v-bind:value="option.id" v-bind:key="option.id"
                            :data-code="option.code">
                            {{ option.name }}
                          </option>
                      </select>
                    </div>
                </div>

                <div v-if="$store.state.library.classification_type === 'cdd' && bookBNData.dewey_category_id" class="col-md-12 mb-3">
                    <label for="dewey_centesimal" class="mb-1">Classificação centesimal Dewey</label>
                    <Autocomplete :items="deweyCentesimals.map(item => item.code + ' ' + item.name)"
                      v-model="bookBNData.dewey_centesimal"
                      @input="setDeweyCentesimal" />
                </div>
        
                <div class="col-md-12 mb-3">
                  <label class="text-danger" for="year">Ano de Publicação</label>
                  <input 
                    id="year"
                    class="form-control"
                    v-model="bookBNData.year">
                </div>

                <div class="col-md-12 mb-3">
                  <label class="text-danger" for="publisher">Editora</label>
                  <input 
                    id="publisher"
                    class="form-control"
                    v-model="bookBNData.publisher">
                </div>
                
                <div class="col-md-12">
                  <label for="resume">Descrição Curta</label>
                  <textarea 
                    id="resume"
                    placeholder="Apresente este material em até 240 caracteres" 
                    rows="2" 
                    class="form-control"
                    maxlength="240"
                    v-model="bookBNData.resume">
                  </textarea>
                </div>
            </form>
            </div>
          </div>
          <div class="col-sm-6 mb-4">
            <div class="card" style="padding:20px">
              <form class="row">
                <div class="col-md-12 mb-3">
                  <label class="text-danger" for="available">Disponível para Empréstimo</label>
                  <select id="available" class="form-select" v-model="bookBNData.available">
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                  </select>
                </div>

                <div v-if="collections.length > 0" class="col-md-12 mb-3">
                  <label for="collections" class="mb-1">
                    Adicionar a uma coleção
                    <span class="tooltip-multiline" data-tooltip="Agora ficou mais fácil adicionar o seu livro a uma coleção">ⓘ</span>
                  </label>
                  <select id="collections" class="form-select" v-model="bookBNData.collection_id">
                    <option :value="null">Selecione</option>
                    <option v-for="coolection in collections" v-bind:key="coolection.id" v-bind:value="coolection.id">{{ coolection.name }}</option>
                  </select>
                </div>

                <div class="col-md-12 mb-3">
                  <label for="edition">Edição</label>
                  <input id="edition" class="form-control" v-model="bookBNData.edition">
                </div>
                
                <div class="col-md-12 mb-3">
                  <label for="volume">Volume</label>
                  <input id="volume" class="form-control" v-model="bookBNData.volume">
                </div>
                
                <div class="col-md-12 mb-3">
                  <label for="place_of_publication">Local da Publicação (cidade)</label>
                  <input id="place_of_publication" class="form-control" v-model="bookBNData.place_of_publication">
                </div>
                
                <div class="col-md-12 mb-3">
                  <label for="language">Idioma</label>
                  <input 
                    id="language"
                    class="form-control"
                    v-model="bookBNData.language">
                </div>
                
                <div class="col-md-12 mb-3">
                  <label for="internal_notes">Anotações Internas</label>
                  <textarea 
                    id="internal_notes"
                    placeholder="Anotações internas..." 
                    rows="2" 
                    class="form-control"
                    v-model="bookBNData.internal_notes">
                  </textarea>
                </div>
            </form>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 mt-2">
            <button type="button" v-on:click="store" class="btn btn-primary">Concluir Cadastro</button>
          </div>
        </div>
      </div>

    </div>

    <!-- Modal -->
    <div class="modal fade" id="chatGptModal" tabindex="-1" aria-labelledby="chatGptModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="chatGptModalLabel">
              <span class="material-icons text-primary" style="font-size: 22px;">auto_awesome</span>
              <span class="ms-1">Sugestões da IA</span>
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="typing-info" v-if="chatGptSuggestions.length > 0">
                <div v-for="suggestion in chatGptSuggestions" :key="suggestion" class="mb-2">
                  <span class="fw-bold badge rounded-pill bg-primary px-3 me-2">{{ suggestion.number }}</span> 
                  <span>{{ suggestion.title }}</span>
                </div>
            </div>
            <div v-else>Informe o título e autor para receber sugestões de classificação.</div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import Env from '../../../../env.js'
  import SidebarLeft from '@/components/SidebarLeft.vue'
  import Autocomplete from '@/components/Autocomplete.vue'
  
  export default{
    name: 'AcervoCadastrarComponent',
    components: {
      SidebarLeft,
      Autocomplete,
    },

    computed: {
      materialTypes() {
        return this.$store.getters.getDigitalTypes;
      }  
    },

    data() {
      return {
        pdfFile: null,
        bookBNData: {
          available: 1,
          copies: 1,
          material_type: 'ebook',
          cover: '',
          dewey_category_id: "",
          dewey_centesimal: "",
          cdu_category_id: "",
          isbn_code: '',
          collection_id: '',
          authors_others: '',
          classification_id: '',
        },
        btnGetBNText: 'Carregar',
        DeweyCodes: [],
        deweyCentesimals: [],
        cduCodes: [],
        classifications: [],
        subclassifications: [],
        config: [],
        message: '',
        onContinue: false,
        chatGptSuggestions: [],
        quotes: {
          materials: {
            hired: 50,
            used: 0,
          }
        },
        collections: [],
      }
    },

    mounted() {
      this.DeweyCodes = Env.Dewey;
      this.getContractLimits();
      this.fetchCduCategories();
      this.getCollections();
      this.getClassifications();
    },

    methods: {
      uploadFile() {
        this.pdfFile = this.$refs.file.files[0];
      },

      async store() {
        if (!this.pdfFile || this.pdfFile.type != 'application/pdf')
          return this.notificationError("Arquivo precisa ser um PDF");

        if(this.bookBNData.title == null)
          return this.notificationError("Informe o título do livro");
        
        if(this.bookBNData.author == null)
          return this.notificationError("Informe o autor principal do material");

        if(this.bookBNData.dewey_category_id == null)
          return this.notificationError("Informe a classificação Dewey");

        const maxSizeInBytes = 10 * 1024 * 1024; // 10 MB
        
        if(this.pdfFile && this.pdfFile.size > maxSizeInBytes)
          return this.notificationError("Arquivo maior que o tamanho permitido.");

        this.$vs.loading()
        
        const formData = new FormData();
        formData.append('file', this.pdfFile);
        
        for (const key in this.bookBNData) {
          if (Object.prototype.hasOwnProperty.call(this.bookBNData, key)) {
            formData.append(key, this.bookBNData[key]);
          }
        }

        const headers = { 'Content-Type': 'multipart/form-data' };

        try {
          const response = await this.$axios.post(`/libraries/ebooks`, formData, { headers });

          this.notificationSuccess(`Cadastrado ${response.data.message}`);

          this.$vs.loading.close();
          
          this.$router.push('/acervo');
          
        } catch (error) {
          this.$vs.loading.close();
          this.notificationError(error.response.data.message);
        }
      },

      async getCollections() {
        this.$vs.loading();
        try {
          const response = await this.$axios.get(`/libraries/collections`);
          this.collections = response.data;
          this.$vs.loading.close();

        } catch (error) {
          this.$vs.loading.close();
          return this.notificationWarning({ message: error.data.data.message });
        }
      },

      async getSubclassifications() {
         this.$vs.loading();
         try {
            const response = await this.$axios.get(`/libraries/classifications/${this.bookBNData.classification_id}`);
            this.subclassifications = response.data.children;
            this.$vs.loading.close();
         } catch (error) {
            this.$vs.loading.close();
            return this.notificationWarning({ message: error.data.message });
         }
      },

      async chatGptHelper() {
        const {title, author} = this.bookBNData;

        if (title.length < 4) {
          return this.notificationError("Informe um título");
        }

        if (author.length < 4) {
          return this.notificationWarning("Informe um autor com mais de 3 caracteres");
        }

        this.$vs.loading();

        try {
          const response = await this.$axios.post(`/libraries/books/openai`, {
            title,
            author,
          });
          
          this.chatGptSuggestions = response.data;

        } catch (error) {
          this.chatGptSuggestions = '';
          this.notificationWarning({message: error.data.message});
        } finally {
          this.$vs.loading.close();
        }
      },

      async fetchCduCategories() {
        if (this.$store.state.library.classification_type != 'cdu') {
          return;
        }
        this.$vs.loading()
        try {
          const response = await this.$axios.get(`/libraries/cdu-categories`);
          this.cduCodes = response.data;
          this.$vs.loading.close();

        } catch (error) {
          console.log(error);
          this.notificationError({message: error.response.data.data.message});
        }
      },

      async getClassifications() {
         if (this.$store.state.library.classification_type != 'own') {
            return;
         }
         this.$vs.loading();
         try {
            const response = await this.$axios.get(`/libraries/classifications`);
            this.classifications = response.data;
            this.$vs.loading.close();
         } catch (error) {
            this.$vs.loading.close();
            return this.notificationWarning({ message: error.data.data.message });
         }
      },
      
      async fetchDeweyCentesimal(event) {
         if (!event || event.length < 3) {return;}
         this.$vs.loading()
         try {
            const code = event.target.options[event.target.options.selectedIndex].getAttribute('data-code');
            const response = await this.$axios.get(`/libraries/dewey-categories?code=${code}`);
            this.deweyCentesimals = response.data;
            this.$vs.loading.close();

         } catch (error) {
            console.log(error);
            this.notificationError({ message: error.response.data?.data.message });
         }
      },
      
      setDeweyCentesimal(event) {
         const code = event.split(' ')[0];
         if (!code || code.length < 3) {
            return this.bookBNData.dewey_centesimal = null;   
         }
         this.bookBNData.dewey_centesimal = code;
      }, 

      setClassification(event) {
         const code = event.split(' ')[0];
         if (!code || code.length == 0) {
            return; 
         }
         const response = this.classifications.find(item => item.code === code);
         this.bookBNData.classification_id = response.id;
         this.getSubclassifications();
      }, 

      setSubclassification(event) {
         const code = event.split(' ')[0];
         if (!code || code.length == 0) {
            return;
         }
         const response = this.subclassifications.find(item => item.code === code);
         this.bookBNData.classification_id = response.id;
      },

      notificationError(title='Ops', message) {
        this.$vs.notify({
          title: title,
          text: message,
          color:'danger',
          position:'top-right'
        });
      },

      notificationSuccess(title='Sucesso!', message) {
        this.$vs.notify({
          title: title,
          text: message,
          color:'success',
          position:'top-right'
        });
      },

      notificationWarning(title='Ops', message) {
        this.$vs.notify({
          title: title,
          text: message,
          color:'warning',
          position:'top-right'
        });
      },

      getContractLimits() {
        this.quotes = JSON.parse(localStorage.getItem('quotes'));
      }

    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
</style>
