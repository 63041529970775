<template>
  <div class="d-flex">
    <SidebarLeft />
    <div class="pages">
      <div class="container-fluid">
        <div class="page-title">
          <h5>Ficha do Leitor</h5>
          <span>{{ $store.state.library.name }}</span>
        </div>
        <div class="row mb-5">
          <div class="col-md-6">
            <div class="card" style="padding:20px">
              
              <h5 class="mb-3">Dados cadastrais</h5>
              
              <div class="mb-3">
                <label for="">Usuário</label>
                <input
                  class="form-control"
                  v-model="reader.username"
                  readonly>
              </div>
              
              <div class="mb-3">
                <label for="">Nome do leitor</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="reader.name"
                  readonly>
              </div>

              <div class="mb-3">
                <label for="">Email</label>
                <input
                  type="email"
                  class="form-control"
                  v-model="reader.email"
                  readonly>
              </div>

              <div class="mb-3">
                <label for="">Telefone</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="reader.phone"
                  v-mask="'(##) #####-####'"
                  readonly>
              </div>
                  
              <div class="mb-3">
                <label for="">Documento</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="reader.document"
                  readonly>
              </div>

              <div class="mb-3">
                <label for="">Tipo de Leitor</label>
                <span
                  class="form-control"
                  readonly>
                  {{ reader.type_reader == '1' ? 'Comum (leitor padrão)' : 'Interno (equipe, professor, colaborador, etc)' }} 
                  </span>
              </div>

              <div class="col-md-12 mt-2">
                <router-link to="/leitores" class="btn btn-outline-primary px-3 me-2">Voltar</router-link>
                <router-link :to="`/leitores/${reader.id}/editar`" class="btn btn-primary px-3">Editar</router-link>
              </div>

            </div>
          </div>
          <div class="col-md-6">
            <div class="card mb-5" style="padding:40px 20px">
              <div class="px-4">
                <h5>Informações adicionais</h5>
                <p>{{ reader.custom_description ?? 'Não informado' }}</p>
              </div>
            </div>

            <!-- Emprestimos -->
            <div class="mb-3">
              <div class="card" style="padding:20px">
                
                <div class="row mb-3">
                  <div class="col-9">
                    <h5>Histórico de empréstimos</h5>
                  </div>
                  <div class="col-3 text-end">
                    <button @click="reportGenerate" class="btn btn-sm btn-outline-primary" type="button">
                      Gerar relatório
                    </button>
                  </div>
                </div>

                <div class="table-responsive">
                  <table v-if="loans.length > 0" class="table">
                    <thead>
                      <tr>
                        <th scope="col">Exemplar</th>
                        <th scope="col">Nome</th>
                        <th scope="col">Material</th>
                        <th scope="col">Período</th>
                        <th scope="col">Situação</th>
                        <th scope="col" style="text-align:center">Devolvido</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(loan, index) in loans" v-bind:key="index">
                        <td>{{ loan.loaned_copy ?? '-' }}</td>
                        <td>{{ loanData(loan.loan_data).user.name }}</td>
                        <td>
                          {{ loanData(loan.loan_data).book.title }}<br>
                          <span class="text-secondary">({{ loanData(loan.loan_data).book.author }}) </span>
                          <span v-if="loan.loaned_copy" class="text-secondary">Ex. {{ loan.loaned_copy }}</span>
                        </td>
                        <td>
                          {{ this.$dayjs(loan.date_start).format("DD/MM/YYYY") }} <br>
                          <b>{{ this.$dayjs(loan.date_end).format("DD/MM/YYYY") }}</b></td>
                        <td>
                          <span 
                              v-if="(loan.status === 'returned')" 
                              class="badge bg-success">
                              Devolvido</span>
                          <span 
                              v-else-if="loan.status == 'pending'" 
                              class="badge bg-warning">
                              Solicitado</span>
                          <span 
                              v-else-if="loan.status === 'authorized' && new Date(loan.date_end) > new Date()" 
                              class="badge bg-primary">
                              Emprestado em dias</span>
                          <span 
                              v-else-if="loan.status === 'authorized' && new Date(loan.date_end) < new Date()" 
                              class="badge bg-danger">
                              Emprestado em atraso</span>
                          <span 
                              v-else-if="loan.status === 'canceled'" 
                              class="badge bg-dark">
                              Cancelado</span>
                          <span 
                              v-else>
                              {{loan.status}}</span>
                        </td>
                        <td style="text-align:center; width:50px">
                          <span 
                              v-if="loan.status == 'returned'">
                              {{ $dayjs(loan.returned_date).format('DD/MM/YYYY') }}</span>
                          <span 
                              v-else-if="loan.status == 'authorized'" 
                              title="Devolução pendente"
                              class="material-icons-outlined">
                              toggle_off</span>
                          <span v-else></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div v-else class="text-center" style="padding: 50px 0">
                    <img src="@/assets/icons/stones.png" alt="">
                    <p class="fw-light mt-2">Leitor ainda não possui empréstimos.</p>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          
        </div>

        
      </div>
    </div>
  </div>
</template>

<script>
  import SidebarLeft from '../../components/SidebarLeft.vue'

  export default{
    name: 'LeitoresEditarPage',
    components: {
      SidebarLeft,
    },

    data() {
      return {
        loans: [],
        reader: {
          id: '',
          email: '',
          name: '',
          phone: '',
          document: '',
          username: '',
          custom_description: null,
          external_id: null,
        },
      }
    },

    mounted() {
      this.fetchReader();
    },

    methods: {
      async fetchReader() {
        this.$vs.loading();
        try {
          const response = await this.$axios.get(`/libraries/readers/${this.$route.params.id}`);

          this.reader.id = response.data.id;
          this.reader.email = response.data.user?.email ?? '';
          this.reader.name = response.data.user.name;
          this.reader.phone = response.data.user?.phone ?? '';
          this.reader.document = response.data.user.document ?? '';
          this.reader.username = response.data.user.username ?? 'criado com e-mail';
          this.reader.custom_description = response.data.custom_description ?? null;
          this.reader.external_id = response.data.external_id ?? null;
          this.loans = response.data.loans ?? [];

          this.$vs.loading.close();

        } catch (error) {
          this.messageError(error.response?.data.message ?? 'Erro');
          this.$vs.loading.close();
          console.log(error);
        }
      },

      async reportGenerate() {

        this.$vs.loading();

        try {
           const response = await this.$axios.get(`/libraries/reports/loans?reader_id=${this.$route.params.id}`, {responseType: 'blob'});
           const blob = new Blob([response.data], { type: 'application/pdf' });
           this.createDownload(blob, "libmin-emprestimos-mes-a-mes.pdf");
           this.successMessage();
           this.$vs.loading.close();
 
         } catch(error) {
          console.log(error);
           this.$vs.notify({
             title:'Ops',
             text: error.response?.data.message ?? 'Erro',
             color:'danger',
             position:'top-right'
           });
           this.$vs.loading.close();
         }
      },

      messageError(message) {
        return this.$vs.notify({
          title: 'Ops',
          text: message,
          color:'danger',
          position:'top-right'
        });
      },

      successMessage(message) {
        return this.$vs.notify({
          title: 'Ops',
          text: message,
          color:'success',
          position:'top-right'
        });
      },


      loanData(data) {
        return typeof data === 'string' ? JSON.parse(data) : data;
      },

      createDownload(blob, filename) {
         const linkForDownload = document.createElement("a");
         document.body.appendChild(linkForDownload);
         const blobUrl = window.URL.createObjectURL(blob);
         linkForDownload.href = blobUrl;
         linkForDownload.download = filename;
         linkForDownload.click();
         setTimeout(() => {
           window.URL.revokeObjectURL(blobUrl);
           document.body.removeChild(linkForDownload);
         }, 0);
       }
    }
  }
</script>
