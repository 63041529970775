<template>
  <div class="d-md-flex">
    <SidebarLeft />
    <div class="pages">
      <div class="container-fluid">

        <div class="welcome">
          <span>Bem-vindo a {{ $store.state.library.name}},</span>
          <div class="row">
            <div class="col-md-8">
              <div class="page-title" style="margin-bottom: 30px;">
                <h5>{{ $store.state.user.name.split(' ')[0] }} 👋</h5>
              </div>
            </div>
            <div class="col-md-4">
              <div v-if="contract.plan_id == 5" class="d-grid">
                <a href="https://libmin.com.br/planos" target="_blank" class="btn btn-danger">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-fire" viewBox="0 0 16 16"><path d="M8 16c3.314 0 6-2 6-5.5 0-1.5-.5-4-2.5-6 .25 1.5-1.25 2-1.25 2C11 4 9 .5 6 0c.357 2 .5 4-2 6-1.25 1-2 2.729-2 4.5C2 14 4.686 16 8 16Zm0-1c-1.657 0-3-1-3-2.75 0-.75.25-2 1.25-3C6.125 10 7 10.5 7 10.5c-.375-1.25.5-3.25 2-3.5-.179 1-.25 2 1 3 .625.5 1 1.364 1 2.25C11 14 9.657 15 8 15Z"/></svg>
                  <span class="ms-2" style="position: relative; top:2px">Conheça nossos planos</span>
                </a>
              </div>
              <div v-else class="text-end position-relative">
                <a :href="`https://${this.$store.state.library.code}.libm.in`" 
                  target="_blank" 
                  class="button-site-link">
                  Link da Biblioteca <span class="material-icons-round" style="font-size: 14px;">open_in_new</span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-5">

          <div class="col-sm-12">
            <Title title="Comece agora" />
          </div>

          <div class="col-md-3 d-grid mb-3">
            <button
              @click="$router.push('/acervo/cadastrar')"
              class="btn btn-info button-actions mx-2 position-relative">
              <span class="material-icons-round">library_books</span>
              <div>Novo livro</div>
            </button>
          </div>

          <div class="col-md-3 d-grid mb-3">
            <button
              @click="$router.push('/acervo/digital/cadastrar')"
              class="btn btn-info button-actions mx-2 position-relative">
              <span class="material-icons-round">picture_as_pdf</span>
              <div>Novo material digital</div>
            </button>
          </div>

          <div class="col-md-3 d-grid mb-3">
            <button
              @click="$router.push('/leitores/cadastrar')"
              class="btn btn-info button-actions mx-2">
              <span class="material-icons-round">people</span>
              <div>Novo leitor</div>
            </button>
          </div>

          <div class="col-md-3 d-grid mb-3">
            <button
              @click="$router.push('/acervo/etiquetas')"
              class="btn btn-info button-actions mx-2 position-relative">
              <span class="material-icons">local_offer</span>
              <div>Gerar Etiquetas</div>
            </button>
          </div>

          <div class="col-md-3 d-grid mb-3">
            <button
              @click="$router.push('/emprestimos/cadastrar')"
              class="btn btn-info button-actions mx-2">
              <span class="material-icons-round">store</span>
              <div>Novo empréstimo</div>
            </button>
          </div>
          
          <div class="col-md-3 d-grid mb-3">
            <button
              @click="$router.push('/lista-espera/cadastrar')"
              class="btn btn-info button-actions mx-2 position-relative">
              <span class="material-icons-round">hourglass_top</span>
              <div>Adicionar à lista de espera</div>
            </button>
          </div>

          <div class="col-md-3 d-grid mb-3">
            <button
              @click="$router.push('/equipe/criar')"
              class="btn btn-info button-actions mx-2 position-relative">
              <span class="material-icons-round">manage_accounts</span>
              <div>Novo assistente</div>
            </button>
          </div>

          <div class="col-md-3 d-grid mb-3">
            <button
              @click="$router.push('/integracao')"
              class="btn btn-info button-actions mx-2">
              <span class="material-icons-round">code</span>
              <div>Integração</div>
            </button>
          </div>

        </div>

        <div class="top-60"></div>

        <div class="row">
          <div class="col-sm-12">
            <Title title="Ações rápidas" />
          </div>

          <div class="col-md-6 mb-3">
            <div class="card p-4 shadow-sm rounded-4">
              <label for="materialLoan" class="fw-bolder fs-6 mb-3">Emprestar material</label>
              <form @submit.prevent class="input-group mb-1">
                <input id="materialLoan" v-model="loanMaterialId" placeholder="Num. exemplar" class="form-control">
                <button @click="loanMaterial" class="btn btn-primary">Emprestar</button>
              </form>
            </div>
          </div>
          
          <div class="col-md-6 mb-3">
            <div class="card p-4 rounded-4">
              <label for="materialReturn" class="fw-bolder fs-6 mb-3">Devolver material</label>
              <form @submit.prevent class="input-group mb-1">
                <input id="materialReturn" v-model="returnMaterialId" placeholder="Num. exemplar" class="form-control">
                <button @click="returnMaterial" class="btn btn-primary">Devolver</button>
              </form>
            </div>
          </div>
          
        </div>
        
        <div class="top-60"></div>

        <div class="row">
          <div class="col-sm-12">
            <Title title="Seu uso no Libmin" />
          </div>
          <CardUsedLimites
            class="col-sm-6 col-md-4"
            :title="contract.materials.title"
            :available="contract.materials.available"
            :used="contract.materials.used"
            :icon="contract.materials.icon"
          />
          <CardUsedLimites
            class="col-sm-6 col-md-4"
            :title="contract.readers.title"
            :available="contract.readers.available"
            :used="contract.readers.used"
            :icon="contract.readers.icon"
          />
          <CardUsedLimites
            class="col-sm-6 col-md-4"
            :title="contract.loans.title"
            :available="contract.loans.available"
            :used="contract.loans.used"
            :icon="contract.loans.icon"
          />
        </div>

        <div class="pt-4"></div>

        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
  import SidebarLeft from '../../components/SidebarLeft'
  import CardUsedLimites from '../../components/CardUsedLimites'
  import Title from '../../components/Title'

  export default{
    name: 'DashboardComponent',
    components: {
      SidebarLeft,
      CardUsedLimites,
      Title,
    },

    data() {
      return {
        loanMaterialId: null,
        returnMaterialId: null,
        contract: {
          materials: {
            title: 'Materiais',
            icon: 'library_books',
            available: 0,
            used: 0,
          },
          readers: {
            title: 'Leitores',
            icon: 'people',
            available: 0,
            used: 0,
          },
          loans: {
            title: 'Empréstimos',
            icon: 'store',
            available: 0,
            used: 0,
          },
          plan: '',
          expires: '',
        },
        waitingList: [],
      }
    },

    mounted() {
      this.getLimits();
    },

    computed: {
      contractExpire() {
        return this.$dayjs(this.contract.expires).format('DD/MM/YYYY');
      },

      daysToExpire() {
        return this.$dayjs(this.contract.expires + ' 23:59:59').diff(Date(), 'day');
      },
    },

    methods: {
      sendMessage() {
        window.location.href="https://wa.me/message/C4T6JBQX6BHJF1";
      },

      async getLimits() {

        this.$vs.loading();

        try {
          const response = await this.$axios.get(`/libraries/contracts?last`);

          this.contract.materials.available = response.data.books_limit;
          this.contract.materials.used = response.data.count_total_books;

          this.contract.readers.available = response.data.readers_limit;
          this.contract.readers.used = response.data.count_total_readers;

          this.contract.loans.available = response.data.loans_limit ?? 'Ilimitado';
          this.contract.loans.used = response.data.count_total_loans;

          this.contract.plan_id = response.data.plan.id;
          this.contract.plan = response.data.plan.name;
          this.contract.expires = response.data.expires_at;

          this.contract.paymentLink = response.data.payment_link;
          this.contract.paymentStatus = response.data.status;
          this.contract.reason = response.data.reason;

          const quotes = {
            materials: {
              hired: response.data.books_limit,
              used: response.data.count_total_books,
            },
            ebooks: {
              hired: response.data.books_limit,
              used: response.data.count_total_ebooks,
            },
            collections: {
              used: response.data.count_total_collections,
            },
            copies: {
              used: response.data.count_total_copies,
            }
          }

          this.$store.commit('updateQuotes', quotes);
          window.localStorage.setItem('quotes', JSON.stringify(quotes));

        } catch (error) {
          console.log(error);
        }

        this.$vs.loading.close();
      },

      errorNotification(message) {
        this.$vs.notify({
          title: 'Ops',
          text: message,
          color:'danger',
          position:'top-right'
        });
      },

      async loanMaterial() {
        if (!this.loanMaterialId) return this.errorNotification('Informe o número do exemplar');
        
        this.$vs.loading();
        
        try {
          const response = await this.$axios.get(`/libraries/copies?copy_number=${this.loanMaterialId}`);

          if (response.data.length === 0) return this.errorNotification('Exemplar não encontrado'); 

          this.$router.push(`/emprestimos/cadastrar?copy_id=${response.data[0].id}&book_id=${response.data[0].book_id}`);
            
        } catch(error) {
          this.errorNotification(error.response?.data.message ?? 'Erro ao recuperar');
          console.log(error)
        }

        this.$vs.loading.close();
      },

      async returnMaterial() {
        if (!this.returnMaterialId) return this.errorNotification('Informe o número do exemplar');
        
        this.$vs.loading();
        
        try {
          const response = await this.$axios.get(`/libraries/copies?copy_number=${this.returnMaterialId}`);

          if (response.data.length === 0) return this.errorNotification('Exemplar não encontrado');

          this.$router.push(`/emprestimos?copy_id=${response.data[0].id}`);
            
        } catch(error) {
          this.errorNotification(error.response?.data.message ?? 'Erro ao recuperar');
          console.log(error)
        }

        this.$vs.loading.close();
      },
    },

  }
</script>

<style lang="scss" src="./style.scss" />