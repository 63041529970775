<template>
   <div class="d-flex">
     <SidebarLeft />
     <div class="pages">
       <div class="container-fluid">
         <div class="page-title">
           <h5>Editar Exemplar {{ copy.copy_number }}</h5> 
           <span>{{ $store.state.library.name }}</span>
         </div>
         <div class="row mb-4">
           <div class="card" style="padding:20px">
               <form class="row">  
                  <div class="col-12 mb-3">
                     <label for="">Material</label>
                     <div class="text-secondary">{{ copy.book.title }} ({{ copy.book.author }})</div>
                  </div>
                  <div class="col-md-4 mb-3">
                     <label for="">Ativo</label>
                     <select class="form-select" v-model="copy.is_active">
                        <option :value="1">Sim</option>
                        <option :value="0">Não</option>
                     </select>
                  </div>
                  <div class="col-md-12 mt-2">
                     <button type="button" 
                        @click="$router.push('/acervo')" 
                        class="btn btn-outline-secondary" 
                        style="margin-right:15px">
                        Cancelar
                     </button>
                     <button type="button" 
                        @click="update" 
                        class="btn btn-primary">
                        Atualizar
                     </button>
                     <button type="button" 
                        data-bs-toggle="modal" 
                        data-bs-target="#deleteCopy"
                        class="btn btn-danger ms-3" 
                        role="button">
                        Excluir
                     </button>
                  </div>
               </form>
            </div>
         </div>
       </div>
     </div>

      <!-- Modal Copy Delete -->
      <div class="modal fade" id="deleteCopy" data-bs-keyboard="false" tabindex="-1" aria-labelledby="deleteCopyLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="deleteCopyLabel">Excluir exemplar</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <div class="mb-4">
                     Deseja excluir o exemplar <span class="fw-bolder">{{ copy.copy_number }}</span>?
                  </div>
               </div>
               <div class="modal-footer">
                  <button 
                     type="button" 
                     class="btn btn-secondary" 
                     data-bs-dismiss="modal">
                     Fechar
                  </button>
                  <button 
                     type="button"
                     class="btn btn-danger" 
                     @click="copyDeleteMethod"
                     data-bs-dismiss="modal">
                     Excluir
                  </button>
               </div>
            </div>
         </div>
      </div>
   </div>
 </template>
 
<script>
   import SidebarLeft from '../../components/SidebarLeft.vue'
 
   export default{
      name: 'AcervoEditarComponent',
      components: {
         SidebarLeft
      },
      data() {
         return {
            copy: {
               copy_number: null,
               is_active: null,
               book: {
                  title: '',
                  author: '',
               }
            },
         }
      },
 
      created() {
         this.fetchCopy();
      },
 
      methods: {
         async fetchCopy() {
            this.$vs.loading()
            
            try {
               const response = await this.$axios.get(`/libraries/copies/${this.$route.params.id}`)
               this.copy = response.data;
               this.$vs.loading.close();
   
            }catch(error) {
               this.$vs.loading.close();
               this.$vs.notify({
                  title: 'Erro',
                  text: error.response.data.data.message,
                  color:'danger',
                  position:'top-right'
               })
            }
         },

         async copyDeleteMethod() {
            this.$vs.loading();
            try {
               await this.$axios.delete(`/libraries/copies/${this.copy.id}`);
               this.$vs.loading.close();
               this.$vs.notify({
                  title: 'Sucesso',
                  text: 'Exemplar excluido com sucesso',
                  color:'success',
                  position:'top-right'
               });
               await new Promise(resolve => setTimeout(resolve, 1500));
               this.$router.push('/acervo');

            } catch(err) {
               this.$vs.loading.close();
               this.notificationError(err.response?.data.message ?? 'Erro ao excluir exemplar');
            }
         },
 
         async update() {
            this.$vs.loading();
            
            try {
               const response = await this.$axios.put(`/libraries/copies/${this.$route.params.id}`, this.copy);   
               this.$vs.notify({
                  title: 'Sucesso',
                  text: response.data.message,
                  color:'success',
                  position:'top-right'
               });
               this.$vs.loading.close();

            } catch(error) {
               this.$vs.notify({
                  title: 'Erro',
                  text: error.data.message,
                  color:'danger',
                  position:'top-right'
               });
               this.$vs.loading.close();
            }
         },

         notificationError(message) {
            return this.$vs.notify({
               title: 'Ops',
               text: message,
               color:'danger',
               position:'top-right'
            })
         }
      },
   }
 </script>
 