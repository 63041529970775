<template>
  <div class="d-md-flex">
    <SidebarLeft />
    <div class="pages">
      <div class="container-fluid">
        <div class="page-title">
          <h5>Importar uma Planilha CSV</h5> 
          <span>{{ $store.state.library.name }}</span>
        </div>

        <div class="col-sm-12">
          
          <div class="alert alert-light" role="alert">
            <span class="material-icons-outlined" style="font-size:12px">
            arrow_forward_ios
            </span>
            <a href="https://docs.google.com/spreadsheets/d/1UI7pVgmZNI4q5r4zS9V3zicemo0BrnQtYHvsJGpReMs/edit?usp=sharing" target="_blank">
              Faça uma cópia do nosso modelo no Google Docs</a> | 
            <span class="text-secondary">
              <a href="https://www.youtube.com/watch?v=4PyMWjRSxnc&t=441s" target="_blank">
              <span class="material-icons-outlined" style="font-size:14px">play_circle_outline</span> Assista ao vídeo se tiver dúvida (1:39 min)
              </a>
            </span>
          </div>

          <div v-if="imports.length > 0" class="card mb-4">
            <div class="m-3">
              <h5 class="mb-4 mt-2">Suas Importações</h5>
              
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Título</th>
                    <th scope="col">Data</th>
                    <th scope="col">Materiais</th>
                    <th scope="col">Status</th>
                    <th scope="col">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="imp, index in imports" v-bind:key="imp.id">
                    <td>{{ imp.title }}</td>
                    <td>{{ $dayjs(imp.created_at).format('DD/MM/YYYY HH:mm:ss')}}</td>
                    <td>{{ imp.total_items }}</td>
                    <td>{{ translateStatus(imp.status) }}</td>
                    <td>
                      <span 
                        @click="$router.push(`/acervo/importar/${imp.id}`)" 
                        class="badge bg-primary me-2"
                        style="cursor:pointer">
                        Detalhes</span>
                      <div 
                        v-if="imp.status !== 'success'"
                        @click="setImportDelete(imp.title, imp.id, index)" 
                        class="badge bg-danger"
                        style="cursor:pointer"
                        data-bs-toggle="modal" 
                        data-bs-target="#staticBackdrop">
                        Excluir</div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="text-secondary">
                {{ importRequest }}
              </div>
            </div>
          </div>

          <div class="card mb-4 mt-4">
            <div class="m-3 mb-0">
              <p class="text-secondary">
                <strong>Dica:</strong> 
                Você pode importar quantas planilhas quiser, inclusive criar uma planilha para cada categoria Dewey. 
                Recomendamos que cada importação tenha até 1 mil livros.
              </p>
            </div>
          </div>

          <div class="card mb-4">
            <div class="m-3">
              <h5 class="mb-3 mt-2">Importar arquivo CSV</h5>
              <input 
                    type="text"
                    class="form-control mb-3"
                    v-model="title"
                    placeholder="Dê um título à sua importação"
                  >
              <input 
                  type="file" 
                  @change="uploadFile($event)"
                  accept=".csv"
                  class="form-control" 
                  placeholder="Arquivo" />
            </div>
          </div>

          <div class="mb-3 mt-3">
            <button
              @click="storeImport"
              class="btn btn-primary">
              Fazer upload</button>
            <button
              @click="deleteAll"
              class="btn btn-danger ms-3">
              Excluir 24 horas</button>

          </div>
        </div>
      </div>
    </div>

    <!-- Modal Delete -->
    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Excluir Importação</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            Confirma a exclusão de <strong>{{ importDelete.title }}</strong>?
          </div>
          <div class="modal-footer">
            <button 
              v-on:click="closeModalDelete()"
              type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
            <button 
              v-on:click="confirmModalDelete()"
              data-bs-dismiss="modal"
              type="button" class="btn btn-danger">Excluir</button>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import SidebarLeft from '../../components/SidebarLeft'

export default {
  name: 'ImportsPage',
  components: {
    SidebarLeft,
  },

  data() {
    return {
      imports: [],
      document: null,
      title: '',
      importDelete: {
        id: null,
        title: '',
        index: null,
      }
    }
  },

  created() {
    this.fetchImports();
  },
  

  methods: {

    async fetchImports() {
      this.$vs.loading();
    
      try {
        const response = await this.$axios.get(`/libraries/imports`);
        this.imports = response.data;
        this.$vs.loading.close();
        
      } catch(error) {
        this.$vs.notify({
          title: 'Erro',
          text: error.response.data.message,
          color:'danger',
          position:'top-right'
        });

        this.$vs.loading.close();
      }
      
      this.$vs.loading.close();
    },

    uploadFile(event) {
      this.document = event.target.files[0];
    },

    async storeImport() {

      if (this.title.length < 1) {
        return this.notificationError({message: "Informe o título"});
      }

      this.$vs.loading()

      try {
        const formData = new FormData();
        formData.append('file', this.document);
        formData.append('title', this.title);
        const headers = { 'Content-Type': 'multipart/form-data' };
        const response = await this.$axios.post(`/libraries/imports`, formData, { headers });
        
        console.log(response.data.files); // binary representation of the file
        console.log(response.status); // HTTP status
        
        this.$vs.loading.close();

        await this.$vs.notify({
          title: 'Feito!',
          text: response.data.message,
          color:'success',
          position:'top-right'
        });

        window.location.reload();

      } catch(error) {
        this.notificationError({message: error.response.message});
        this.$vs.loading.close();
        console.log(error);
      }

    },

    setImportDelete(title, id, index) {
      this.importDelete.title = title;
      this.importDelete.id = id;
      this.importDelete.index = index;
    },

    async confirmModalDelete() {
      this.$vs.loading();

      try {
        const response = await this.$axios.delete(`/libraries/imports/${this.importDelete.id}`);
        this.$vs.loading.close();
        this.notificationSuccess({message: response.data.message});
        
        this.imports.splice(this.importDelete.index, 1);

      } catch (error) {
        this.$vs.loading.close();
        this.notificationError({message: error.response.data.message});
      }
    },

    closeModalDelete() {
      this.importDelete.title = "";
      this.importDelete.id = null;
      this.importDelete.index = null;
    },

    notificationError({title='Ops', message}) {
      this.$vs.notify({
        title: title,
        text: message,
        color:'danger',
        position:'top-right'
      });
    },

    notificationSuccess({title='Sucesso!', message}) {
      this.$vs.notify({
        title: title,
        text: message,
        color:'success',
        position:'top-right'
      });
    },

    async deleteAll() {
      if(!confirm('Esta ação excluirá todos os seus livros importados nas últimas 24 horas.')) {
        return false;
      }

      this.$vs.loading();

      try {
        const response = await this.$axios.delete('/libraries/imports');
        this.notificationSuccess({message: response.data.message});
        this.$vs.loading.close();

      } catch (error) {
        console.error(error);
        this.notificationError({message: error.response.data.message});
        this.$vs.loading.close();
      }

    },

    translateStatus(name) {
      if (name === 'success') return 'Sucesso';
      if (name === 'pending') return 'Pendente';
    }

  }

}
</script>
