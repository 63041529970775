<template>
    <div style="font-size:24px; font-weight:600; color:#2a2a2a; padding-bottom:20px">
        <slot name="title">{{title}}</slot>
    </div>
</template>

<script>
export default {
    name: 'Title',
    props: {
        title: {
            type: String,
            required: true
        }
    }
}
</script>