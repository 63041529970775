<template>
   <div class="d-md-flex">
     <SidebarLeft />
     <div class="pages">
       <div class="container-fluid">
         <div class="page-title">
           <h5>Dados e Relatórios</h5> 
           <span>{{ $store.state.user.name }}</span>
         </div>
         <div class="card" style="padding:20px">
            <h5>Gere relatórios</h5> 
           <div class="row">
             <div class="col-md-12 mb-3">
               <p class="my-2">Inventário</p>
               <button 
                  type="button" 
                  v-on:click="inventory" 
                  class="btn btn-outline-primary">
                  Exportar PDF</button>
             </div>

             <div class="col-md-12">
               <p class="my-2">Empréstimos</p>
               
               <div class="d-grid d-md-flex">
                  
                  <button type="button"
                    @click="loans" 
                    class="btn btn-outline-primary me-2 mb-3">
                    Empréstimos por mês
                  </button>

                  <button type="button"
                    @click="loansOverdue" 
                    class="btn btn-outline-primary me-2 mb-3">
                    Empréstimos em atraso
                  </button>

                  <div class="dropdown d-grid d-md-inline me-2 mb-3">
                      <button type="button" class="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        Livros mais solicitados
                      </button>
                      <div class="dropdown-menu">
                        <div class="py-3">
                          <div class="px-4 mb-3">
                          <label for="" class="mb-1">Data Inicial</label>
                          <input type="date" class="form-control" v-model="mostReadBooks.dateStart">
                        </div>
                        <div class="px-4 mb-3">
                          <label for="" class="mb-1">Data Final</label>
                          <input type="date" class="form-control" v-model="mostReadBooks.dateEnd">
                        </div>
                        <div class="px-4 mb-3">
                          <label for="" class="mb-1">
                            Quantidade
                            <span class="tooltip-multiline" data-tooltip="Deixe vazio para imprimir todos.">ⓘ</span>
                          </label>
                          <input type="number" class="form-control" v-model="mostReadBooks.quantity" placeholder="Todos">
                        </div>
                        <div class="px-4">
                          <button type="button" @click="mostReadBooksGenerate" class="btn btn-primary w-100">Gerar Relatório</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="dropdown d-grid d-md-inline me-2 mb-3">
                    <button type="button" class="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        Leitores mais frequentes
                      </button>
                      <div class="dropdown-menu">
                        <div class="py-3">
                          <div class="px-4 mb-3">
                          <label for="" class="mb-1">Data Inicial</label>
                          <input type="date" class="form-control" v-model="biggestReaders.dateStart">
                        </div>
                        <div class="px-4 mb-3">
                          <label for="" class="mb-1">Data Final</label>
                          <input type="date" class="form-control" v-model="biggestReaders.dateEnd">
                        </div>
                        <div class="px-4 mb-3">
                          <label for="" class="mb-1">
                            Quantidade
                            <span class="tooltip-multiline" data-tooltip="Deixe vazio para imprimir todos.">ⓘ</span>
                          </label>
                          <input type="number" class="form-control" v-model="biggestReaders.quantity" placeholder="Todos">
                        </div>
                        <div class="px-4">
                          <button type="button" @click="biggestReadersReportGenerate" class="btn btn-primary w-100">Gerar Relatório</button>
                        </div>
                      </div>
                    </div>
                  </div>
               </div>
             </div>
           </div>
         </div>
         
         <div class="card mt-4" style="padding:20px">
           <h5>Exporte seus dados (CSV)</h5> 
           <div class="d-md-flex mt-2">
             <div class="d-grid me-3 mb-3">
               <button @click="exportBooks" class="btn btn-outline-primary" type="button">Exportar acervo</button>
             </div>
             <div class="d-grid me-3 mb-3">
               <button @click="exportReaders" class="btn btn-outline-primary" type="button">Exportar leitores</button>
             </div>
             <div class="d-grid me-3 mb-3">
               <button @click="exportLoans" class="btn btn-outline-primary" type="button">Exportar empréstimos</button>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
 </template>
 
 <script>
   import SidebarLeft from '../../components/SidebarLeft'
 
   export default{
     name: 'DadosIndexPage',
     
     components: {
       SidebarLeft,
     },
 
     data() {
       return {
         userProfile: {},
         mostReadBooks: {
           dateStart: `${new Date().getFullYear()}-01-01`,
           dateEnd: `${new Date().getFullYear()}-12-31`,
           quantity: '',
         },
         biggestReaders: {
           dateStart: `${new Date().getFullYear()}-01-01`,
           dateEnd: `${new Date().getFullYear()}-12-31`,
           quantity: '',
         }
       }
     },
 
     created() {
       this.userProfile = this.$store.state.user;
     },
 
     methods: {
       async inventory() {
         
         this.$vs.loading();
 
         try {
           const response = await this.$axios.get(`/libraries/reports?report_type=books`, {responseType: 'blob'});
           const blob = new Blob([response.data], { type: 'application/pdf' });
           this.createDownload(blob, "libmin-inventario.pdf");
           this.successMessage();
           this.$vs.loading.close();
 
         } catch(error) {
           this.$vs.notify({
             title:'Ops',
             text: error.response.data.message,
             color:'danger',
             position:'top-right'
           });
           this.$vs.loading.close();
         }
       },

       async loans() {
         
         this.$vs.loading();

         try {
           const response = await this.$axios.get(`/libraries/reports/loans`, {responseType: 'blob'});
           const blob = new Blob([response.data], { type: 'application/pdf' });
           this.createDownload(blob, "libmin-emprestimos-mes-a-mes.pdf");
           this.successMessage();
           this.$vs.loading.close();
 
         } catch(error) {
           this.$vs.notify({
             title:'Ops',
             text: error.response.data.message,
             color:'danger',
             position:'top-right'
           });
           this.$vs.loading.close();
         }
       },

       async loansOverdue() {
         
         this.$vs.loading();
         try {
           const response = await this.$axios.get(`/libraries/reports/overdue-loans`, {responseType: 'blob'});
           const blob = new Blob([response.data], { type: 'application/pdf' });
           this.createDownload(blob, "libmin-emprestimos-em-atraso.pdf");
           this.successMessage();
           this.$vs.loading.close();
 
         } catch(error) {
           this.$vs.notify({
             title:'Ops',
             text: error.response.data.data.message,
             color:'danger',
             position:'top-right'
           });
           this.$vs.loading.close();
         }
       },

       async mostReadBooksGenerate() {
         this.$vs.loading();
 
         try {
           const response = await this.$axios.get(
              `/libraries/reports/most-read-books?quantity=${this.mostReadBooks.quantity}&date_start=${this.mostReadBooks.dateStart}&date_end=${this.mostReadBooks.dateEnd}`, 
              {responseType: 'blob'}
           );
           const blob = new Blob([response.data], { type: 'application/pdf' });
           var fileURL = window.URL.createObjectURL(blob);
           let tab = window.open();
           tab.location.href = fileURL;
           this.successMessage();
           this.$vs.loading.close();
 
         } catch(error) {
           this.$vs.notify({
             title:'Ops',
             text: error.response.data.message,
             color:'danger',
             position:'top-right'
           });
           this.$vs.loading.close();
         }
       },

       async biggestReadersReportGenerate() {
         this.$vs.loading();
 
         try {
           const response = await this.$axios.get(
              `/libraries/reports/biggest-readers?quantity=${this.biggestReaders.quantity}&date_start=${this.biggestReaders.dateStart}&date_end=${this.biggestReaders.dateEnd}`,
              {responseType: 'blob'}
            );
           const blob = new Blob([response.data], { type: 'application/pdf' });
           var fileURL = window.URL.createObjectURL(blob);
           let tab = window.open();
           tab.location.href = fileURL;
           this.successMessage();
           this.$vs.loading.close();
 
         } catch(error) {
           this.$vs.notify({
             title:'Ops',
             text: error.response.data.message,
             color:'danger',
             position:'top-right'
           });
           this.$vs.loading.close();
         }
       },
 
       async exportBooks() {
         this.$vs.loading();
 
         try {
           const response = await this.$axios.get(`/libraries/exports/books`);
           const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8,' });
           this.createDownload(blob, "libmin-acervo-exportado.csv");
           this.successMessage();
           this.$vs.loading.close();
 
         } catch (error) {
           console.log(error.response);
           this.$vs.loading.close();
           this.errorMessage();
         }
       },
 
       async exportReaders() {
         this.$vs.loading();
 
         try {
           const response = await this.$axios.get(`/libraries/exports/readers`);
           const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8,' });
           this.createDownload(blob, "libmin-leitores-exportado.csv");
           this.successMessage();
           this.$vs.loading.close();
 
         } catch (error) {
           console.log(error.response);
           this.$vs.loading.close();
           this.errorMessage();
         }
       },
 
       async exportLoans() {
         this.$vs.loading();
 
         try {
           const response = await this.$axios.get(`/libraries/exports/loans`);
           const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8,' });
           this.createDownload(blob, "libmin-emprestimos-exportado.csv");
           this.successMessage();
           this.$vs.loading.close();
 
         } catch (error) {
           console.log(error.response);
           this.$vs.loading.close();
           this.errorMessage();
         }
       },
 
       successMessage() {
         this.$vs.notify({
           title:'Feito!',
           text: "Dados exportados com sucesso",
           color:'success',
           position:'top-right'
         });
       },
 
       errorMessage() {
         this.$vs.notify({
           title:'Ops',
           text: "Não conseguimos exportar",
           color:'danger',
           position:'top-right'
         });
       },
 
       createDownload(blob, filename) {
         const linkForDownload = document.createElement("a");
         document.body.appendChild(linkForDownload);
         const blobUrl = window.URL.createObjectURL(blob);
         linkForDownload.href = blobUrl;
         linkForDownload.download = filename;
         linkForDownload.click();
         setTimeout(() => {
           window.URL.revokeObjectURL(blobUrl);
           document.body.removeChild(linkForDownload);
         }, 0);
       }
     }
     
   }
 </script>