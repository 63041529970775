import { createWebHistory, createRouter } from "vue-router";

// Pages
import LoginComponent from '@/pages/Auth/LoginComponent'
import ProfileSelectPage from '@/pages/Auth/ProfileSelectPage'
import PasswordRecoveryComponent from '@/pages/Auth/PasswordRecoveryComponent'
import PasswordResetComponent from '@/pages/Auth/PasswordResetComponent'
import PasswordSuccessComponent from '@/pages/Auth/PasswordSuccessComponent'
import DashboardComponent from '@/pages/Dashboard/DashboardComponent'
import AcervoComponent from '@/pages/Acervo/AcervoComponent'
import AcervoCadastrarComponent from '@/pages/Acervo/AcervoCadastrarComponent'
import AcervoDigitalCadastrarPage from '@/pages/Acervo/Digital/DigitalCadastrarPage'
import AcervoDigitalEditarPage from '@/pages/Acervo/Digital/DigitalEditarPage'
import AcervoFichaPage from '@/pages/Acervo/AcervoFichaPage.vue'
import ImportsPage from '@/pages/Acervo/ImportsPage'
import ImportDetailsPage from '@/pages/Acervo/ImportDetailsPage'
import AcervoEditarComponent from '@/pages/Acervo/AcervoEditarComponent'
import CopiasEditarPage from '@/pages/Copias/CopiasEditarPage'
import EtiquetasPage from '@/pages/Etiquetas/EtiquetasPage'
import LeitoresComponent from '@/pages/Leitores/LeitoresComponent'
import LeitoresCadastrarComponent from '@/pages/Leitores/LeitoresCadastrarComponent'
import LeitoresEditarPage from '@/pages/Leitores/LeitoresEditarPage'
import LeitoresVerPage from '@/pages/Leitores/LeitoresVerPage'
import ClassificacoesIndexPage from "../pages/Classificacoes/ClassificacoesIndexPage.vue";
import ClassificacoesCreatePage from "../pages/Classificacoes/ClassificacoesCreatePage.vue";
import EditarBibliotecasComponent from '@/pages/Bibliotecas/EditarBibliotecasComponent'
import IndexEquipePage from '@/pages/Equipe/IndexEquipePage'
import EditEquipePage from '@/pages/Equipe/EditEquipePage'
import CreateEquipePage from '@/pages/Equipe/CreateEquipePage'
import EmprestimosComponent from '@/pages/Emprestimos/EmprestimosComponent'
import EmprestimosCadastrarPage from '@/pages/Emprestimos/EmprestimosCadastrarPage'
import EmprestimosFichaPage from '@/pages/Emprestimos/EmprestimosFichaPage.vue'
import ListaEsperaIndexPage from '@/pages/ListaEspera/ListaEsperaIndexPage'
import ListaEsperaCadastrarPage from '@/pages/ListaEspera/ListaEsperaCadastrarPage'
import ListaEsperaEmprestarPage from '@/pages/ListaEspera/ListaEsperaEmprestarPage'
import AjudaComponent from '@/pages/Ajuda/AjudaComponent'
import DadosIndexPage from '@/pages/Dados/DadosIndexPage'
import AssinaturaComponent from '@/pages/Assinatura/AssinaturaComponent'
import RenovarAssinaturaPage from '@/pages/Assinatura/RenovarPage'
import PagamentoPendentePage from '@/pages/Assinatura/PagamentoPendentePage'
import PagamentoPage from '@/pages/Assinatura/PagamentoPage'
import UpgradePage from '@/pages/Assinatura/UpgradePage'
import IntegracaoIndexPage from '@/pages/Integracao/IntegracaoIndexPage'
import ColecoesIndexPage from '@/pages/Colecoes/ColecoesIndexPage'
import ColecoesCreatePage from '@/pages/Colecoes/ColecoesCreatePage'
import ColecoesShowPage from '@/pages/Colecoes/ColecoesShowPage'
import NotFound from '@/pages/Customs/NotFoundPage'

const routes = [
   { path: '/', name: 'login', component: LoginComponent },
   { path: '/password-recovery', name: 'password-recovery', component: PasswordRecoveryComponent },
   { path: '/reset-password/:type/:token', name: 'reset-password', component: PasswordResetComponent },
   { path: '/password-success', name: 'password-success', component: PasswordSuccessComponent },
   { path: '/perfis', name: 'profile-select', component: ProfileSelectPage },

   { path: '/home', name: 'home', component: DashboardComponent },
   { path: '/acervo', name: 'acervo', component: AcervoComponent },
   { path: '/acervo/cadastrar', name: 'acervo-cadastrar', component: AcervoCadastrarComponent },
   { path: '/acervo/importar', name: 'importar', component: ImportsPage },
   { path: '/acervo/importar/:id', name: 'import-details-page', component: ImportDetailsPage },
   { path: '/acervo/:id', name: 'acervo-editar', component: AcervoEditarComponent },
   { path: '/acervo/:id/ficha', name: 'acervo-ficha', component: AcervoFichaPage },

   { path: '/acervo/digital/cadastrar', name: 'acervo-digital-cadastrar', component: AcervoDigitalCadastrarPage },
   { path: '/acervo/digital/:id/editar', name: 'acervo-digital-editar', component: AcervoDigitalEditarPage },
   { path: '/acervo/etiquetas', name: 'acervo-etiquetas', component: EtiquetasPage },
   { path: '/copias/:id/editar', name: 'copias-editar', component: CopiasEditarPage },

   { path: '/classificacoes', name: 'classificacoes-index', component: ClassificacoesIndexPage },
   { path: '/classificacoes/criar', name: 'classificacoes-create', component: ClassificacoesCreatePage },

   { path: '/leitores', name: 'leitores', component: LeitoresComponent },
   { path: '/leitores/cadastrar', name: 'leitores-cadastrar', component: LeitoresCadastrarComponent },
   { path: '/leitores/:id/editar', name: 'leitores-editar', component: LeitoresEditarPage },
   { path: '/leitores/:id/ver', name: 'leitores-ver', component: LeitoresVerPage },

   { path: '/bibliotecas/editar', name: 'bibliotecas-editar', component: EditarBibliotecasComponent },

   { path: '/equipe', name: 'equipe', component: IndexEquipePage },
   { path: '/equipe/:id/editar', name: 'equipe.editar', component: EditEquipePage },
   { path: '/equipe/criar', name: 'equipe.criar', component: CreateEquipePage },

   { path: '/emprestimos', name: 'emprestimos', component: EmprestimosComponent },
   { path: '/emprestimos/cadastrar', name: 'emprestimos-cadastrar', component: EmprestimosCadastrarPage },
   { path: '/emprestimos/:id/ficha', name: 'emprestimos-ficha', component: EmprestimosFichaPage },

   { path: '/lista-espera', name: 'lista-espera', component: ListaEsperaIndexPage },
   { path: '/lista-espera/cadastrar', name: 'lista-espera-cadastrar', component: ListaEsperaCadastrarPage },
   { path: '/lista-espera/:id/emprestar', name: 'lista-espera-emprestar', component: ListaEsperaEmprestarPage },

   { path: '/meus-dados', name: 'meus-dados', component: DadosIndexPage },

   { path: '/ajuda', name: 'ajuda', component: AjudaComponent },

   { path: '/assinatura', name: 'assinatura', component: AssinaturaComponent },
   { path: '/assinatura/renovar', name: 'renovar-assinatura', component: RenovarAssinaturaPage },
   { path: '/assinatura/pagamento', name: 'status-pagamento', component: PagamentoPage },
   { path: '/assinatura/pagamento-pendente', name: 'pagamento-pendente', component: PagamentoPendentePage },
   { path: '/assinatura/melhorar', name: 'assinatura-melhorar', component: UpgradePage },

   { path: '/integracao', name: 'integracao', component: IntegracaoIndexPage },

   { path: '/colecoes', name: 'colecoes', component: ColecoesIndexPage },
   { path: '/colecoes/criar', name: 'colecoes-create', component: ColecoesCreatePage },
   { path: '/colecoes/:id', name: 'colecoes-show', component: ColecoesShowPage },

   { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
];

const router = createRouter({
   history: createWebHistory(),
   routes,
});

export default router;