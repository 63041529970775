<template>
   <div class="my-5">
      <div class="">
       <div class="container">
         <div class="page-title">
           <div class="row">
               <div class="col-md-6">
                  <h5>Selecione um perfil</h5> 
                  <span>{{ $store.state.user.name }}</span>
               </div>
               <div class="col-md-6 text-end">
                  <button class="btn btn-outline-secondary px-4 mt-2">Deslogar</button>
               </div>
           </div>
         </div>
         <div class="">
           <div class="card" style="padding:20px">
             <div class="m-4">
               <div class="row">
                  <div 
                     v-for="plan in profiles" :key="plan.id" 
                     class="col-md-4 mb-3 mx-auto text-center">
                     <div class="p-4" style="border: 1px solid #ccc; border-radius:12px">
                        <div class="fw-bolder" style="font-size: 20px;">{{ plan.name }}</div>
                        <div class="mt-2">Você é administrador</div>
                        <div class="mt-3">
                           <button 
                              @click="setProfile(plan.id)"
                              type="button"
                              class="btn btn-sm btn-primary">
                              selecionar
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="text-center mt-5">
                     <p class="text-muted">Agora o seu usuário pode administrar mais de uma biblioteca.</p>
                 </div>
             </div>
           </div>
         </div>
       </div>
      </div>
   </div>
 </template>
 
 <script>
   export default{
     name: 'ProfileSelectPage',
      data() {
         return {
            profiles: [
               {
                  id: 1,
                  name: 'BPM Lucila Macedo Deda Dos Santos',
               },
            ],
         }
      },
      methods: {
         setProfile(id) {
            // todo
            console.log(id)
            this.$router.push('/home')
         },
         logout() {
            localStorage.clear();
            this.$store.commit('logout');
            this.$router.push('/');
         }
      }
   }
 </script>