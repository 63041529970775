<template>
   <div class="d-md-flex">
      <SidebarLeft />
      <div class="pages">
         <div class="container-fluid">
            
            <div class="page-title mx-5">
               <h5>Ficha do Empréstimo</h5> 
               <span>{{ $store.state.user.name }}</span>
            </div>

            <div class="card p-4 mx-5">
               <h5 class="mb-4">
                  <strong v-if="loan.is_digital" class="badge bg-primary px-3">Material Digital</strong>
                  <strong v-else class="badge bg-secondary px-3">Material Físico</strong>
                  <span class="mx-1"></span>
                  <strong v-if="loan.status == 'returned'" class="badge bg-success px-3">Devolvido</strong>
                  <strong v-else-if="loan.status == 'authorized' && new Date(loan.date_end) < new Date()" class="badge bg-danger px-3">
                     Em Atraso
                  </strong>
                  <strong v-else-if="loan.status == 'authorized'" class="badge bg-primary px-3">Autorizado</strong>
                  <strong v-else-if="loan.status == 'canceled'" class="badge bg-danger px-3">Cancelado</strong>
                  <strong v-else class="badge bg-warning px-3">Pendente</strong>
               </h5> 
               <div class="row fs-5">
                  <div class="col-md-8 mb-3">
                     <p class="my-2">Título: {{ loan.loan_data?.book?.title }}</p>
                     <p class="my-2">Autor: {{ loan.loan_data?.book?.author }}</p>
                     <p class="my-2">Exemplar: {{ loan.copy?.copy_number || loan.loaned_copy || '-' }}</p>
                     
                     <div class="mt-4">
                        <p class="my-2">Data de empréstimo: {{ $dayjs(loan.date_start).format('DD/MM/YYYY') }}</p>
                        <p class="my-2">Previsão de devolução: {{ $dayjs(loan.date_end).format('DD/MM/YYYY') }}</p>
                     </div>

                     <div v-if="loan.annotations" class="text-danger mt-4">
                        <p>Anotações: {{ loan.annotations }}</p>
                     </div>
                  </div>

                     <div class="col-md-4 mb-3 text-end">
                     <img :src="loan.loan_data?.book?.cover ?? '../../assets/images/logo.png'" alt="" style="height: 250px">
                  </div>

                  <div class="mb-3 fw-bolder">
                     <div v-if="loan.status == 'returned'">Devolvido em <span>{{ $dayjs(loan.returned_date).format('DD/MM/YYYY') }}</span></div>
                  </div>

                  <div class="col-md-12">
                     <div v-if="loan.fine_days != null && loan.fine_days > 0 ">Dias em Atraso: {{ loan.fine_days || '-' }}</div>
                     <div v-if="loan.fine">Multa: R$ {{ loan.fine?.toFixed(2) || '0.00' }}</div>
                     <div v-if="loan.fine_payment_method">Forma de pagamento: <span class="fw-bolder">{{ paymentMethod }}</span></div>
                  </div>
               </div>
            </div>

            <div class="card p-4 mx-5 mt-4">
               <div class="col-md-12">
                  <h5 class="my-2 mb-3">Comprovantes</h5>
                  <div v-if="!loan.is_digital && loan.status == 'authorized' || loan.status == 'returned'" class="mb-2">
                     <span class="text-decoration-underline text-primary" role="button" @click="requestReceipt">
                        Comprovante de retirada
                     </span>
                  </div>
                  <div v-if="!loan.is_digital && loan.status == 'returned'">
                     <span class="text-decoration-underline text-primary" role="button" @click="returnReceipt">
                        Comprovante de devolução
                     </span>
                  </div>
                  <div v-if="loan.status != 'authorized' && loan.status != 'returned'">
                     <span class="text-muted">Este empréstimo não possui comprovantes para impressão.</span>
                  </div>
               </div>
            </div>

            <div class="card p-4 mx-5 mt-4">
               <div class="col-md-12">
                  <h5 class="my-2 mb-3">Histórico de cobranças</h5>
                  <div class="d-grid d-md-flex text-muted">
                     Nos próximos dias mostraremos mais informações nesta seção.
                  </div>
               </div>
            </div>
         </div>
      </div>
         
      <div class="card p-5 border-0" style="width: 100%; max-width: 450px">
         <h5>Informações sobre o Leitor</h5> 
         <div class="table-responsive text-muted mt-4">
            <table class="table">
               <tr>
                  <td>Nome:</td>
                  <th>{{ reader.user.name }}</th>
               </tr>
               <tr>
                  <td>Usuário:</td>
                  <th>{{ reader.user.username || 'Não informado' }}</th>
               </tr>
               <tr>
                  <td>E-mail:</td>
                  <th>{{ reader.user.email || 'Não informado' }}</th>
               </tr>
               <tr>
                  <td>Telefone:</td>
                  <th>{{ reader.user.phone || 'Não informado' }}</th>
               </tr>
               <tr>
                  <td>Documento:</td>
                  <th>{{ reader.user.document || 'Não informado' }}</th>
               </tr>
               <tr>
                  <td>Anotações:</td>
                  <th>{{ reader.custom_description || 'Não informado' }}</th>
               </tr>
            </table>

            <router-link :to="`/leitores/${reader.id}/ver`" class="btn btn-primary px-4 mt-4">ver perfil</router-link>
         </div>
         <h5 class="mt-5 mb-3">Últimos empréstimos solicitados</h5>
         <div class="table-responsive text-muted mt-4" style="max-height: 420px;">
            <table class="table">
               <tbody>
                  <tr v-for="(loan, index) in reader.loans" v-bind:key="index">
                     <td><span :class="`badge ${loanStatusColor(loan.status)}`">
                        {{ loanStatusLabel(loan.status) }}
                     </span></td>
                     <td>{{ loan.loan_data?.book?.title }}</td>
                  </tr>
               </tbody>
            </table>
         </div>
      </div>

   </div>
</template>
 
<script>
   import SidebarLeft from '../../components/SidebarLeft'

   export default {
      name: 'EmprestimosFichaPage',
      components: { SidebarLeft },
      computed: {
         paymentMethod() {
            switch(this.loan.fine_payment_method) {
               case 1: return 'Dinheiro';
               case 2: return 'Pix';
               case 3: return 'Cartão de Crédito';
               case 4: return 'Não foi cobrado';
               default: return this.loan.fine_payment_method;
            }
         }
      },
      data() {
         return {
            userProfile: {},
            reader: {
               id: '',
               user: {
                  name: '',
                  email: '',
                  phone: '',
                  document: '',
                  username: '',
               }
            },
            loan: {
               id: this.$route.params.id,
               status: '',
               copy_number: '',
               date_start: '',
               date_end: '',
               due_days: '',
               fine: '',
               reader_id: '',
            }
         }
      },

      created() {
         this.userProfile = this.$store.state.user;
      },
      
      async mounted() {
         await this.findLoan();
         this.fetchReader();
      },

      methods: {
         async findLoan() {
            this.$vs.loading();
            try {
               const response = await this.$axios.get(`/libraries/loans/${this.$route.params.id}`);
               this.loan = response.data;
               
            } catch(error) {
               console.log(error);
            }  
            this.$vs.loading.close();
         },

         async fetchReader() {
            this.$vs.loading();
            if (this.loan.reader_id == null) {
               this.errorNotification('Ainda não conseguimos mostrar os dados deste leitor.');
               return; 
            }
            try {
               const response = await this.$axios.get(`/libraries/readers/${this.loan.reader_id}?loans_limit=5`);
               this.reader = response.data;
            } catch(err) {
               this.errorNotification(err.response?.data.message ?? 'Erro');
            }
            this.$vs.loading.close();
         },

         async requestReceipt() {
            this.$vs.loading();
            const url = `${process.env.VUE_APP_API_URL}/libraries/loans/${this.loan.id}/receipts/request`;
            const options = {
               headers: {
                  'Content-Type': 'application/json',
                  'Authorization': "Bearer " + JSON.parse(localStorage.getItem('authorization')).token,
               },
            };
            await fetch(url, options)
               .then( res => res.blob() )
               .then( blob => {
                  var file = window.URL.createObjectURL(blob);
                  window.open(file, '_blank');
                  this.$vs.loading.close();
               });
            this.$vs.loading.close();
         },

         async returnReceipt() {
            this.$vs.loading();
            const url = `${process.env.VUE_APP_API_URL}/libraries/loans/${this.loan.id}/receipts/return`;
            const options = {
               headers: {
                  'Content-Type': 'application/json',
                  'Authorization': "Bearer " + JSON.parse(localStorage.getItem('authorization')).token,
               },
            };
            await fetch(url, options)
               .then( res => res.blob() )
               .then( blob => {
                  var file = window.URL.createObjectURL(blob);
                  window.open(file, '_blank');
                  this.$vs.loading.close();
               });
            this.$vs.loading.close();
         },

         loanStatusColor(value) {
            switch (value) {
               case 'authorized':
                  return 'bg-primary';
               case 'canceled':
                  return 'bg-dark';
               case 'returned':
                  return 'bg-success';
               case 'overdue':
                  return 'bg-danger';
               default:
                  return 'bg-secondary';
            }   
         },

         loanStatusLabel(value) {
            switch (value) {
               case 'authorized':
                  return 'Autorizado';
               case 'canceled':
                  return 'Cancelado';
               case 'returned':
                  return 'Devolvido';
               case 'overdue':
                  return 'Atrasado';
               default:
                  return value;
            }   
         },

         successNotification(message) {
            this.$vs.notify({
               title: 'Sucesso',
               text: message,
               color:'success',
               position:'top-right'
            });
         },

         errorNotification(message) {
            this.$vs.notify({
               title: 'Ops',
               text: message,
               color:'danger',
               position:'top-right'
            });
         }
      }
   }
</script>

<style scoped>
   .border-0 {
      border-radius: 0 !important;
   }
</style>