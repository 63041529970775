<template>
  <div class="d-md-flex">
    <SidebarLeft />
    <div class="pages">
      <div class="container-fluid">
        <div class="page-title">
          <h5>Editar Biblioteca</h5> 
          <span>{{ $store.state.library.name }}</span>
        </div>

        <div class="row mb-4">
          <div class="card" style="padding:20px">
            <form class="row">
              <div class="col-md-12 mb-3">
                <label for="title">Nome</label>
                <input 
                  type="text"
                  required 
                  class="form-control"
                  v-model="library.name"
                >
              </div>
              <div class="col-md-6 mb-3">
                <label for="">E-mail da biblioteca</label>
                <input 
                  type="text" 
                  class="form-control"
                  v-model="library.public_email"
                >
              </div>
              <div class="col-md-3 mb-3">
                <label for="">WhatsApp da biblioteca
                  <span class="tooltip-multiline" 
                    data-tooltip="Deixe vazio caso não queira receber notificações.">ⓘ</span>
                </label>
                <input 
                  type="text"
                  class="form-control"
                  v-model="library.public_phone"
                  v-mask="['(##) ####-####', '(##) #####-####']"
                >
              </div>
              <div class="col-md-3 mb-3">
                <label for="avisos-whatsapp">Notificar leitores pelo WhatsApp
                  <span class="tooltip-multiline" 
                    data-tooltip="Se ativo, os leitores vão receber avisos automáticos através do WhatsApp, 
                      como novo empréstimo, devolução e empréstimo em atraso.">ⓘ</span>
                </label>
                <select v-model="library.send_whatsapp" id="avisos-whatsapp" class="form-select">
                  <option value="1" :selected="library.send_whatsapp == 1">Ativo</option>
                  <option value="0" :selected="library.send_whatsapp == 0">Inativo</option>
                </select>
              </div>
              
              <div class="col-md-6 mb-3">
                <label>Duração dos empréstimos (em dias)</label>
                <input 
                  v-model="library.loan_duration"
                  type="number" 
                  class="form-control">
              </div>

              <div class="col-md-6 mb-3">
                <label>Limite de empréstimos simultâneos</label>
                <input 
                  v-model="library.loans_same_time"
                  type="number" 
                  class="form-control">
              </div>

              <div class="col-md-6">
                <label for="fine_type">Tipo de multa</label>
                <select id="fine_type" class="form-select" v-model="library.fine_type">
                  <option value="1" :selected="library.fine_type == 1">Diário</option>
                  <option value="2" :selected="library.fine_type == 2">Fixo</option>
                </select>
              </div>

              <div class="col-md-6 mb-3">
                <label for="fine_value">Valor da multa</label>
                <input 
                  v-model="library.fine_value"
                  type="number" 
                  class="form-control">
              </div>

              <div class="col-md-6 mb-3">
                <label>
                  Padrão de classificação
                  <span class="tooltip-multiline" 
                    data-tooltip="Fale com nosso suporte para alterar o padrão de classificação">ⓘ</span>
                </label>
                <input readonly class="form-control text-uppercase" :value="library.classification_type">
              </div>

              <div class="col-md-6 mb-3">
                <label for="">Porte da biblioteca</label>
                <select class="form-select" v-model="library.size">
                  <option selected disabled>Selecionar</option>
                  <option value="1000">Até 1 mil livros</option>
                  <option value="4000">Até 4 mil livros</option>
                  <option value="8000">Até 8 mil livros</option>
                  <option value="8001">Mais de 8 mil livros</option>
                </select>
              </div>
            
              <div class="col-md-6 mb-3">
                <label for="">Tipo de biblioteca</label>
                <select class="form-select" v-model="library.type">
                  <option selected disabled>Selecionar</option>
                  <option value="academica">Acadêmica/universitária</option>
                  <option value="pessoal">Acervo Pessoal</option>
                  <option value="comunidade">Comunidade</option>
                  <option value="empresa">Empresa</option>
                  <option value="escolar">Escola</option>
                  <option value="outro">Outro</option>
                </select>
              </div>
            
              <div class="col-md-12">
                <div class="">
                  <button @click="saveOn" type="button" class="btn btn-primary">Atualizar</button>
                </div>
              </div>
          </form>
          <div class="col-sm-12 mt-4">
            <span class="text-seconday">Biblioteca criada em {{ $dayjs($store.state.library.created_at).format("DD/MM/YYYY HH:mm") }}
            </span>
          </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="card" style="padding:20px">
            <h5>Link da biblioteca e logo</h5>
            <div class="row">
              <div class="col-md-9">
                <div class="mb-3">
                  <label for="change-link">Meu link</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon3">https://</span>
                    </div>
                    <input id="change-link" type="text" class="form-control" disabled v-model="library.code">
                    <div class="input-group-append">
                      <span class="input-group-text">.libm.in</span>
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <label for="change-primary-color">Cor principal</label>
                  <input id="change-primary-color" 
                    class="form-control" 
                    type="color" 
                    disabled
                    v-model="library.primary_color">
                </div>
                <p class="text-muted mb-0">
                  Para alterar estas informações, entre em contato com o suporte.
                </p>
              </div>
              <div class="col-md-3">
                <div class="d-flex">
                  <div class="mb-3 ms-auto">
                    <img :src="library.logo" alt="" style="width: 140px; height: 140px; object-fit: cover; border-radius: 50%; border: 1px solid #ccc">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="card" style="padding:20px">
            <h5>Dados da NFS-e</h5>
            <div class="row">
              <div class="col-md-8 mb-3">
                <label for="">Razão social ou nome completo</label>
                <div class="bg-muted form-control">{{ company.company_name ?? 'Não informado' }}</div>
              </div>
              <div class="col-md-4 mb-3">
                <label for="">CNPJ ou CPF</label>
                <div class="bg-muted form-control">{{ company.document ?? 'Não informado' }}</div>
              </div>
              <div class="col-md-8 mb-3">
                <label for="">Endereço</label>
                <input 
                  type="text"
                  class="form-control"
                  v-model="company.address"
                >
              </div>
              <div class="col-md-4 mb-3">
                <label for="">Número</label>
                <input 
                  type="text"
                  class="form-control"
                  v-model="company.address_number"
                >
              </div>
              <div class="col-md-4 mb-3">
                <label for="">CEP</label>
                <input 
                  type="text"
                  class="form-control"
                  v-model="company.zip_code"
                  v-mask="'##.###-###'"
                >
              </div>
              <div class="col-md-4 mb-3">
                <label for="">Bairro</label>
                <input 
                  type="text"
                  class="form-control"
                  v-model="company.neighborhood"
                >
              </div>
              <div class="col-md-4 mb-3">
                <label for="">Estado</label>
                <select 
                  class="form-select" 
                  aria-label="Estado"
                  v-model="company.state_id"
                  @change="fetchCities">
                    <option v-for="estado in estadosBrasil" :value="estado.id" v-bind:key="estado.id">{{estado.name}}</option>
                </select>
              </div>
              <div class="col-md-4 mb-3">
                <label for="">Cidade</label>
                <select 
                  class="form-select" 
                  aria-label=""
                  v-model="company.city_id">
                    <option value="titulo">Cidade</option>
                    <option v-for="cidade in cidades" :value="cidade.id" v-bind:key="cidade.id">{{cidade.name}}</option>
                </select>
              </div>

              <div class="col-md-12">
                <button @click="fiscalUpdate" type="button" class="btn btn-primary">Atualizar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SidebarLeft from '../../components/SidebarLeft.vue';
  import Env from '../../../env.js';
  
  export default{
    name: 'EditarBibliotecaComponent',
    components: {
      SidebarLeft
    },
    data() {
      return {
        library: {
          name: '',
          public_email: '',
          public_phone: '',
          visible_to_all: 0,
          reserved_copy: '',
          loan_duration: '',
          loan_renewable: 0,
          loans_same_time: '',
          link_calendar: '',
          classification_type: '',
          fine_type: '',
          fine_value: '',
          type: null,
          size: null,
          send_welcome_email: 0,
          logo: '',
          code: '',
          primary_color: '',
        },
        company: {
          company_name: '',
          document: '',
          address: '',
          address_number: '',
          neighborhood: '',
          zip_code: '',
          city_id: '',
          state_id: '',
          logo: '',
        },
        estadosBrasil: [],
        cidades: []
      }
    },

    created() {
      this.estadosBrasil = Env.Estados;
    },

    mounted() {
      this.fetchLibrary();
      this.fetchCities();
    },

    methods: {
      async fetchCities() {
        this.$vs.loading();
        try {
          const response = await this.$axios.get(`/mobile/cities?state_id=${this.company.state_id}`);
          this.cidades = response.data;
          this.$vs.loading.close();
          
        } catch (error) {
          console.log(error.response.data);

          this.$vs.notify({
            title:'Erro',
            text:error.response.data.message,
            color:'danger',
            position:'top-right'
          })
          this.$vs.loading.close();
        }
      },

      changeFormLink() {
        this.$vs.notify({
          title: 'Ainda não está disponível',
          text: 'Estamos trabalhando para liberar esta funcionalidade para você nas próximas horas.',
          color:'warning',
          position:'top-right'
        })
      },

      fetchLibrary() {
        const data = this.$store.state.library;

        this.library = {
          name: data.name,
          public_email: data.public_email,
          public_phone: data.public_phone,
          visible_to_all: data.visible_to_all,
          reserved_copy: data.reserved_copy,
          loan_duration: data.loan_duration,
          loan_renewable: data.loan_renewable,
          loans_same_time: data.loans_same_time,
          link_calendar: data.link_calendar,
          classification_type: data.classification_type,
          fine_type: data.fine_type,
          fine_value: data.fine_value,
          type: data.type,
          size: data.size,
          send_whatsapp: data.send_whatsapp,
          code: data.code,
          primary_color: data.primary_color,
          logo: data.logo,
        };

        this.company = {
          company_name: data.company_name,
          document: data.document,
          address: data.address,
          address_number: data.address_number,
          neighborhood: data.neighborhood,
          zip_code: data.zip_code,
          city_id: data.city_id,
          state_id: data.state_id,
          logo: data.logo,
        }
      },

      async saveOn() {
        this.$vs.loading();

        try {
          const response = await this.$axios.put(`/libraries/libraries`, this.library);
          
          const updatedData = {
            name: this.library.name,
            public_email: this.library.public_email,
            public_phone: this.library.public_phone,
            visible_to_all: this.library.visible_to_all,
            reserved_copy: this.library.reserved_copy,
            loan_duration: this.library.loan_duration,
            loan_renewable: this.library.loan_renewable,
            loans_same_time: this.library.loans_same_time,
            link_calendar: this.library.link_calendar,
            classification_type: this.library.classification_type,
            fine_type: this.library.fine_type,
            fine_value: this.library.fine_value,
            type: this.library.type,
            size: this.library.size,
            send_whatsapp: this.library.send_whatsapp,
            
            code: this.library.code,
            created_at: this.library.created_at,
            id: this.library.id,
            logo: this.library.logo,
            updated_at: this.library.updated_at,
            user_id: this.library.user_id,
            
            company_name: this.company.company_name,
            address: this.company.address,
            address_number: this.company.address_number,
            neighborhood: this.company.neighborhood,
            zip_code: this.company.zip_code,
            city_id: this.company.city_id,
            state_id: this.company.state_id,
            document: this.company.document,
          };

          localStorage.setItem('library', JSON.stringify(updatedData)); 

          this.$store.commit('updateLibrary', updatedData);

          this.$vs.notify({
            title:'Sucesso',
            text: response.data.message,
            color:'success',
            position:'top-right'
          });

        }catch(error) {
          this.$vs.notify({
            title:'Erro',
            text:error.response.data.message,
            color:'danger',
            position:'top-right'
          })
        }

        this.$vs.loading.close();
        
      },

      async fiscalUpdate() {
        this.$vs.loading();

        try {
          const response = await this.$axios.put(`/libraries/libraries/fiscal`, this.company);
          
          const updatedData = {
            name: this.library.name,
            public_email: this.library.public_email,
            public_phone: this.library.public_phone,
            visible_to_all: this.library.visible_to_all,
            reserved_copy: this.library.reserved_copy,
            loan_duration: this.library.loan_duration,
            loan_renewable: this.library.loan_renewable,
            loans_same_time: this.library.loans_same_time,
            link_calendar: this.library.link_calendar,
            classification_type: this.library.classification_type,
            fine_type: this.library.fine_type,
            fine_value: this.library.fine_value,
            type: this.library.type,
            size: this.library.size,
            send_whatsapp: this.library.send_whatsapp,
            
            code: this.library.code,
            created_at: this.library.created_at,
            id: this.library.id,
            logo: this.library.logo,
            updated_at: this.library.updated_at,
            user_id: this.library.user_id,
            
            company_name: this.company.company_name,
            address: this.company.address,
            address_number: this.company.address_number,
            neighborhood: this.company.neighborhood,
            zip_code: this.company.zip_code,
            city_id: this.company.city_id,
            state_id: this.company.state_id,
            document: this.company.document,
          };

          localStorage.setItem('library', JSON.stringify(updatedData)); 

          this.$store.commit('updateLibrary', updatedData);

          this.$vs.notify({
            title:'Sucesso',
            text: response.data.message,
            color:'success',
            position:'top-right'
          });

        }catch(error) {
          this.$vs.notify({
            title:'Erro',
            text:error.response.data.message,
            color:'danger',
            position:'top-right',
          })
        }
        this.$vs.loading.close();
      },
    },
  }
</script>

<style scoped>
  .bg-muted{
    background-color: #e9ecef;
  }
</style>