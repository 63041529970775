<template>
   <div class="d-md-flex">
     <SidebarLeft />
     <div class="pages">
       <div class="container-fluid">
         <div class="page-title">
           <h5>Lista de Espera</h5> 
           <span>{{ $store.state.library.name }}</span>
         </div>

         <div class="row mb-4">
           <div class="card" style="padding:20px">
             <form @submit.prevent="search" class="row">
               <div class="col-md-6">
                 <label for="document">Nome do leitor ou exemplar</label>
                 <input id="document" v-model="searchQuery.name" placeholder="Buscar..." class="form-control">
               </div>
               <div class="col-md-3">
                 <label for="filter">Filtro</label>
                 <select id="filter" class="form-select" v-model="searchQuery.filter">
                   <option value="name">Nome do Leitor</option>
                   <option value="copy">Número do Exemplar</option>
                 </select>
               </div>
               <div class="col-md-3">
                 <div class="d-grid">
                   <label for="">&ensp;</label>
                   <button type="button" @click="search" class="btn btn-primary">Buscar</button>
                 </div>
               </div>
             </form>
             <div class="row">
               <div class="col-12 mt-3">
                 <div class="text-secondary">
                    <span v-if="waitingList.length > 0">Mostrando as primeiros <strong>{{ waitingList.length }} reservas</strong> encontradas.</span>
                    <span v-else>Nenhuma reserva encontrada.</span>
                 </div>
               </div>
             </div>
           </div>
         </div>
 
         <div class="mt-120"></div>
 
         <div class="row">
           <div class="card">
             <div v-if="waitingList.length > 0">
               <div class="table-responsive">
                 <table class="table">
                   <thead>
                     <tr>
                       <th scope="col">Leitor</th>
                       <th scope="col">Material</th>
                       <th scope="col"></th>
                     </tr>
                   </thead>
                   <tbody>
                     <tr v-for="(item, index) in waitingList" :key="index">
                      <td>{{ item.reader.user?.name }} ({{ item.reader.external_id ?? item.reader.user.username }})</td>
                      <td>{{ item.book.title }} ({{ item.book.author }})</td>
                      <td class="text-end" style="min-width: 220px;">
                         <span
                           @click="$router.push(`/lista-espera/${item.id}/emprestar`)"
                           style="cursor:pointer"
                           class="btn btn-sm btn-primary px-3 me-md-3">
                           Emprestar
                         </span>
                         <span
                           @click="setCopyDelete(index)"
                           data-bs-toggle="modal"
                           data-bs-target="#confirmDeleteModal"
                           style="cursor:pointer"
                           class="btn btn-sm btn-danger px-3">
                           Excluir
                         </span>
                       </td>
                     </tr>
                   </tbody>
                 </table>
               </div>
             </div>
             <div v-else>
               <div class="text-center p-5">
                  <img src="@/assets/icons/stones.png" alt="">
                  <p class="fw-light mt-2">Adicione leitores à lista de espera quando um livro não estiver disponível.</p>
               </div>
            </div>
           </div>
         </div>
 
         <div class="mt-60"></div>
 
       </div>
     </div>


      <!-- Modal -->
      <div class="modal fade" id="confirmDeleteModal" tabindex="-1" aria-labelledby="confirmDeleteModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="confirmDeleteModalLabel">Confirmar exclusão</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <p>Deseja excluir este registro da lista de espera?</p>
            </div>
            <div class="modal-footer">
              <button id="modalClose" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
              <button @click="removeItemConfirm" class="btn btn-danger">Confirmar exclusão</button>
            </div>
          </div>
        </div>
      </div>

   </div>
 </template>
 
 <script>
   import SidebarLeft from '@/components/SidebarLeft.vue';
   
   export default{
     name: 'LeitoresComponent',
     components: {
       SidebarLeft,
     },
     data() {
       return {
         modalOpen: true,
         waitingList: [],
         searchQuery: {
           name: '',
           filter: 'name',
         },
         removeItem: {
          index: null,
          id: null,
         },
       };
     },
     
     mounted() {
       this.fecthWaitingList();
     },
 
     methods: {
       async fecthWaitingList() {
         this.$vs.loading();
 
         try {
           const response = await this.$axios.get(`/libraries/waiting-lists`);
           this.waitingList = response.data;
           this.$vs.loading.close();
 
         } catch (error) {
           this.$vs.loading.close();
           console.log(error);
         }
       },
 
       async search() {
         this.$vs.loading();
         
         let query = '';
 
         if(this.searchQuery.name) {
           query = '&name=' + this.searchQuery.name;
         }
 
         query += `&filter=${this.searchQuery.filter}`;
          
         try {
           const response = await this.$axios.get(`/libraries/waiting-lists?${query}`);
           this.readers = response.data
           this.$vs.loading.close();
         
         } catch(error) {
           this.$vs.notify({
             title:'Ops',
             text: error.response.data.message,
             color:'danger',
             position:'top-right'
           });
           this.$vs.loading.close();
         }
         
       },

       setCopyDelete(index) {
        this.removeItem.index = index;
        this.removeItem.id = this.waitingList[index].id;
       },

       async removeItemConfirm() {
        this.$vs.loading();

        try {
          await this.$axios.delete(`/libraries/waiting-lists/${this.removeItem.id}`);
          this.waitingList.splice(this.removeItem.index, 1);
          this.$vs.loading.close();
          document.getElementById('modalClose').click();

        } catch (error) {
          console.log(error);
          this.messageError(error.response?.data.message || 'Ocorreu um erro inesperado');
          this.$vs.loading.close();
        }
       },
       
       successNotification(message){
         this.$vs.notify({
           title:'Sucesso',
           text:  message,
           color:'success',
           position:'top-right'
         });
       },
 
       errorNotification(message) {
         this.$vs.notify({
           title: 'Erro',
           text: message,
           color:'danger',
           position:'top-right'
         });
       },
     },
   }
 </script>
 
 <style lang="scss"/>