<template>
   <div class="d-md-flex">
     <SidebarLeft />
     <div class="pages">
       <div class="container-fluid">
         <div class="page-title">
           <h5>Criar coleção</h5> 
           <span>{{ $store.state.library.name }}</span>
         </div>
         
         <div class="mt-120"></div>
        
          <div 
            v-if="$store.state.contract.plan_id == 5 && $store.state.quotes.collections.used >= 2" 
            class="container-fluid">
            <div class="card p-4">
              <div class="page-title text-center mt-4">
                <img src="@/assets/icons/ebook.png" class="mb-4" style="width:75px" alt="">
                <h5>Limite de coleções atingido</h5> 
                <span>Faça upgrade e disponibilize mais e-books para os seus leitores.</span>

                <div class="mt-5">
                  <button 
                    @click="$router.push('/assinatura/melhorar')" 
                    class="btn btn-sm btn-rounded px-3 btn-primary">conhecer planos</button>
                </div>
              </div>
            </div>
          </div>

         <div v-else class="row">
           <div class="card">
             <div class="row p-3">
               <div class="col-md-12">
                <div class="mb-3">
                  <label for="">Nome da coleção</label>
                  <input 
                    type="text" 
                    class="form-control" 
                    v-model="collection.name"
                    placeholder="Dê um nome à sua coleção">
                </div>

                <div>
                  <button class="btn btn-primary" type="button" @click="storeCollection">Cadastrar</button>
                </div>

              </div>
             </div>
           </div>
         </div>
         
         <div class="mt-60"></div>
 
       </div>
     </div>

   </div>
 </template>
 
 <script>
   import SidebarLeft from '@/components/SidebarLeft.vue';
   
   export default{
     name: 'LeitoresComponent',
     components: {
       SidebarLeft,
     },
     data() {
       return {
         modalOpen: true,
         collection: {
            name: '',
         },
       };
     },
     
      methods: {
        async storeCollection() {

          if (this.collection.name == '') {
            return this.errorNotification('Preencha o nome da coleção');
          }

          this.$vs.loading();
 
          try {
           const response = await this.$axios.post(`/libraries/collections`, this.collection);
           
           this.successNotification(response.data.data.message);
           this.$vs.loading.close();

           this.$router.push('/colecoes');
 
          } catch (error) {
            this.$vs.loading.close();
            return this.errorNotification(error.response.data.data.message ?? 'Erro ao cadastrar');
          }
        },

      successNotification(message){
         this.$vs.notify({
           title:'Sucesso',
           text:  message,
           color:'success',
           position:'top-right'
         });
       },
 
       errorNotification(message) {
         this.$vs.notify({
           title: 'Erro',
           text: message,
           color:'danger',
           position:'top-right'
         });
       },
     },
   }
 </script>
 