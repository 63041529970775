import { createApp } from 'vue'
import App from './App.vue'
import router from './routers'

import axios from 'axios'
import store from './store';

import Vuesax from 'vuesax3'
import 'bootstrap'
import 'vuesax3/dist/vuesax.css'
import 'css-tooltip/dist/css-tooltip.min.css'
import VueTheMask from 'vue-the-mask'
import dayjs from 'dayjs'

let authorizationToken = '';
if(localStorage.getItem('authorization')) {
  authorizationToken = JSON.parse(localStorage.getItem('authorization')).token;
}

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Authorization': `Bearer ${authorizationToken}`,
    'library-id': store.getters.getLibraryId
  }
});

const app = createApp(App)
app.use(store)
app.use(router)
app.use(Vuesax)
app.use(VueTheMask)
app.config.globalProperties.$axios = { ...axiosInstance }
app.config.globalProperties.$dayjs = dayjs
app.mount('#app')