<template>
   <div class="d-md-flex">
     <SidebarLeft />
     <div class="pages">
       <div class="container-fluid">
         <div class="page-title">
           <h5>Editar coleção</h5> 
           <span>{{ $store.state.library.name }}</span>
         </div>
         
         <div class="mt-120"></div>
 
         <div class="row">
           <div class="card">
             <div class="row p-3">
               <div class="col-md-12">
                <div class="mb-3">
                  <label for="">Nome da coleção</label>
                  <input 
                    type="text" 
                    class="form-control" 
                    v-model="collection.name"
                    placeholder="Dê um nome à sua coleção">
                </div>

                <div>
                  <button class="btn btn-primary" type="button" @click="updateCollection">Atualizar</button>
                </div>

              </div>
             </div>
           </div>
         </div>
         
         <div class="mt-4"></div>

         <div class="row">
            <div class="card">
             <div v-if="collection.books.length > 0">
               <div class="table-responsive">
                 <table class="table">
                   <thead>
                     <tr>
                       <th scope="col">Título</th>
                       <th scope="col">Autor</th>
                       <th scope="col"></th>
                     </tr>
                   </thead>
                   <tbody>
                     <tr v-for="item in collection.books" :key="item.id">
                       <td>{{ item?.book.title }}</td>
                       <td>{{ item?.book.author }}</td>
                       <td style="width: 70px;">
                           <span 
                              @click="setBookDelete(item.id, item.book.title)" 
                              data-bs-toggle="modal" 
                              data-bs-target="#modalDelete"
                              role="button"
                              title="Excluir">
                              <span class="material-icons-round text-danger" style="font-size: 20px;">delete</span>
                           </span>
                       </td> 
                     </tr>
                   </tbody>
                 </table>
               </div>
             </div>
             <div v-else>
               <div class="text-center p-5">
                  <img src="@/assets/icons/stones.png" alt="">
                  <div class="mt-2 mb-3">Sua coleção ainda não possui livros.</div>
                  <router-link to="/acervo" class="btn btn-sm btn-primary">Adicionar livros</router-link>
               </div>
             </div>
           </div>
         </div>

         <div class="mt-60"></div>
 
       </div>
     </div>

     <!-- Modal Delete -->
     <div class="modal fade" id="modalDelete" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalDeleteLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="modalDeleteLabel">Excluir item da coleção</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                Confirma a remoção de <span class="text-danger fw-bolder">{{ bookRemove.title }}</span> desta coleção?
              </div>
              <div class="modal-footer">
                <button 
                  type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                <button 
                  v-on:click="modalDeleteConfirm()"
                  data-bs-dismiss="modal"
                  type="button" class="btn btn-danger">Excluir</button>
              </div>
            </div>
          </div>
      </div>

   </div>
 </template>
 
 <script>
   import SidebarLeft from '@/components/SidebarLeft.vue';
   
   export default{
     name: 'LeitoresComponent',
     components: {
       SidebarLeft,
     },
     data() {
       return {
         modalOpen: true,
         collection: {
            name: '',
            id: '',
            books: [],
         },
         bookRemove: {
            id: '',
            title: '',
         }
       };
     },
     
      mounted() {
         this.fetchCollection();
      },

      methods: {
         async fetchCollection() {
            this.$vs.loading();
            
            try {
               const response = await this.$axios.get(`/libraries/collections/${this.$route.params.id}`);
               this.collection = response.data;
               this.$vs.loading.close();
            } catch (error) {
               this.$vs.loading.close();
               console.log(error);
               this.errorNotification(error.response.data.data.message ?? 'Erro ao recuperar');
            }
         },

       async updateCollection() {
         this.$vs.loading();
 
         try {
           const response = await this.$axios.put(`/libraries/collections/${this.collection.id}`, this.collection);
           
           this.successNotification(response.data.data.message);
           this.$vs.loading.close();
 
         } catch (error) {
           this.$vs.loading.close();
           this.errorNotification(error.response.data.data.message ?? 'Erro ao atualizar');
           console.log(error);
         }
       },

       setBookDelete(id, title) {
         this.bookRemove.id = id;
         this.bookRemove.title = title;  
       },

       async modalDeleteConfirm() {
         this.$vs.loading();
 
         try {
           const response = await this.$axios.delete(`/libraries/collections/books/${this.bookRemove.id}`);
            this.fetchCollection();

           this.successNotification(response.data.data.message);
           this.$vs.loading.close();
 
         } catch (error) {
           this.$vs.loading.close();
           this.errorNotification(error.response.data.data.message ?? 'Erro ao excluir');
           console.log(error);
         }
       },

      successNotification(message){
         this.$vs.notify({
           title:'Sucesso',
           text:  message,
           color:'success',
           position:'top-right'
         });
       },
 
       errorNotification(message) {
         this.$vs.notify({
           title: 'Erro',
           text: message,
           color:'danger',
           position:'top-right'
         });
       },
     },
   }
 </script>
 