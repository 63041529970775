<template>
  <div class="d-md-flex">
    <SidebarLeft />
    <div class="pages">
      <div class="container-fluid">
        <div class="page-title">
          <h5>Criar usuário assistente</h5> 
          <span>{{ $store.state.user.name }}</span>
        </div>
        <div class="card" style="padding:20px">
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="name">Nome</label>
              <input 
                  id="name" 
                  required 
                  placeholder="Ex: Ana Maria"
                  class="form-control"
                  v-model="userProfile.name">
            </div>

            <div class="col-md-12 mb-3">
              <label for="username">Usuário</label>
              <input 
                  id="username"
                  required
                  placeholder="Ex: anamaria.minhabiblioteca"
                  class="form-control"
                  v-model="userProfile.username">
            </div>
            
            <div class="col-md-12 mb-3">
              <label for="email">E-mail</label>
              <input 
                id="email"
                type="email"
                required
                placeholder="Ex: anamaria@gmail.com"
                class="form-control"
                v-model="userProfile.email">
            </div>

            <div class="col-md-12 mb-3">
              <label for="password">Senha</label>
              <input 
                id="password"
                type="password"
                required
                placeholder="Min. 6 caracteres"
                class="form-control"
                v-model="userProfile.password">
            </div>
            
            <div class="col-md-12">
              <div class="">
                <button type="button" @click="store" class="btn btn-primary px-3">Cadastrar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SidebarLeft from '../../components/SidebarLeft'

  export default{
    name: 'CreateEquipePage',
    components: { SidebarLeft },
    data() {
      return {
        userProfile: {
          name: '',
          username: '',
          email: '',
          password: '',
        }
      }
    },

    methods: {
      async store() {
        
        this.$vs.loading();

        try {
          const response = await this.$axios.post(`/libraries/teams`, this.userProfile);
          this.$vs.notify({
            title:'Feito!',
            text: response.data.message,
            color:'success',
            position:'top-right'
          });
          this.$vs.loading.close();

          this.$router.push('/equipe');

        } catch(error) {
          this.$vs.notify({
            title:'Ops',
            text: error.response.data.message,
            color:'danger',
            position:'top-right'
          });
          this.$vs.loading.close();
        }
      },
    }
    
  }
</script>