<template>
  <div class="d-md-flex">
    <SidebarLeft />
    <div class="pages">
      <div class="container-fluid">
        <div class="page-title">
          <h5>Acervo</h5> 
          <span>{{ $store.state.library.name }}</span>
        </div>
        <div class="row mb-4">
          <div class="card" style="padding:20px">
            <form @submit.prevent="searchBook" class="row">
              <div class="col-md-5">
                <label for="title">Título, autor, exemplar ou ISBN</label>
                <input 
                  id="title"
                  v-model="searchQuery.title" 
                  placeholder="Buscar..." 
                  class="form-control">
              </div>
              <div class="col-md-2">
                <label for="filter">Filtro</label>
                <select id="filter" class="form-select" v-model="searchQuery.filter">
                  <option value="title">Título</option>
                  <option value="copy">Exemplar</option>
                  <option value="isbn">ISBN</option>
                  <option value="author">Autor</option>
                </select>
              </div>
              <div class="col-md-3">
                <label for="classification">Classificação</label>
                <select class="form-select" id="classification" v-model="searchQuery.dewey_category_id">
                  <option :value="null">Todos</option>
                  <option v-for="option in dewey" v-bind:value="option.id" v-bind:key="option.id">
                    {{ option.name }}
                  </option>
                </select>
              </div>
              <div class="col-md-2">
                <div class="d-grid">
                  <label for="">&ensp;</label>
                  <button type="button" @click="searchBook()" class="btn btn-primary">Buscar</button>
                </div>
              </div>
            </form>
            <div class="row">
              <div class="col-12 mt-3">
                <div class="text-secondary">
                  Mostrando os primeiros <strong>{{ books.length }} materiais</strong> encontrados.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-120"></div>

        <div class="row">
          <div class="card">
            <div v-if="books.length > 0" class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" style="width:90px;text-align:center"></th>
                    <th scope="col">Título</th>
                    <th scope="col">Autor</th>
                    <th scope="col">Editora</th>
                    <th scope="col">Ano</th>
                    <th scope="col">Exemplares</th>
                    <th scope="col" class="text-center">Tipo</th>
                    <th scope="col" style="text-align:center">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(book, index) in books" :key="index">
                    <td style="text-align:center">
                      <div v-if="book.cover && book.cover.length > 10">
                        <img :src="book.cover" alt="Capa" style="width:90px;height:120px" class="rounded">
                      </div>
                      <div v-else>
                        <span class="material-icons-round" title="Sem capa" style="color:rgb(129 129 129)">image</span>
                      </div>
                    </td>
                    <td>
                      <div class="mb-2">
                        {{ book.title }}
                      </div>
                      <span 
                        class="custom-badge" 
                        v-if="book.call_number"
                        title="Este número te auxilia a encontrar o material no acervo"
                        data-bs-toggle="tooltip">
                        Chamada {{ book.call_number }}
                      </span>
                      <span v-if="!book.available" class="custom-badge bg-danger text-white px-2 ms-2">Não Emprestar</span>
                    </td>
                    <td>{{ book.author }}</td>
                    <td>{{ book.publisher }}</td>
                    <td>{{ book.year }}</td>
                    <td class="text-center">{{ book.copies }}</td>
                    <td class="text-center">
                      <span :class="['badge', 'rounded-pill', 'text-white', ['ebook', 'ejournal'].includes(book.material_type) ? 'bg-primary' : 'bg-secondary']">
                        {{ materialTypes.find(x => x.code == book.material_type).name }}
                      </span>

                    </td>
                    <td style="text-align:center; width:120px">
                      <router-link 
                        v-if="['ebook', 'ejournal'].includes(book.material_type)" 
                        :to="'acervo/digital/' + book.id + '/editar'" 
                        title="Editar">
                        <span class="material-icons-round" style="font-size: 20px;">edit</span>
                      </router-link>

                      <router-link v-else :to="'acervo/' + book.id" title="Editar">
                        <span class="material-icons-round" style="font-size: 20px;">edit</span>
                      </router-link>

                      <router-link :to="`/acervo/${book.id}/ficha?type=${(['ebook', 'ejournal'].includes(book.material_type) ? 'digital' : 'physical')}`" class="text-primary fw-bolder ms-3" role="button">
                        <span class="material-icons" style="font-size: 20px;">menu</span>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="p-5">
              <div class="text-center">
                Nenhum material encontrado
              </div>
            </div>
          </div>
        </div>
        
        <div class="mt-60"></div>

      <Footer />
      </div>
    </div>
  </div>
</template>

<script>
  import Env from '../../../env.js'
  import SidebarLeft from '../../components/SidebarLeft.vue'
  
  export default{
    name: 'AcervoPage',
    computed: {
      materialTypes() {
        return this.$store.getters.getAllMaterialTypes;
      }
    },
    data() {
      return {
        books: [],
        library: {
          name: ''
        },
        config: [],
        dewey: [],
        searchQuery: {
          title: '',
          filter: 'title',
          dewey_category_id: null
        },
        totalResults: 0,
      };
    },
    
    components: {
      SidebarLeft,
    },

    mounted() {
      this.dewey = Env.Dewey;
      this.fetchBooks();
    },

    methods: {
      
      async fetchBooks() {  
        this.$vs.loading();
        try {
          const response = await this.$axios.get(`/libraries/books`);
          this.books = response.data;
        } catch(err) {
          console.log(err);
        }
        this.$vs.loading.close();
      },

      searchBook() {
        this.$vs.loading();
        let query = '';
        if(this.searchQuery.title) {
          query = '&name=' + this.searchQuery.title; 
        }

        if(this.searchQuery.dewey_category_id !== null) {
          query += `&dewey_category_id=${this.searchQuery.dewey_category_id}`; 
        }

        this.$axios.get(`/libraries/books?filter=${this.searchQuery.filter}${query}`)
        .then(response => {
          this.books = response.data;
          this.totalResults = response.data.length;
          this.$vs.loading.close();

        }).catch(err => {
            this.$vs.notify({
              title: 'Erro',
              text: err.response.data.data.message,
              color:'danger',
              position:'top-right'
            })
        })
        this.$vs.loading.close();
      },
    },
  }
</script>

<style lang="scss" scoped>
.custom-badge {
  border-radius: 12px;
  padding: 4px 8px;
  background-color: #e0e0e0;
  font-size: 13px;
  font-weight: 500;
}
</style>