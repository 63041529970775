<template>
   <div class="d-md-flex">
     <SidebarLeft />
     <div class="pages">
       <div class="container-fluid">
         
         <div class="page-title">
           <h5>Coleções</h5> 
           <span>{{ $store.state.library.name }}</span>
         </div>
         

         <div class="row mb-4">
          <div class="card" style="padding:20px">
            <form @submit.prevent="searchCollections" class="row">
              <div class="col-md-10">
                <label for="title">Nome</label>
                <input 
                  id="title"
                  v-model="searchName" 
                  placeholder="Nome da coleção..." 
                  class="form-control">
              </div>
              <div class="col-md-2">
                <div class="d-grid">
                  <label for="">&ensp;</label>
                  <button type="button" @click="searchCollections" class="btn btn-primary">Buscar</button>
                </div>
              </div>
            </form>
            <div class="row">
              <div class="col-12 mt-3">
                <div class="text-secondary">
                  Mostrando os primeiros <strong>{{ collections.length }} registros</strong> encontrados.
                </div>
              </div>
            </div>
          </div>
        </div>


         <div class="mt-120"></div>
 
         <div class="row">
           <div class="card">
             <div v-if="collections.length > 0">
               <div class="table-responsive">
                 <table class="table">
                   <thead>
                     <tr>
                       <th scope="col">Código</th>
                       <th scope="col">Nome</th>
                       <th scope="col"></th>
                     </tr>
                   </thead>
                   <tbody>
                     <tr v-for="collection in collections" :key="collection.id">
                       <td>
                        <span class="badge bg-secondary px-3">
                          {{ collection?.id }}/
                          {{ collection?.code }}
                        </span>
                       </td>
                       <td>{{ collection?.name }}</td>
                       <td style="width: 60px;">
                        <span 
                              @click="copyUrl(collection.public_url)"
                              class="badge bg-secondary me-2" role="button">Copiar link</span></td>
                       <td style="width:130px;">
                           <router-link to="/acervo" class="me-3" title="Adicionar livros">
                              <span class="material-icons-round" style="font-size: 20px;">post_add</span>
                           </router-link>

                           <router-link :to="`colecoes/${collection.id}`" class="me-3" title="Editar">
                              <span class="material-icons-round" style="font-size: 20px;">edit</span>
                           </router-link>

                           <span 
                              @click="setModalDelete(collection.id, collection.name)" 
                              role="button"
                              title="Excluir"
                              data-bs-toggle="modal" 
                              data-bs-target="#modalDelete">
                              <span class="material-icons-round text-danger" style="font-size: 20px;">delete</span>
                           </span>
                       </td> 
                     </tr>
                   </tbody>
                 </table>
               </div>
             </div>
             <div v-else>
               <div class="text-center p-5">
                  <span class="material-icons-round text-primary fs-1">post_add</span>
                  <div class="mb-3">
                    Crie coleções e compartilhe os seus livros. <br>
                    Suas coleções ficam disponíveis no aplicativo e no link da biblioteca.
                  </div>
                  <router-link to="/colecoes/criar" class="btn btn-sm btn-primary">
                     Criar coleção
                  </router-link>
               </div>
             </div>
           </div>
         </div>
         
         <div class="mt-60"></div>
 
       </div>
     </div>

      <!-- Modal Delete -->
      <div class="modal fade" id="modalDelete" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalDeleteLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="modalDeleteLabel">Excluir Coleção</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                Confirma a exclusão de <span class="text-danger fw-bolder">{{ collectionDelete.name }}</span>?
              </div>
              <div class="modal-footer">
                <button 
                  type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                <button 
                  v-on:click="confirmModalDelete()"
                  data-bs-dismiss="modal"
                  type="button" class="btn btn-danger">Excluir</button>
              </div>
            </div>
          </div>
      </div>

   </div>
 </template>
 
 <script>
   import SidebarLeft from '@/components/SidebarLeft.vue';
   
   export default{
     name: 'LeitoresComponent',
     components: {
       SidebarLeft,
     },
     data() {
       return {
         modalOpen: true,
         collections: [],
         collectionDelete: {
           id: '',
           name: '',
         },
         searchName: '',
       };
     },
     
      mounted() {
         this.fecthCollections();
      },
 
     methods: {
       async fecthCollections() {
         this.$vs.loading();
 
         try {
           const response = await this.$axios.get(`/libraries/collections`);
           this.collections = response.data;
           this.$vs.loading.close();
 
         } catch (error) {
           this.$vs.loading.close();
           console.log(error);
         }
       },

       async searchCollections() {
         this.$vs.loading();
 
         try {
           const response = await this.$axios.get(`/libraries/collections?name=${this.searchName}`);
           this.collections = response.data;
           this.$vs.loading.close();
 
         } catch (error) {
           this.$vs.loading.close();
           console.log(error);
         }
       },

       setModalDelete(id, name) {
         this.collectionDelete.id = id;
         this.collectionDelete.name = name;
       },

       async confirmModalDelete() {
         this.$vs.loading();
         try {
           const response = await this.$axios.delete(`/libraries/collections/${this.collectionDelete.id}`);
           this.$vs.loading.close();
           this.successNotification(response.data.data.message);
           this.fecthCollections();
         } catch (error) {
           this.$vs.loading.close();
           this.errorNotification(error.response.data.data.message ?? 'Erro ao excluir');
         }
       },
 
       copyUrl(message) {
        navigator.clipboard.writeText(message);
        this.$vs.notify({
          title: 'Link copiado!',
          text: "A URL já está na área de transferência.",
          color:'success',
          position:'top-right',
        });
      },

      successNotification(message){
         this.$vs.notify({
           title:'Sucesso',
           text:  message,
           color:'success',
           position:'top-right'
         });
       },
 
       errorNotification(message) {
         this.$vs.notify({
           title: 'Erro',
           text: message,
           color:'danger',
           position:'top-right'
         });
       },
     },
   }
 </script>
 
 <style lang="scss"/>