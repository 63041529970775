<template>
	<div class="card">
		<vue-cal 
			:events="events"
			:time="false" 
			active-view="month"
			hide-view-selector
			:disable-views="['years', 'year', 'week']"
			events-on-month-view="short"
			locale="pt-br"
			style="min-height: 500px;"
		/>
	</div>
</template>
<script>
   import VueCal from 'vue-cal'
	import 'vue-cal/dist/vuecal.css'
   
	export default {
		name: 'app',
		components: {
			VueCal,
		},

		data() {
         return {
            events: [],
         };
      },

		mounted() {
			this.fetchEvents();
		},
		
		methods: {
			async fetchEvents() {
            
            this.$vs.loading();
            
            try {
					const response = await this.$axios.get(`/libraries/calendar`);
					
					let events = [];

					response.data.map(function(event) {
						events.push({
							start: event.date_end,
							end: event.date_end,
							title: event.user.name,
							content: `${event.book?.title} ${event.book?.author}`,
							class: event.status
						});
					});

					this.events = events;
					this.$vs.loading.close()

				} catch(err) {
					console.log(err);
					this.$vs.notify({
						title: 'Erro',
						text: err,
						color:'danger',
						position:'top-right'
					});
					this.$vs.loading.close();
				}
         },
		},
	}
</script>

<style>
.vuecal__event.pending {
	background-color: rgb(230, 182, 27);
	border: 1px solid rgb(230, 182, 27);
	color: #fff;
}

.vuecal__event.returned {
	background-color: #28a745;
	border: 1px solid #28a745;
	color: #fff;
	margin: 4px;
	width: calc(100% - 8px);
}

.vuecal__event.overdue {
	background-color: rgba(214, 36, 36, 0.9);
	border: 1px solid rgba(214, 36, 36, 0.9);
	color: #fff;
}

.vuecal__event.authorized {
	background-color: #007bff;
	border: 1px solid #007bff;
	color: #fff;
}

.vuecal__event.canceled {
	background-color: rgb(0, 0, 0);
	border: 1px solid rgb(0, 0, 0);
	color: #fff;
}

/* Dash indicator */
.vuecal__cell-events-count {
  width: 18px;
  height: 2px;
  color: red;
}

/* Dot indicator */
.vuecal__cell-events-count {
  width: 4px;
  min-width: 0;
  height: 4px;
  padding: 0;
  color: red;
}
</style>