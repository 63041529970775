<template>
   <div class="d-md-flex">
     <SidebarLeft />
     <div class="pages">
       <div class="container-fluid">
         <div class="page-title">
           <h5>Equipe</h5> 
           <span>{{ $store.state.library.name }}</span>
         </div>
         
         <div class="row mb-4">
           <div class="card" style="padding:20px">
             <form @submit.prevent="searchUsers" class="row">
               <div class="col-md-7">
                 <label for="document">Nome</label>
                 <input id="document" v-model="searchQuery.name" placeholder="Buscar..." class="form-control">
               </div>
               <div class="col-md-3">
                 <label for="">Situação</label>
                 <select 
                   v-model="searchQuery.status"
                   @change="searchUsers"
                   class="form-select" aria-label="Filtro">
                   <option value="all">Todos</option>
                   <option value="1">Ativo</option>
                   <option value="0">Bloqueado</option>
                 </select>
               </div>
               <div class="col-md-2">
                 <div class="d-grid">
                   <label for="">&ensp;</label>
                   <button @click="searchUsers" type="button" class="btn btn-primary">Buscar</button>
                 </div>
               </div>
             </form>

             <div class="row">
               <div class="col-12 mt-3">
                 <div class="text-secondary">
                   Mostrando os primeiros <strong>{{ users.length }} membros</strong> encontrados.
                 </div>
               </div>
             </div>
           </div>
         </div>
 
         <div class="mt-120"></div>
 
         <div class="row">
           <div class="card">
             <div class="table-responsive">
               <table v-if="users.length > 0" class="table">
                 <thead>
                   <tr>
                     <th scope="col">Nome</th>
                     <th scope="col">E-mail</th>
                     <th scope="col">Usuário</th>
                     <th scope="col">Tipo</th> 
                     <th scope="col">Status</th>
                     <th scope="col"></th>
                   </tr>
                 </thead>
                 <tbody>
                   <tr v-for="(user, index) in users" v-bind:key="index">
                     <td>{{ user.user.name }}</td>
                     <td>
                       {{ user.user.email ?? '-' }}
                     </td>
                     <td>
                       {{ user.user.username ?? '-' }}
                     </td>
                     <td>
                       <span v-if="(user.user.type === 'USER_LIBRARY')" class="badge bg-black px-3">Administrador</span>
                       <span v-if="user.user.type === 'USER_ATTENDANT'" class="badge bg-secondary px-3">Assistente</span>   
                     </td>
                     <td>
                       <span v-if="(user.active === 1)" class="badge bg-success px-3">Ativo</span>
                       <span v-if="user.active === 0" class="badge bg-danger px-3">Bloqueado</span>
                     </td>
                     <td>
                        <router-link 
                           v-if="$store.state.user.type === 'USER_LIBRARY'"
                           :to="{ name: 'equipe.editar', params: { id: user.id } }" 
                           title="Editar">
                           <span class="material-icons-round" style="font-size: 20px;">edit</span>
                        </router-link>
                     </td>
                   </tr>
                 </tbody>
               </table>
               <div v-else class="text-center" style="padding: 50px 0">
                 <img src="@/assets/icons/stones.png" alt="">
                 <p class="fw-light mt-2">Nenhum usuário para mostrar.</p>
               </div>
             </div>
           </div>
         </div>

       </div>
     </div>
   </div>
 </template>
 
 <script>
   import SidebarLeft from '../../components/SidebarLeft.vue'
   
   export default{
     name: 'EmprestimosComponent',
     data() {
       return {
         users: [],
         searchQuery: {
           status: 'all',
           name: '', 
         },
       };
     },
 
     components: { SidebarLeft },
     methods: {
       successNotification(message){
         this.$vs.notify({
           title:'Sucesso',
           text:  message,
           color:'success',
           position:'top-right'
         });
       },
 
       errorNotification(message) {
         this.$vs.notify({
           title: 'Ops',
           text: message,
           color:'danger',
           position:'top-right'
         });
       },
 
       async searchUsers() {
         this.$vs.loading();
         
         let queryUrl = '';
 
         if(this.searchQuery.name) {
           queryUrl = `&name=${this.searchQuery.name}`;
         }
 
         try {
           const response = await this.$axios.get(`/libraries/teams?status=${this.searchQuery.status}${queryUrl}`);
           this.users = response.data;
           this.$vs.loading.close();
 
         } catch(error) {
           this.$vs.loading.close();
           this.errorNotification(error.response?.data.message ?? 'Erro ao buscar');
           this.users = [];
         }
       },

        async fetchUsers() {
          this.$vs.loading()

          try {
            const response = await this.$axios.get(`/libraries/teams`);
            this.users = response.data;
            this.$vs.loading.close();

          } catch(error) {
            this.$vs.loading.close();
            this.errorNotification(error.response?.data.message ?? 'Erro ao buscar');
          }
        },
      },

      created() {
        this.fetchUsers();
      },
     
   }
 </script>
 