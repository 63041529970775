import { createStore } from 'vuex'

// populate with localStorage data
const localUser          = JSON.parse(localStorage.getItem('user'));
const localAuthorization = JSON.parse(localStorage.getItem('authorization'));
const localLibrary       = JSON.parse(localStorage.getItem('library'));
const localQuotes        = JSON.parse(localStorage.getItem('quotes'));
const localContract      = JSON.parse(localStorage.getItem('contract'));

export default createStore({
  state: {
    userLogged: false,
    user: localUser,
    authorization: localAuthorization,
    library: localLibrary,
    quotes: localQuotes,
    contract: localContract,
    materialTypes: [
      { code: 'book', name: 'Livro', isDigital: false },
      { code: 'old_media', name: 'Midia Antiga (CD, DVD, etc)', isDigital: false },
      { code: 'manuscript', name: 'Manuscritos', isDigital: false },
      { code: 'cartography', name: 'Cartografia', isDigital: false },
      { code: 'other', name: 'Outros', isDigital: false },
      { code: 'journal', name: 'Periódicos Impressos', isDigital: false },
      { code: 'ebook', name: 'Ebook', isDigital: true },
      { code: 'ejournal', name: 'Periódicos Online', isDigital: true },
    ]
  },

  getters: {
    userToken (state) {
      return state.authorization.token;
    },
    getLibraryId(state) {
      if(!state.library) {
        return null;
      }
      return state.library.id;
    },
    getDigitalTypes(state) {
      return state.materialTypes.filter(material => material.isDigital);
    },
    getPhysicalTypes(state) {
      return state.materialTypes.filter(material => !material.isDigital);
    },
    getAllMaterialTypes(state) {
      return state.materialTypes;
    }
  },
  
  mutations: {
    login(state, data) {
      state.userLogged    = true;
      state.user          = data.user;
      state.authorization = data.authorization;
      state.library       = data.library;
      state.contract      = data.contract;
    },
    
    logout(state) {
      state.userLogged    = false;
      state.user          = null;
      state.authorization = null;
      state.library       = null;
      state.quotes        = null;
      state.contract      = null;
    },
    
    createLibrary(state, data) {
      state.library = data;
    },

    updateLibrary(state, data) {
      state.library = data;
    },

    updateUser(state, data) {
      state.user = data;
    },
    
    updateQuotes(state, data) {
      state.quotes = data;
    },

    sumItemToQuotesCopy(state, data) {
      state.quotes.copies.used = state.quotes.copies.used + data;
    },

    minusItemToQuotesCopy(state, data) {
      state.quotes.copies.used = state.quotes.copies.used - data;
    },
  },

  actions: {
  },

  modules: {
  }
})