<template>
  <div class="main-login row align-items-center" style="height: 100vh;">
    <div>
      <div class="col-sm-12 d-md-none" style="margin-bottom:40px">
        <div class="text-center">
          <img src="@/assets/images/logo.png" alt="Logo Bibliotecas" width="160">
        </div>
      </div>
      <div class="container">
        <div class="row card-login">
          <div class="col-md-6 d-sm-none d-md-block card-login-left-bg">
            <div class="card-login-left">
              <div class="logo-bibliotecas">
                <img src="@/assets/images/logo-white.png" alt="Logo Bibliotecas" width="160">
              </div>
              <div class="mt-120">
                <h1>Bem-vindo!</h1>
                <p>Libmin simplifica e democratiza as bibliotecas.</p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card-login-right">
              <h1>Login</h1>
              <p>Faça login para continuar.</p>
              
              <div v-if="maintenance" class="alert alert-danger" role="alert">
                <strong>Aviso:</strong>
                Programamos uma atualização para este sábado, 09 de março. A previsão de retorno é até às 16h. 
              </div>
              
              <form v-else @submit.prevent="login" class="mt-15">
                <div class="mb-3">
                  <label for="email" class="form-label">Usuário ou email</label>
                  <input 
                    v-model="user.email"
                    type="text" 
                    class="form-control" 
                    name="email" 
                    placeholder="seu-email@gmail.com" />
                </div>
                <div class="mb-3">
                  <label for="senha" class="form-label">Senha</label>
                  <input 
                    v-model="user.password"
                    type="password"
                    class="form-control" 
                    name="senha" 
                    placeholder="*******" />
                </div>
                
                <div class="mb-4 d-grid">
                  <button :disabled="loading" class="btn btn-primary" type="submit">{{textBtnEntrar}}</button>
                  <span class="text-danger text-center mt-2">{{message}}</span>
                </div>
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <a href="https://libmin.com.br/?ref=libmin-cloud" style="cursor:pointer; color:#002979; font-weight: 700; text-decoration:none">Criar uma conta</a>
                  </div>
                  <div class="col-md-6 mb-3">
                    <a v-on:click="passwordRecovery" style="cursor:pointer; color:#9c9c9c; font-weight:400; text-decoration:none">Recuperar senha</a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginComponent',

  data() {
    return {
      user: {
        'email': '',
        'password': '',
      },
      loading: false,
      message: '',
      textBtnEntrar: 'Entrar',
      maintenance: false,
    };
  },

  computed: {
    loggedIn() {
      return this.$store.state.userLogged;
    }
  },

  created() {
    if (this.loggedIn) {
      this.$router.push('/home');
    }
  },

  methods: {
    async login() {
      this.textBtnEntrar = 'Aguarde...'; 
      this.loading = true;
      
      if (this.user.email.length < 5 || this.user.password.length < 5) {
        this.loading = false;
        this.textBtnEntrar = 'Entrar';
        this.message = 'Preencha os campos obrigatórios';
        return;
      }

      try {
        const response = await this.$axios.post('/libraries/auth/login', this.user);
        
        const data = {
          user: response.data.user,
          authorization: response.data.authorization,
          library: response.data.library,
          contract: response.data.contract,
        };

        this.$store.commit('login', data);

        window.localStorage.setItem('user', JSON.stringify(data.user));
        window.localStorage.setItem('authorization', JSON.stringify(data.authorization));
        window.localStorage.setItem('contract', JSON.stringify(data.contract));
        window.localStorage.setItem('library', JSON.stringify(data.library));
        window.location.href = '/home'

      } catch (error) {
        this.textBtnEntrar = 'Entrar' 
        this.loading = false
        this.message = error.response.data.message;
      }
    },

    register(){
      this.$router.push('register')
    },

    passwordRecovery(){
      this.$router.push('/password-recovery')
    }

  }
};
</script>

<style lang="scss" src="./style.scss" />