<template>
   <div class="d-md-flex">
     <SidebarLeft />
     <div class="pages">
       <div class="container-fluid">
         <div class="page-title">
           <h5>Gerar Etiquetas</h5> 
           <span>{{ $store.state.library.name }}</span>
         </div>

         <!-- Orientações -->
         <div class="card" style="padding:30px 30px">
            <h6 class="mt-2">Orientações:</h6>
            <!-- <p class="mt-1 text-secondary">- Para entender melhor o padrão das nossas etiquetas <span class="text-primary" style="cursor: pointer;" data-bs-toggle="modal" data-bs-target="#exampleModal">clique aqui</span></p> -->
            <p class="text-secondary">- Utilizamos o padrão de etiquetas 31,0x63,5mm (Pimaco A4255 ou similar).
               <a href="https://g.co/kgs/4aZSu2" target="_blank">Onde encontrar</a>
            </p>
         </div>

         <!-- Impressão por título -->
         <div class="card mt-5" style="padding:30px 30px">
            <h6 class="mb-3">Opção 1: Localizar a partir do título</h6>
            <form @submit.prevent="searchTitles" class="input-group mb-3">
               <input v-model="title" class="form-control" placeholder="Nome do material">
               <button @click="searchTitles" class="btn btn-outline-primary" type="button">Buscar</button>
            </form>

            <div>
               <div v-for="book in books" :key="book.id" class="mb-3 border-bottom">
                  <div>{{ book.title }} - {{ book.author }}</div>
                  <div>
                     <div v-for="copy in book.list_copies" :key="copy.id" class="form-check">
                        <input 
                           @change="setCopyToList(copy.copy_number, $event.target.checked)"
                           class="form-check-input" 
                           type="checkbox" 
                           :value="copy.copy_number" 
                           :id="`flexCheck_${copy.id}`">
                        <label class="form-check-label" :for="`flexCheck_${copy.id}`">
                           Exemplar {{ copy.copy_number }}
                        </label>
                     </div>
                  </div>
               </div>
            </div>

            <div class="mt-3">
               <div class="fw-bolder mb-2">Exemplares selecionados ({{ selectedBooks.length }}/27)</div>
               <div v-if="selectedBooks.length > 0">
                  
                  <span v-for="book in selectedBooks" :key="book.id" class="badge bg-info px-3 fs-6 mx-2 ms-0 mb-2">
                     Exemplar {{ book }}
                  </span>
                  
                  <div>
                     <button @click="generateByTitles" class="btn btn-outline-primary mt-3" type="button">Gerar Etiquetas</button>
                  </div>
               </div>
               <div v-else class="text-secondary">Nenhum até momento</div>
            </div>
         </div>

         <!-- Exemplar -->
         <div class="card mt-5" style="padding:30px 30px">
            <h6 class="mb-3">
               Opção 2: Localizar pelo número do exemplar
               <span class="tooltip-multiline" data-tooltip="Para mais de 1 exemplar, separe-os por vírgula. Permitido até 27 exemplares por vez">ⓘ</span>
            </h6>
            <div class="row">
               <div class="col-md-12">
                  <form @submit.prevent="generateTagsById">
                     <div class="input-group">
                        <input 
                           class="form-control"
                           v-model="bookId">
                        <button @click="generateTagsById" class="btn btn-outline-primary" type="button">Gerar Etiqueta</button>
                     </div>
                  </form>
               </div>
            </div>
         </div>

         <!-- Blocos de Etiquetas -->
         <div class="card mt-5" style="padding:30px 30px">
            <div class="">
               <h6 class="mb-3">Opção 3: Impressão em lote (todas as etiquetas)</h6>
               <div class="row">
                  <div v-for="n in totalPages" :key="n" class="col-sm-3 mb-3">
                     <button @click="generateTags(n)" class="btn btn-outline-primary" type="button">Bloco de Etiquetas {{ n +'/' + totalPages }}</button>
                  </div>
               </div>
            </div>
         </div>

       </div>
     </div>

      <!-- Modal -->
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
         <div class="modal-content">
            <div class="modal-body">
               <img src="@/assets/images/Explicando-Etiquetas.png" alt="" class="w-100">
            </div>
            <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
            </div>
         </div>
      </div>
      </div>
   </div>
 </template>
 
 <script>
   import SidebarLeft from '../../components/SidebarLeft'

   const TOTAL_ITEMS_TO_PRINT = 27;

   export default{
      name: 'EtiquetasPage',
      
      components: {
         SidebarLeft,
      },
 
      data() {
       return {
         userProfile: {},
         bookId: null,
         title: '',
         books: [],
         selectedBooks: [],
       }
      },
 
      created() {
         this.userProfile = this.$store.state.user;
      },
     
      computed: {
         totalPages() {
            return Math.ceil(this.$store.state.quotes.copies.used / TOTAL_ITEMS_TO_PRINT);
         }
      },

      methods: {
         async generateTags(page) {
            this.$vs.loading();

            const url = `${process.env.VUE_APP_API_URL}/libraries/tags-printing?page=${page}`;
            const options = {
               method: 'POST',
               headers: {
                  Authorization: "Bearer " + JSON.parse(localStorage.getItem('authorization')).token
               }
            };

            await fetch(url, options)
               .then( res => res.blob() )
               .then( blob => {
                  var file = window.URL.createObjectURL(blob);
                  window.open(file, '_blank');
                  this.$vs.loading.close();
               });

            this.$vs.loading.close();
         },
 
         async generateTagsById() {
            if (!this.bookId) {
               return false;
            }

            this.$vs.loading();
            
            const url = `${process.env.VUE_APP_API_URL}/libraries/tags-printing/many`;
            const options = {
               method: 'POST',
               headers: {
                  'Content-Type': 'application/json',
                  Authorization: "Bearer " + JSON.parse(localStorage.getItem('authorization')).token
               },
               body: JSON.stringify({ ids: this.bookId.split(',').map(id => id.trim()) })
            };

            await fetch(url, options)
               .then( res => res.blob() )
               .then( blob => {
                  var file = window.URL.createObjectURL(blob);
                  window.open(file, '_blank');
                  this.$vs.loading.close();
               });

            this.$vs.loading.close();
         },

         generateByTitles() {
            this.bookId = this.selectedBooks.join(',');
            this.generateTagsById();
            this.bookId = null;
         },

         searchTitles() {         
            this.$vs.loading();
            if(this.title.length < 3) {
               this.$vs.loading.close();
               return;
            }

            this.$axios.get(`/libraries/books?filter=title&name=${this.title}`)
            .then(response => {
               this.books = response.data;
               this.$vs.loading.close();

            }).catch(err => {
                  this.$vs.notify({
                  title: 'Erro',
                  text: err.response.data.data.message,
                  color:'danger',
                  position:'top-right'
                  })
            })
            this.$vs.loading.close();
         },

         setCopyToList(copyNumber, value) {
            if (!value) {
               const index = this.selectedBooks.indexOf(copyNumber); 
               return this.selectedBooks.splice(index, 1);
            }
            this.selectedBooks.push(copyNumber);
         },

         successMessage() {
            this.$vs.notify({
            title:'Feito!',
            text: "Dados exportados com sucesso",
            color:'success',
            position:'top-right'
            });
         },
   
         errorMessage() {
            this.$vs.notify({
            title:'Ops',
            text: "Não conseguimos exportar",
            color:'danger',
            position:'top-right'
            });
         },
      }
   }
 </script>