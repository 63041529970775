<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  beforeCreate() {
    if (localStorage.getItem('authorization')) {
      const authorization = JSON.parse(localStorage.getItem('authorization'));

      if (new Date(authorization.expires_at * 1000) < new Date()) {
        this.$store.commit('logout');
        return this.$router.push('/');
      }

      console.log(`Sessão expira em: ${new Date(authorization.expires_at * 1000).toLocaleString()}`);

    } else {
      if(this.$router.currentRoute.path != '/') {
        this.$router.push('/');
      }
    }
    
  },
  created () {
    document.title = "Dashboard | Libmin Bibliotecas";
  },
  mounted() {
    document.addEventListener( "keydown", this.onKeydownCtrlJ );
  },
  methods: {
    onKeydownCtrlJ(e) {
      if (e.ctrlKey && e.keyCode === 74) {
        // Alguns leitores ópticos abrem a guia de downloads do navegador ao ler código de barras. 
        // Esta função trata essa ação indesejada
        e.preventDefault();
      }
    },
  }
}
</script>

<style>
</style>
