<template>
   <div class="d-md-flex">
     <SidebarLeft />
     <div class="pages">
       <div class="container-fluid">
         <div class="page-title">
           <h5>Criar classificação</h5> 
           <span>{{ $store.state.library.name }}</span>
         </div>
         
         <div class="mt-120"></div>
        
          
         <div class="row">
           <div class="card">
             <div class="row p-3">
               <div class="col-md-12">
                <div class="mb-3">
                  <label for="" class="mb-1">Código da classificação</label>
                  <input
                    class="form-control" 
                    v-model="classification.code"
                    placeholder="Exemplo: A10, 0010, 0.230, etc">
                </div>

                <div class="mb-3">
                  <label for="" class="mb-1">Nome da classificação</label>
                  <input
                    class="form-control" 
                    v-model="classification.name"
                    placeholder="Exemplo: Literatura Local, Ciências da Natureza, etc">
                </div>

                <div class="row">
                    <div v-if="classifications.length > 0" class="col-md-6 mb-3">
                      <label for="" class="mb-1">Esta classificação está subordinada a outra? (opcional)</label>
                        <select
                          class="form-select" 
                          v-model="classification.parent_id">
                          <option value="">Selecione</option>
                          <option v-for="option in classifications" v-bind:value="option.id" v-bind:key="option.id">
                            {{ option.name }}
                          </option>
                        </select>
                    </div>

                    <div class="col-md-6 mb-3">
                      <label for="" class="mb-1">Cor (opcional)</label>
                      <input class="form-control" type="color" v-model="classification.color" style="min-height: 35px;">
                    </div>
                </div>

                <div>
                  <button class="btn btn-primary" type="button" @click="storeClassification">Cadastrar</button>
                </div>

              </div>
             </div>
           </div>
         </div>
         
         <div class="mt-60"></div>
 
       </div>
     </div>

   </div>
 </template>
 
 <script>
   import SidebarLeft from '@/components/SidebarLeft.vue';
   
   export default{
     name: 'LeitoresComponent',
     components: {
       SidebarLeft,
     },
     mounted() {
       this.fecthClassifications();
     },
     data() {
       return {
         modalOpen: true,
         classification: {
            code: '',
            name: '',
            parent_id: '',
            color: '#6c757d',
         },
         classifications: [],
       };
     },
     
      methods: {
        async storeClassification() {

          if (this.classification.code == '') {
            return this.errorNotification('Preencha o código da classificação');
          }

          if (this.classification.name == '') {
            return this.errorNotification('Preencha o nome da classificação');
          }

          this.$vs.loading();
 
          try {
           const response = await this.$axios.post(`/libraries/classifications`, this.classification);
           this.successNotification(response.data.message);
           this.$vs.loading.close();

           this.$router.push('/classificacoes');
 
          } catch (error) {
            this.$vs.loading.close();
            return this.errorNotification(error.response.data.data.message ?? 'Erro ao cadastrar');
          }
        },

      async fecthClassifications() {
         try {
           const response = await this.$axios.get(`/libraries/classifications`);
           this.classifications = response.data;
         } catch (error) {
           console.log(error);
         }
       },

      successNotification(message){
         this.$vs.notify({
           title:'Sucesso',
           text:  message,
           color:'success',
           position:'top-right'
         });
       },
 
       errorNotification(message) {
         this.$vs.notify({
           title: 'Erro',
           text: message,
           color:'danger',
           position:'top-right'
         });
       },
     },
   }
 </script>
 