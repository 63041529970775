<template>
  <div class="main-login">
    <div class="container top-60">
      <div class="col-sm-12 text-center ">
        <img src="@/assets/images/logo.png" alt="Logo Bibliotecas" width="160px">
      </div>
    </div>
    <div class="mt-60" style="margin-top:60px">
      <div class="container card-login">
        <div class="row top-15">
          <div class="col-md-6 d-sm-none d-md-block">
            <div class="text-center">
              <img src="@/assets/images/password-recovery.jpg" alt="" width="330">
            </div>
          </div>
          <div class="col-md-6">
            <div class="card-login-right">
              <h1>Sua nova senha</h1>
              <div class="top-30">
                <div class="mb-3">
                  <label for="new-password" class="form-label">Nova senha</label>
                  <input 
                    v-model="newPassword"
                    type="password" 
                    class="form-control" 
                    v-validate="'required'"
                  />
                </div>

                <div class="mb-3">
                  <label for="new-password-repeat" class="form-label">Repetir nova senha</label>
                  <input 
                    v-model="newPasswordRepeat"
                    type="password" 
                    class="form-control" 
                    v-validate="'required'"
                  />
                </div>
                
                <div class="mb-4 d-grid">
                  <button :disabled="loading" @click="passwordRecovery()" class="btn btn-primary" type="submit">{{textBtnEntrar}}</button>
                  <span class="text-danger text-center mt-3">{{message}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'PasswordResetComponent',

  data() {
      return {
        loading: false,
        message: '',
        newPassword: '',
        newPasswordRepeat: '',
        textBtnEntrar: 'Atualizar senha'
      }
  },

  methods: {
    passwordRecovery() {
      if(this.newPassword.length < 6 || this.newPasswordRepeat.length < 6) {
        this.message = "A nova senha precisa ter ao menos 6 caracteres.";
        return false;
      }

      if(this.newPassword != this.newPasswordRepeat) {
        this.message = "As senhas informadas não conferem.";
        return false;
      }

      this.textBtnEntrar = 'Aguarde...'; 
      this.message;
      this.loading = true;

      axios.post(
        `${process.env.VUE_APP_API_URL}libraries/auth/reset_password`,
        { 
          "password": this.newPassword,
          "token": this.$route.params.token
        }
      ).then(response => {
        this.loans = response.data.message
        this.message = response.data.message
        this.textBtnEntrar = "Entrar"

        this.$vs.notify({
          title: 'Senha foi atualizada',
          text: `Parabéns! Agora você já pode voltar a usar o Libmin.`,
          position: 'top-right',
          color: 'success'
        });
        
        if (this.$route.params.type == 'admin') {
          this.toIndex();
        } else {
          this.toMobilePageSuccess();
        }

      }).catch(( err )=>  {
        this.message = err.response.data.error
        this.textBtnEntrar = "Tente novamente"
        this.loading = false;
      });
    },

    toIndex() {
      this.$router.push('/')
    },

    toMobilePageSuccess() {
      window.location.replace('/#/password-success')
    }

  }
};
</script>

<style lang="scss" src="./style.scss" />