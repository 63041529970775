<template>
   <div class="d-flex">
      <SidebarLeft />
      <div class="pages">

         <div v-if="quotes.materials.used >= quotes.materials.hired" class="container-fluid">
            <div class="card p-4">
               <div class="page-title text-center mt-4">
                  <img src="@/assets/icons/speed-limit.png" class="mb-4" style="width:60px" alt="">
                  <h5>Limite de livros atingido</h5>
                  <span>{{ $store.state.library.name }} atingiu o limite de títulos permitidos.</span>
                  <div class="mt-4">
                     <button @click="$router.push('/assinatura/melhorar')" class="btn btn-sm btn-primary">conhecer
                        planos</button>
                  </div>
               </div>
            </div>
         </div>

         <div v-else class="container-fluid">

            <div class="page-title">
               <h5>Cadastrar Material</h5>
               <span>{{ $store.state.library.name }}</span>
            </div>

            <div class="row mb-4">
               <div class="col-sm-6 col-md-3">
                  <div class="card" style="padding:20px">
                     <div class="col-md-12 mb-3">
                           <label class="text-danger mb-1" for="material_type">Tipo de Material</label>
                           <select id="material_type" class="form-select" v-model="bookBNData.material_type">
                              <option v-for="type in materialTypes" :key="type.code" :value="type.code">
                                 {{ type.name }}
                              </option>
                           </select>
                        </div>
                  </div>
               </div>
               <div class="col-sm-6 col-md-9">
                  <div class="card" style="padding:20px">
                     <label for="isbn_code" class="mb-1">Código ISBN ou ISSN
                        <span class="tooltip-multiline" data-tooltip="ISBN-10, ISBN-13 e ISSN são aceitos. 
                        Deixe vazio caso não tenha esta informação.">ⓘ</span>
                     </label>
                     <form @submit.prevent="findISBN">
                        <div class="input-group mb-3">
                           <input id="isbn_code" class="form-control" v-model="bookBNData.isbn_code">
                           <button @click="findISBN" class="btn btn-outline-primary" type="button">Encontrar</button>
                        </div>
                     </form>
                  </div>
               </div>
            </div>

            <div class="row">
               <div class="col-sm-6 mb-4">
                  <div class="card" style="padding:20px">

                     <form class="row" enctype="multipart/form-data">

                        <!-- Cover from provider -->
                        <div v-if="hasCoverProvider" class="col-md-12 mb-3">
                           <div v-if="bookBNData.cover != null" class="text-center mb-2">
                              <img :src="bookBNData.cover" alt=""
                                 style="width:120px; height:120px; object-fit:cover; border-radius:12px;">
                           </div>
                           <div>
                              <label for="cover">URL da capa</label>
                              <input id="cover" required class="form-control" v-model="bookBNData.cover">
                           </div>
                        </div>

                        <!-- Cover from upload -->
                        <div v-if="!hasCoverProvider" class="col-md-12 mb-3">
                           <div v-if="coverFilePreview" class="text-center mb-2">
                              <img :src="coverFilePreview" alt=""
                                 style="width:120px; height:120px; object-fit:cover; border-radius:12px;">
                           </div>
                           <div>
                              <label for="cover-input">Upload da capa</label>
                              <input id="cover-input" type="file" class="form-control" @change="coverUpload($event)">
                           </div>
                        </div>

                        <div class="col-md-12 mb-3">
                           <label class="text-danger" for="title">Título da obra</label>
                           <input id="title" required class="form-control" v-model="bookBNData.title">
                        </div>

                        <div class="col-md-12 mb-3">
                           <label for="subtitle">Subtítulo</label>
                           <input id="subtitle" class="form-control" v-model="bookBNData.subtitle">
                        </div>

                        <div class="col-md-12 mb-3">
                           <label class="text-danger" for="author">Autor Principal</label>
                           <input id="author" class="form-control" v-model="bookBNData.author">
                        </div>

                        <!-- Own, Dewey or CDU classification -->
                        <div v-if="$store.state.library.classification_type === 'own'" class="col-md-12 mb-3">
                           <label for="classification_id" class="text-danger">Classificação</label>
                           <Autocomplete :items="classifications.map(item => item.code + ' ' + item.name)"
                              v-model="bookBNData.classification_id" 
                              @input="setClassification($event)"
                           />
                        </div>

                        <div v-if="$store.state.library.classification_type === 'own' && bookBNData.classification_id" class="col-md-12 mb-3">
                           <label class="mb-1">Subclassificação</label>
                           <Autocomplete :items="subclassifications.map(item => item.code + ' ' + item.name)"
                              @input="setSubclassification($event)"/>
                        </div>

                        <div v-if="$store.state.library.classification_type === 'cdu'" class="col-md-12 mb-3">
                           <label for="cdu_category_id" class="text-danger">Classificação CDU</label>
                           <select v-model="bookBNData.cdu_category_id" id="cdu_category_id" class="form-select">
                              <option v-for="item in cduCodes" :value="item.id" :key="item.id">
                                 {{ `${item.code} - ${item.name}` }}
                              </option>
                           </select>
                        </div>

                        <div v-if="$store.state.library.classification_type === 'cdd'" class="col-md-12 mb-3">
                           <label for="dewey_category_id" class="text-danger mb-1">Classificação Dewey</label>
                           <div class="d-flex gap-1 mb-3">
                              <span type="button" 
                                 @click="chatGptHelper()"
                                 data-bs-toggle="modal" 
                                 data-bs-target="#chatGptModal"
                                 title="Pedir ajuda à IA para classificação"
                                 class="ai-suggestions">
                                 <span class="material-icons text-primary" style="font-size: 26px; padding: 3px">auto_awesome</span>
                              </span>
                              <select id="dewey_category_id" class="form-select" @change="fetchDeweyCentesimal($event)"
                                 v-model="bookBNData.dewey_category_id">
                                 <option v-for="option in DeweyCodes" v-bind:value="option.id" v-bind:key="option.id"
                                    :data-code="option.code">
                                    {{ option.name }}
                                 </option>
                              </select>
                           </div>
                        </div>

                        <div v-if="$store.state.library.classification_type === 'cdd' && bookBNData.dewey_category_id" class="col-md-12 mb-3">
                           <label for="dewey_centesimal" class="mb-1">Classificação centesimal Dewey</label>
                           <Autocomplete :items="deweyCentesimals.map(item => item.code + ' ' + item.name)"
                              v-model="bookBNData.dewey_centesimal"
                              @input="setDeweyCentesimal" />
                        </div>

                        <div class="col-md-12 mb-3">
                           <label class="text-danger" for="year">Ano de Publicação</label>
                           <input id="year" class="form-control" v-model="bookBNData.year">
                        </div>

                        <div class="col-md-12 mb-3">
                           <label class="text-danger" for="publisher">Editora</label>
                           <input id="publisher" class="form-control" v-model="bookBNData.publisher">
                        </div>

                        <div class="col-md-12 mb-3">
                           <label for="place_of_publication">Local da Publicação (cidade)</label>
                           <input id="place_of_publication" class="form-control" v-model="bookBNData.place_of_publication">
                        </div>

                        <div class="col-md-12 mb-3">
                           <label for="description">Descrição Física</label>
                           <textarea id="description" 
                              placeholder="Informações como peso, altura, largura, etc." 
                              rows="2" 
                              class="form-control"
                              v-model="bookBNData.description">
                           </textarea>
                        </div>

                        <div class="col-md-12 mb-3">
                           <label for="internal_notes">Anotações internas</label>
                           <textarea id="internal_notes" placeholder="Anotações internas..." rows="2"
                              class="form-control" v-model="bookBNData.internal_notes">
                           </textarea>
                        </div>
                        
                     </form>
                  </div>
               </div>
               <div class="col-sm-6 mb-4">
                  <div class="card" style="padding:20px">
                     <form class="row">
                        <div class="col-md-12 mb-3">
                           <label class="text-danger" for="copies">Cópias no acervo</label>
                           <input id="copies" type="number" class="form-control" v-model="bookBNData.copies">
                        </div>

                        <div class="col-md-12 mb-3">
                           <label class="text-danger" for="available">Disponível para empréstimo</label>
                           <select id="available" class="form-select" v-model="bookBNData.available">
                              <option value="1">Sim</option>
                              <option value="0">Não</option>
                           </select>
                        </div>

                        <div v-if="collections.length > 0" class="col-md-12 mb-3">
                           <label for="collections" class="mb-1">
                              Adicionar a uma coleção
                              <span class="tooltip-multiline" data-tooltip="Agora ficou mais fácil adicionar o seu livro a uma coleção">ⓘ</span>
                           </label>
                           <select id="collections" class="form-select" v-model="bookBNData.collection_id">
                              <option :value="null">Selecione</option>
                              <option v-for="coolection in collections" v-bind:key="coolection.id" v-bind:value="coolection.id">{{ coolection.name }}</option>
                           </select>
                        </div>

                        <div class="col-md-12 mb-3">
                           <label for="authors_others">Outros autores</label>
                           <input id="authors_others" required class="form-control" v-model="bookBNData.authors_others">
                        </div>

                        <div class="col-md-12 mb-3">
                           <label for="total_pages">Páginas</label>
                           <input id="total_pages" type="number" class="form-control" v-model="bookBNData.total_pages">
                        </div>

                        <div class="col-md-12 mb-3">
                           <label for="edition">Edição</label>
                           <input id="edition" type="string" class="form-control" v-model="bookBNData.edition">
                        </div>

                        <div class="col-md-12 mb-3">
                           <label for="volume">Volume</label>
                           <input id="volume" type="string" class="form-control" v-model="bookBNData.volume">
                        </div>

                        <div class="col-md-12 mb-3">
                           <label for="language">Idioma</label>
                           <input id="language" class="form-control" v-model="bookBNData.language">
                        </div>

                        <div class="col-md-12 mb-3">
                           <label class="mb-1" for="description">Descrição curta (resuma esta obra em até 240 caracteres)</label>
                           <textarea id="description" 
                              placeholder="Você pode falar sobre este material em até 240 caracteres" 
                              rows="2" 
                              maxlength="240"
                              class="form-control"
                              v-model="bookBNData.resume">
                           </textarea>
                        </div>

                        <div v-if="bookBNData.material_type === 'url'" class="col-md-12 mb-3">
                           <label for="source_url" class="text-danger mb-1">URL externa (somente HTTPS)</label>
                           <input id="source_url" class="form-control" v-model="bookBNData.source_url" placeholder="Ex: https://www.scielo.br/">
                        </div>
                     </form>
                  </div>
               </div>
            </div>

            <div class="row">
               <div class="col-md-12">
                  <button type="button" v-on:click="storeBook" class="btn px-4 btn-primary">Concluir Cadastro</button>
               </div>
            </div>

         </div>

      </div>

      <!-- Modal GPT -->
      <div class="modal fade" id="chatGptModal" tabindex="-1" aria-labelledby="chatGptModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="chatGptModalLabel">
                     <span class="material-icons text-primary" style="font-size: 22px;">auto_awesome</span>
                     <span class="ms-1">Sugestões da IA</span>
                  </h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <div class="typing-info" v-if="chatGptSuggestions.length > 0">
                     <div v-for="suggestion in chatGptSuggestions" :key="suggestion" class="mb-2">
                        <span class="fw-bold badge rounded-pill bg-primary px-3 me-2">{{ suggestion.number }}</span> 
                        <span>{{ suggestion.title }}</span>
                     </div>
                  </div>
                  <div v-else>Informe o título e autor para receber sugestões de classificação.</div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>

      <!-- Modal Success -->
      <div class="modal fade" id="successModal" data-bs-backdrop="static" tabindex="-1"
         aria-labelledby="successModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
               <div class="modal-body text-center py-5">
                  <div class="mb-4">
                     <img src="@/assets/icons/like-icon.svg" alt="" style="width: 90px;">
                  </div>
                  <h5 class="text-success fw-bold">O material foi cadastrado com sucesso!</h5>
                  <p class="text-muted">Como você deseja continuar?</p>
                  <div class="mt-4 d-flex justify-content-center">
                     <button onclick="window.location.reload()" class="btn btn-outline-primary rounded px-3 me-md-3">
                        Cadastrar novo
                     </button>
                     <button onclick="window.location.href = '/acervo'" class="btn btn-primary px-3">
                        Mostrar no acervo
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>

   </div>
</template>

<script>
import Env from '../../../env.js'
import SidebarLeft from '../../components/SidebarLeft.vue'
import * as bootstrap from 'bootstrap'
import Autocomplete from '../../components/Autocomplete.vue'

export default {
   name: 'AcervoCadastrarComponent',
   components: {
      SidebarLeft,
      Autocomplete
   },

   computed: {
      materialTypes() {
         return this.$store.getters.getPhysicalTypes;
      },
   },

   data() {
      return {
         inputUrl: '',
         bookBNData: {
            available: 1,
            copies: 1,
            material_type: 'book',
            cover: null,
            dewey_category_id: null,
            dewey_centesimal: "",
            cdu_category_id: null,
            isbn_code: '',
            cover_file: null,
            title: '',
            author: '',
            total_pages: null,
            language: null,
            publisher: null,
            year: null,
            description: null,
            subtitle: null,
            authors_others: null,
            edition: null,
            internal_notes: null,
            collection_id: null,
            source_url: null,
            place_of_publication: null,
            classification_id: null,
         },
         btnGetBNText: 'Carregar',
         DeweyCodes: [],
         deweyCentesimals: [],
         cduCodes: [],
         classifications: [],
         subclassifications: [],
         config: [],
         message: '',
         onContinue: false,
         chatGptSuggestions: [],
         quotes: {
            materials: {
               hired: 50,
               used: 0,
            }
         },
         hasCoverProvider: null,
         coverFilePreview: null,
         collections: [],
      }
   },

   mounted() {
      this.getContractLimits();
      this.fetchCduCategories();
      this.getCollections();
      this.getClassifications();
      this.loadDeweys();
   },

   methods: {
      loadDeweys() {
         this.DeweyCodes = [...Env.Dewey];
      },
      async storeBook() {
         if (this.bookBNData.title == null)
            return this.notificationError({ title: "Campo obrigatório", message: "Informe o título do mayerial" });

         if (this.bookBNData.author == null)
            return this.notificationError({ title: "Campo obrigatório", message: "Informe o autor principal" });

         if (this.bookBNData.dewey_category_id == null && this.bookBNData.cdu_category_id == null && this.bookBNData.classification_id == null)
            return this.notificationError({ title: "Campo obrigatório", message: "Informe o código de classificação" });

         this.$vs.loading()

         try {

            const headers = { 'Content-Type': 'multipart/form-data' };

            const formData = new FormData();
            for (const key in this.bookBNData) {
               if (!this.bookBNData[key]) continue;
               formData.append(key, this.bookBNData[key]);
            }

            if (this.bookBNData.cover_file) {
               formData.delete('cover');
            }

            await this.$axios.post(`/libraries/books`, formData, { headers });
            this.$store.commit('sumItemToQuotesCopy', this.bookBNData.copies ?? 1);
            this.$vs.loading.close();

            var successModal = new bootstrap.Modal(document.querySelector('#successModal'), {
               keyboard: false
            })
            successModal.show();

         } catch (error) {
            this.$vs.loading.close();
            console.log(error)
            this.notificationError({ message: error.response?.data?.message ?? 'Erro ao cadastrar' });
         }
      },

      async coverUpload(event) {

         if (event.target.files[0].type != 'image/jpeg' && event.target.files[0].type != 'image/png') {
            return this.notificationError({ title: "Formato inválido", message: "Deve ser uma imagem JPG ou PNG" });
         }

         if (event.target.files[0].size > 2097152) {
            return this.notificationError({ title: "Tamanho inválido", message: "A imagem deve ser menor que 2MB" });
         }

         this.bookBNData.cover_file =  event.target.files[0];
         this.coverFilePreview = URL.createObjectURL(event.target.files[0]);
      },

      async getCollections() {
         this.$vs.loading();
         try {
            const response = await this.$axios.get(`/libraries/collections`);
            this.collections = response.data;
            this.$vs.loading.close();

         } catch (error) {
            this.$vs.loading.close();
            return this.notificationWarning({ message: error.data.data.message });
         }
      },

      async getClassifications() {
         if (this.$store.state.library.classification_type != 'own') {
            return;
         }
         this.$vs.loading();
         try {
            const response = await this.$axios.get(`/libraries/classifications`);
            this.classifications = response.data;
            this.$vs.loading.close();
         } catch (error) {
            this.$vs.loading.close();
            return this.notificationWarning({ message: error.data.message });
         }
      },

      async getSubclassifications() {
         this.$vs.loading();
         try {
            const response = await this.$axios.get(`/libraries/classifications/${this.bookBNData.classification_id}`);
            this.subclassifications = response.data.children;
            this.$vs.loading.close();
         } catch (error) {
            this.$vs.loading.close();
            return this.notificationWarning({ message: error.data.message });
         }
      },

      async chatGptHelper() {

         if (this.bookBNData.title.length < 3 || this.bookBNData.author.length < 3) {
            return;
         }

         this.$vs.loading();

         try {
            const response = await this.$axios.post(`/libraries/books/openai`, {
               title: this.bookBNData.title,
               author: this.bookBNData.author,
            });

            this.chatGptSuggestions = response.data;

            this.$vs.loading.close();

         } catch (error) {
            this.$vs.loading.close();
            this.chatGptSuggestions = [];
            return this.notificationWarning({ message: error.data.data.message });
         }
      },

      async findISBN() {

         if (this.bookBNData.isbn_code.length == 8 || this.bookBNData.isbn_code.length == 9) {
            return this.notificationWarning({ message: "O autocomplete de dados ainda não está disponível para publicações do tipo ISSN."});
         }
         
         if (this.bookBNData.isbn_code.length < 10) {
            return this.notificationWarning({ message: "Informe um ISBN válido." });
         }

         this.bookBNData.isbn_code = this.bookBNData.isbn_code.replace(/[^0-9]/g, "");

         this.$vs.loading()

         try {
            const response = await this.$axios.get(`https://tools.libmin.com.br/isbn/${this.bookBNData.isbn_code}`);

            this.bookBNData.title = response.data.title;
            this.bookBNData.author = response.data.author;
            this.bookBNData.total_pages = response.data.pages;
            this.bookBNData.language = response.data.language;
            this.bookBNData.publisher = response.data.publisher;
            this.bookBNData.year = response.data.year;
            this.bookBNData.cover = response.data.cover;
            this.bookBNData.description = response.data.dimensions;

            this.hasCoverProvider = (response.data.cover) ? true : false;

            this.$vs.loading.close();

         } catch (error) {
            this.$vs.loading.close();

            if (error.response.status == 400) {
               return this.notificationWarning({ message: error.response.data.message });
            }

            return this.notificationError({ message: "Ocorreu um erro. Preencha os campos de forma manual ou fale com o nosso suporte para obter ajuda." });
         }
      },

      async fetchDeweyCentesimal(event) {
         if (!event || event.length < 3) {return;}
         this.$vs.loading()
         try {
            const code = event.target.options[event.target.options.selectedIndex].getAttribute('data-code');
            const response = await this.$axios.get(`/libraries/dewey-categories?code=${code}`);
            this.deweyCentesimals = response.data;
            this.$vs.loading.close();

         } catch (error) {
            console.log(error);
            this.notificationError({ message: error.response.data?.data.message });
         }
      },

      setDeweyCategory(event) {
         const response = this.DeweyCodes.find(item => item.name === event);
         this.bookBNData.dewey_category_id = response?.id;
         this.fetchDeweyCentesimal(response?.code);
      },

      setDeweyCentesimal(event) {
         const code = event.split(' ')[0];
         if (!code || code.length < 3) {
            return this.bookBNData.dewey_centesimal = null;   
         }
         this.bookBNData.dewey_centesimal = code;
      }, 

      setClassification(event) {
         const code = event.split(' ')[0];
         if (!code || code.length == 0) {
            return; 
         }
         const response = this.classifications.find(item => item.code === code);
         this.bookBNData.classification_id = response.id;
         this.getSubclassifications();
      }, 

      setSubclassification(event) {
         const code = event.split(' ')[0];
         if (!code || code.length == 0) {
            return;
         }
         const response = this.subclassifications.find(item => item.code === code);
         this.bookBNData.classification_id = response.id;
      },

      async fetchCduCategories() {
         if (this.$store.state.library.classification_type != 'cdu') {
            return;
         }
         this.$vs.loading()

         try {
            const response = await this.$axios.get(`/libraries/cdu-categories`);
            this.cduCodes = response.data;
            this.$vs.loading.close();

         } catch (error) {
            console.log(error);
            this.notificationError({ message: error.response.data.data.message });
         }
      },

      notificationError({ title = 'Ops!', message }) {
         this.$vs.notify({
            title: title,
            text: message,
            color: 'danger',
            position: 'top-right'
         });
      },

      notificationWarning({ title = 'Ops', message }) {
         this.$vs.notify({
            title: title,
            text: message,
            color: 'warning',
            position: 'top-right'
         });
      },

      getContractLimits() {
         this.quotes = JSON.parse(localStorage.getItem('quotes'));
      }

   }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.ai-suggestions {
   border-radius:50%; 
   width: 40px; 
   height: 40px; 
   border: none;
   animation: brightness 3s infinite;
}
@keyframes brightness {
   0% {
      filter: saturate(50%) brightness(100%);
   }
   50% {
      filter: saturate(50%) brightness(110%)
   }
   100% {
      filter: saturate(120%) brightness(120%);
   }
}
</style>
