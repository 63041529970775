<template>
   <div class="d-md-flex">
      <SidebarLeft />
      <div class="pages">
         <div class="container-fluid">
            
            <div class="page-title">
               <h5>Classificações da Biblioteca</h5> 
               <span>{{ $store.state.library.name }}</span>
            </div>

            <div class="row mb-4">
               <div class="card" style="padding:20px">
                  <form @submit.prevent="searchClassifications" class="row">
                     <div class="col-md-10">
                        <label for="title">Nome</label>
                        <input 
                           id="title"
                           v-model="searchName" 
                           placeholder="Nome da classificação..." 
                           class="form-control">
                     </div>
                     <div class="col-md-2">
                        <div class="d-grid">
                           <label for="">&ensp;</label>
                           <button type="button" @click="searchClassifications" class="btn btn-primary">Buscar</button>
                        </div>
                     </div>
                  </form>
                  <div class="row">
                     <div class="col-12 mt-3">
                        <div class="text-secondary">
                           Mostrando os primeiros <strong>{{ classifications.length }} registros</strong> encontrados.
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div class="mt-120"></div>
   
            <div class="row">
               <div class="card">
                  <div v-if="classifications.length > 0">
                     <div class="table-responsive">
                     <table class="table">
                        <thead>
                           <tr>
                              <th scope="col">Código</th>
                              <th scope="col">Nome</th>
                              <th scope="col"></th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr v-for="collection in classifications" :key="collection.id">
                           <td style="width:120px;">
                              <span class="badge px-3 py-2 bg-secondary" :style="`font-size: 14px; background-color: ${collection.color} !important`">{{ collection?.code }}</span>
                           </td>
                           <td>{{ collection?.name }}</td>
                           <td style="width:130px;">
                                 <router-link :to="`classificacoes/${collection.id}/editar`" class="me-3" title="Editar">
                                    <span class="material-icons-round" style="font-size: 20px;">edit</span>
                                 </router-link>

                                 <span 
                                    @click="setModalDelete(collection.id, collection.name)" 
                                    role="button"
                                    title="Excluir"
                                    data-bs-toggle="modal" 
                                    data-bs-target="#modalDelete">
                                    <span class="material-icons-round text-danger" style="font-size: 20px;">delete</span>
                                 </span>
                           </td> 
                           </tr>
                        </tbody>
                     </table>
                     </div>
                  </div>
                  <div v-else>
                     <div class="text-center p-5">
                        <span class="material-icons-round text-primary fs-1">post_add</span>
                        <div class="mb-3">
                        Agora você pode customizar a classificação do seu acervo. Crie e edite as categorias. <br>
                        </div>
                        <router-link to="/classificacoes/criar" class="btn btn-sm px-3 btn-rounded btn-primary">
                           Criar classificação
                        </router-link>
                     </div>
                  </div>
               </div>
            </div>
            
            <div class="mt-60"></div>
   
         </div>
      </div>

      <!-- Modal Delete -->
      <div class="modal fade" id="modalDelete" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalDeleteLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalDeleteLabel">Excluir Classificação</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <span class="fw-bolder text-danger">Atenção!</span>
                  Esta ação excluirá <span class="fw-bolder">{{ collectionDelete.name }}</span> e todas as suas subclassificações. 
                  Deseja continuar?
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                  <button v-on:click="confirmModalDelete()"
                     data-bs-dismiss="modal"
                     type="button" class="btn btn-danger">
                     Excluir
                  </button>
               </div>
            </div>
         </div>
      </div>

   </div>
 </template>
 
 <script>
   import SidebarLeft from '@/components/SidebarLeft.vue';
   
   export default{
      name: 'ClassificacoesIndexPage',
      components: {
         SidebarLeft,
      },
      data() {
         return {
            modalOpen: true,
            classifications: [],
            collectionDelete: {
               id: '',
               name: '',
            },
            searchName: '',
         };
      },
      
      mounted() {
         this.fecthClassifications();
      },
 
      methods: {
         async fecthClassifications() {
            this.$vs.loading();
            try {
               const response = await this.$axios.get(`/libraries/classifications`);
               this.classifications = response.data;
               this.$vs.loading.close();
            } catch (error) {
               this.$vs.loading.close();
               console.log(error);
            }
         },

         async searchClassifications() {
            this.$vs.loading();
            try {
               const response = await this.$axios.get(`/libraries/classifications?name=${this.searchName}`);
               this.classifications = response.data;
               this.$vs.loading.close();
   
            } catch (error) {
               this.$vs.loading.close();
               console.log(error);
            }
         },

         setModalDelete(id, name) {
            this.collectionDelete.id = id;
            this.collectionDelete.name = name;
         },

         async confirmModalDelete() {
            this.$vs.loading();
            try {
               const response = await this.$axios.delete(`/libraries/classifications/${this.collectionDelete.id}`);
               setTimeout(() => {
                  this.successNotification(response.data.message);
               }, 500);
               this.$vs.loading.close();
               this.fecthClassifications();
            } catch (error) {
               this.$vs.loading.close();
               this.errorNotification(error.response.data.message ?? 'Erro ao excluir');
            }
         },

         successNotification(message){
            this.$vs.notify({
               title:'Sucesso',
               text:  message,
               color:'success',
               position:'top-right'
            });
         },

         errorNotification(message) {
            this.$vs.notify({
               title: 'Erro',
               text: message,
               color:'danger',
               position:'top-right'
            });
         },
      },
   }
 </script>
 
 <style lang="scss"/>