<template>
   <div class="d-flex">
     <SidebarLeft />
     <div class="pages">
       <div class="container-fluid">
         <div class="page-title">
           <h5>Confirmar empréstimo</h5>
           <span>{{ $store.state.library.name }}</span>
         </div>
         <div class="row mb-4">
           <div class="card" style="padding:20px">
 
            <div class="mb-3">
              <label for="">Leitor</label>
              <div>
                {{ waitingListItem.reader.user.name }}
                ({{ waitingListItem.reader.user.username }})
              </div>
              <div>Telefone: {{ waitingListItem.reader.user.phone }}</div>
              <div>Email: {{ waitingListItem.reader.user.email }}</div>
              <div>{{ waitingListItem.reader.external_id }}</div>
            </div>

            <div class="mb-3">
              <label for="">Material</label>
              <div class="mb-3">
                <img :src="waitingListItem.book?.cover" alt="" class="img-fluid rounded" style="height: 200px; width: 150px; object-fit: cover;">
              </div>
              <div>Título: {{ waitingListItem.book?.title }}</div>
              <div>Autor: {{ waitingListItem.book?.author }}</div>
              <div class="mb-3">Ano: {{ waitingListItem.book?.year }}</div>

              <div class="col-3">
                <label for="copy_number" class="mb-2">Exemplar</label>
                <select v-model="form.copy_id" id="copy_number" class="form-select">
                  <option v-for="option in waitingListItem.book?.list_copies" 
                    :key="option.id"
                    :value="option.id">
                    {{ option.copy_number }}
                  </option>
                </select>
              </div>

            </div>

            <div class="mt-4">
              <button class="btn btn-primary" @click="loanStore">Confirmar empréstimo</button>
            </div>

           </div>
         </div>
 
       </div>
     </div>
   </div>
 </template>
 
 <script>
   import SidebarLeft from '../../components/SidebarLeft.vue'
 
   export default{
     name: 'LeitoresCadastrarComponent',
     components: {
       SidebarLeft,
     },
 
     data() {
       return {
        waitingListItem: {
          reader: {
            user: {
              name: '',
              username: '',
              phone: '',
              email: ''
            },
            external_id: ''
          },
          book: {
            title: '',
            author: '',
            year: '',
            cover: '',
            copies: []
          }
        },
        form: {
          copy_id: '',
          expected_date: new Date().toISOString().slice(0, 10)
        }
       }
     },
 
     mounted() {
      this.findItem()
     },

     methods: {

      async findItem() {
        this.$vs.loading();

        try {
          const response = await this.$axios.get(`/libraries/waiting-lists/${this.$route.params.id}`);
          this.waitingListItem = response.data;
          this.$vs.loading.close();
        } catch (error) {
          this.messageError(error.response.data.message);
          this.$vs.loading.close();
          console.log(error);
        }
      },

      async loanStore() {

        if (!this.form.copy_id) {
          return this.messageError('Selecione um exemplar');
        }
 
        this.form.user_id = this.waitingListItem.reader.user.id;
        this.form.book_id = this.waitingListItem.book.id;

        if (!this.form.user_id) {
          return this.messageError('Selecione um leitor');
        }

        if (!this.form.copy_id) {
          return this.messageError('Selecione um exemplar');
        }

        this.$vs.loading();
 
        try {
          const response = await this.$axios.post(`/libraries/loans`, this.form);
          this.$vs.notify({
            title:'Feito!',
            text: response.data.message,
            color:'success',
            position:'top-right'
          });

          this.itemWaitingListDelete();

          this.$vs.loading.close();

          setTimeout(() => {
            this.$router.push('/emprestimos');
          }, 2000);

        } catch (error) {
          this.messageError(error.response.data.message);
          this.$vs.loading.close();
          console.log(error);
        }
      },

      async itemWaitingListDelete() {
        await this.$axios.delete(`/libraries/waiting-lists/${this.$route.params.id}`);
      },
      
      messageError(message) {
        return this.$vs.notify({
          title: 'Ops',
          text: message,
          color:'danger',
          position:'top-right'
        });
      }
    }
  }
 </script>
 