<template>
  <div class="d-flex">
    <SidebarLeft />
    <div class="pages">
      <div class="container-fluid">
        <div class="page-title">
          <h5>Editar Leitor</h5>
          <span>{{ $store.state.library.name }}</span>
        </div>
        <div class="row mb-4">
          <div class="card" style="padding:20px">

            <div class="row">
              <div class="col-md-6 mb-3">
                <label class="text-danger" for="">Usuário</label>
                <input
                  class="form-control"
                  v-model="reader.username"
                  readonly>
              </div>
              <div class="col-md-6 mb-3">
                <label for="">Nova senha
                  <span class="tooltip-multiline"
                    data-tooltip="Deixe vazio caso não queira alterar.">ⓘ</span>
                </label>
                <input
                  class="form-control"
                  placeholder="Deixe vazio se não deseja alterar"
                  v-model="reader.password">
              </div>

              <div class="col-md-6 mb-3">
                <div class="">
                  <label class="text-danger" for="">Nome do leitor</label>
                  <input
                    class="form-control"
                    v-model="reader.name"
                    placeholder="Nome"
                    aria-label="Nome">
                </div>
              </div>

              <div class="col-md-6 mb-3">
                <label for="">Documento (opcional)</label>
                <input
                  class="form-control"
                  v-model="reader.document"
                  placeholder="RG, CPF, Matrícula ou outro">
              </div>

              <div class="col-md-6 mb-3">
                <label for="">Email (opcional)</label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="Email"
                  v-model="reader.email">
              </div>

              <div class="col-md-6 mb-3">
                <div class="">
                  <label for="">Telefone (opcional)</label>
                  <input
                    class="form-control"
                    v-model="reader.phone"
                    v-mask="'(##) #####-####'"
                    placeholder="Telefone"
                    aria-label="Telefone">
                </div>
              </div>

              <div class="col-md-6 mb-4">
                <label class="text-danger" for="">Perfil</label>
                <select v-model="reader.type_reader" class="form-select">
                  <option value="1">Comum (leitor padrão)</option>
                  <option value="2">Interno (equipe, professor, colaborador, etc)</option>
                </select>
              </div>

              <div class="col-md-6 mb-4">
                <label class="text-danger" for="">Situação da matrícula</label>
                <select v-model="reader.status" class="form-select">
                  <option value="active">Ativo</option>
                  <option value="removed">Removido</option>
                  <option value="pending">Aprovação Pendente</option>
                </select>
              </div>
              
              <div class="col-md-12">
                <button @click="$router.back()" 
                  class="btn btn-outline-primary px-3 btn-rounded me-2">Cancelar</button>
                <button
                  @click="saveOn"
                  class="btn btn-primary px-3 btn-rounded" type="button">Atualizar</button>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import SidebarLeft from '../../components/SidebarLeft.vue'

  export default{
    name: 'LeitoresEditarPage',
    components: {
      SidebarLeft,
    },

    data() {
      return {
        reader: {
          id: '',
          email: '',
          name: '',
          phone: '',
          document: '',
          username: '',
          password: '',
          type_reader: '',
          status: '',
        },
      }
    },

    mounted() {
      this.fetchReader();
    },

    methods: {
      async fetchReader() {
        this.$vs.loading();
        try {
          const response = await this.$axios.get(`/libraries/readers/${this.$route.params.id}`);

          this.reader.id = response.data.id;
          this.reader.email = response.data.user.email ?? '';
          this.reader.name = response.data.user.name;
          this.reader.phone = response.data.user.phone ?? '';
          this.reader.document = response.data.user.document ?? '';
          this.reader.username = response.data.user.username ?? 'criado com e-mail';
          this.reader.custom_description = response.data.custom_description ?? null;
          this.reader.external_id = response.data.external_id ?? null;
          this.reader.type_reader = response.data.type_reader ?? '';
          this.reader.status = response.data.status;

          this.$vs.loading.close();

        } catch (error) {
          this.messageError(error.response?.data.message ?? 'Erro');
          this.$vs.loading.close();
          console.log(error);
        }
      },

      async saveOn() {

        if (this.reader.name.length < 3) {
          return this.messageError('Nome obrigatório');
        }

        if (this.reader.phone.length > 1 && this.reader.phone.length < 11) {
          return this.messageError('Telefone incorreto');
        }

        if (this.reader.password.length > 1 && this.reader.password.length < 5) {
          return this.messageError('Senha precisa ter pelo menos 5 caracteres');
        }

        this.$vs.loading();

        try {
          const response = await this.$axios.put(`/libraries/readers/${this.reader.id}`, this.reader);
          this.$vs.notify({
            title:'Feito!',
            text: response.data.message,
            color:'success',
            position:'top-right'
          });
          this.$vs.loading.close();

        } catch (error) {
          this.messageError(error.response.data.message);
          this.$vs.loading.close();
          console.log(error);
        }
      },

      messageError(message) {
        return this.$vs.notify({
          title: 'Ops',
          text: message,
          color:'danger',
          position:'top-right'
        });
      }
    }
  }
</script>
