<template>
  <div class="d-md-flex">
    <SidebarLeft />
    <div class="pages">
      <div class="container-fluid">
        <div class="page-title">
          <h5>Preciso de Ajuda</h5> 
          <span>Suporte do Libmin</span>
        </div>
        
        <div class="alert alert-light d-flex align-items-center" role="alert">
          <span class="material-icons-outlined">support</span>
          <div class="ms-2">
            Ficou com alguma dúvida? Seja qual for, vamos te ajudar.
          </div>
        </div>

        <div class="top-60"></div>
        
        <div class="row">
            <div class="col-sm-4 mb-3">
                <a href="https://wa.me/message/C4T6JBQX6BHJF1" target="_blank" class="card p-3 text-center" style="text-decoration:none">
                  <img src="@/assets/icons/whatsapp-icon.svg" alt="" style="width:22px; margin: 0 auto; margin-bottom: 2px;">
                  WhatsApp
                </a>
            </div>

            <div class="col-sm-4 mb-3">
                <a href="javascript:void()" @click="copyEmailAddress" class="card p-3 text-center" style="text-decoration:none">
                  <span class="material-icons-outlined">
                    email
                  </span>
                  E-mail
                </a>
            </div>

            <div class="col-sm-4 mb-3">
                <a 
                  href="https://blog.libmin.com.br/category/dicas-para-gestores/" 
                  target="_blank" 
                  class="card p-3 text-center" 
                  style="text-decoration:none">
                  <span class="material-icons-outlined">
                    quiz
                  </span>
                  FAQ
                </a>
            </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import SidebarLeft from '../../components/SidebarLeft'
  
  export default{
    name: 'PerfilComponent',
    components: {
      SidebarLeft,
    },
    data() {
        return {
          userProfile: []
        }
    },

    mounted() {
      this.userProfile = JSON.parse(localStorage.getItem('user'))
    },

    methods: {
      copyEmailAddress() {
        let message = 'atendimento@libmin.com.br';
        navigator.clipboard.writeText(message);

        this.$vs.notify({
          title: 'E-mail copiado!',
          text: "Copiamos atendimento@libmin.com.br para a área de transferência.",
          color:'success',
          position:'top-right',
        });
      },
    }
    
  }
</script>

<style lang="scss">
</style>
