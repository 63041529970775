<template>
  <div class="d-md-flex">
    <SidebarLeft />
    <div class="pages">
      <div class="container-fluid">
        <div class="page-title">
          <h5>Editar usuário assistente</h5> 
          <span>{{ $store.state.user.name }}</span>
        </div>
        <div class="card" style="padding:20px">
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="name">Nome</label>
              <input 
                  id="name" 
                  required 
                  class="form-control"
                  disabled
                  v-model="userProfile.name">
            </div>

            <div class="col-md-12 mb-3">
              <label for="email">E-mail</label>
              <input 
                  id="email"
                  class="form-control"
                  disabled
                  :value="userProfile.email">
            </div>

            <div class="col-md-12 mb-3">
              <label for="email">Usuário</label>
              <input 
                  id="email"
                  class="form-control"
                  disabled
                  :value="userProfile.username">
            </div>
            
            <div class="col-md-12 mb-3">
              <label for="status">Status</label>
              <select class="form-select" v-model="userProfile.status">
                <option value="1" :selected="userProfile.status == 1">Ativo</option>
                <option value="0" :selected="userProfile.status == 0">Bloqueado</option>
              </select>
            </div>

            <div class="col-md-12">
              <div class="">
                <button type="button" v-on:click="profileUpdate()" class="btn btn-primary">Atualizar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SidebarLeft from '../../components/SidebarLeft'

  export default{
    name: 'PerfilComponent',
    
    components: {
      SidebarLeft,
    },

    data() {
      return {
        userProfile: {
          name: '',
          email: '',
          password: '',
          status: null,
          username: '',
        }
      }
    },

    created() {
      this.fetchUser();
    },

    methods: {

      async fetchUser() {
        this.$vs.loading();

        try {
          const response = await this.$axios.get(`/libraries/teams/${this.$route.params.id}`);
          this.userProfile = response.data.user;
          this.userProfile.status = response.data.active;
          this.userProfile.id = response.data.id;
          this.$vs.loading.close();

        } catch(error) {
          console.log(error)
          this.$vs.notify({
            title:'Ops',
            text: error.response?.data.data.message ?? 'Ocorreu um erro',
            color:'danger',
            position:'top-right'
          });
          this.$vs.loading.close();
        }
      },

      async profileUpdate() {
        this.$vs.loading();
        try {
          const response = await this.$axios.put(`/libraries/teams/${this.userProfile.id}`, {
            status: this.userProfile.status
          });
          this.$vs.notify({
            title:'Feito!',
            text: response.data.message,
            color:'success',
            position:'top-right'
          });
          this.$vs.loading.close();

        } catch(error) {
          this.$vs.notify({
            title:'Ops',
            text: error.response?.data.data.message ?? 'Ocorreu um erro',
            color:'danger',
            position:'top-right'
          });
          this.$vs.loading.close();
        }
      },
    }
    
  }
</script>