<template>
   <div class="d-flex">
     <SidebarLeft />
     <div class="pages">
       <div class="container-fluid">
         <div class="page-title">
           <h5>Adicionar à lista de espera</h5>
           <span>{{ $store.state.library.name }}</span>
         </div>
         <div class="row mb-4">
           <div class="card" style="padding:20px">
 
             <div class="mb-3">
              <label for="">Número do exemplar ou título</label>
              
              <form @submit.prevent="findBook" class="input-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="bookQuery.title"
                  placeholder="Ex: 1001, Alice no País">
                
                  <select id="filter" class="form-select" v-model="bookQuery.filter">
                    <option value="title">Título</option>
                    <option value="copy">Exemplar</option>
                    <option value="isbn">ISBN</option>
                    <option value="author">Autor</option>
                  </select>
                  
                  <button
                    @click="findBook"
                    class="btn btn-primary" type="button">Encontrar
                  </button>
              </form>

              <div class="mt-3">
                <div v-for="book in books" :key="book.id" class="form-check mb-2">
                  <input class="form-check-input" 
                    type="radio" 
                    :value="book.id" 
                    v-model="form.book_id" 
                    :id="`book-${book.id}`"
                    @click="setCopies(book.list_copies)">
                  <label class="form-check-label text-dark" :for="`book-${book.id}`">
                    {{ `${book.title} (${book.author}), ${book.year}` }}
                  </label>
                </div>
              </div>

             </div>

             <div class="mb-3">
              <label for="">Nome ou matrícula do leitor</label>
              <form @submit.prevent="findReader" class="input-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="readerQuery.name">

                  <select id="filter" class="form-select" v-model="readerQuery.filter">
                    <option value="name">Nome</option>
                    <option value="username">Usuário</option>
                  </select>
                  
                  <button
                    @click="findReader"
                    class="btn btn-primary" type="button">Encontrar</button>
              </form>
              <div class="mt-3">
                <div v-for="reader in readers" :key="reader.id" class="form-check mb-2">
                  <input 
                    class="form-check-input" 
                    type="radio" 
                    :value="reader.id" 
                    v-model="form.reader_id" 
                    :id="`reader-${reader.id}`">
                  <label class="form-check-label text-dark" :for="`reader-${reader.id}`">
                    <div>{{ reader.user.name }} - {{ reader.user.external_id ?? reader.user.document }}</div>
                    <div>{{ reader.custom_description ?? reader.user.username }}</div>
                  </label>
                </div>
              </div>
             </div>

             <div>
               <button class="btn btn-primary px-3" @click="store">Cadastrar</button>
             </div>

           </div>
         </div>
 
       </div>
     </div>
   </div>
 </template>
 
 <script>
   import SidebarLeft from '../../components/SidebarLeft.vue'
 
   export default{
     name: 'LeitoresCadastrarComponent',
     components: {
       SidebarLeft,
     },
 
     data() {
       return {
        bookQuery: {
          title: '',
          filter: 'title'
        },
        books: [],
        readerQuery: {
          name: '',
          filter: 'name'
        },
        readers: [],
        form: {
          reader_id: null,
          book_id: null,
        }
       }
     },
 
     methods: {

      async findBook() {
        if (this.bookQuery.title.length < 1) {
          return this.messageError('Título precisa ter ao menos 1 caractere');
        }

        this.copies = [];
        
        this.$vs.loading();

        try {
          const response = await this.$axios.get(`/libraries/books?filter=${this.bookQuery.filter}&name=${this.bookQuery.title}`);
          this.books = response.data;
          this.$vs.loading.close();
        } catch (error) {
          this.messageError(error.response.data.message);
          this.$vs.loading.close();
          console.log(error);
        }
      },

      async findReader() {
        if (this.readerQuery.name.length < 3) {
          return this.messageError('Leitor precisa ter ao menos 3 caracteres');
        }
        
        this.$vs.loading();

        let query = '';
        if(this.readerQuery.name) {
          query = '&name=' + this.readerQuery.name;
        }
        query += `&filter=${this.readerQuery.filter}`;
        query += `&status=active`;

        try {
          const response = await this.$axios.get(`/libraries/readers?${query}`);
          this.readers = response.data;
          this.$vs.loading.close();
        } catch (error) {
          this.messageError(error.response.data.message);
          this.$vs.loading.close();
          console.log(error);
        }
      },

      async store() {
 
        if (!this.form.reader_id) {
          return this.messageError('Selecione um leitor');
        }

        if (!this.form.book_id) {
          return this.messageError('Selecione o livro');
        }
 
        this.$vs.loading();
 
        try {
          const response = await this.$axios.post(`/libraries/waiting-lists`, this.form);
          this.$vs.notify({
            title:'Feito!',
            text: response.data.message,
            color:'success',
            position:'top-right'
          });
          this.$vs.loading.close();

          setTimeout(() => {
            this.$router.push('/lista-espera');
          }, 1500);

        } catch (error) {
          this.messageError(error.response.data.message);
          this.$vs.loading.close();
          console.log(error);
        }
      },
      
      messageError(message) {
        return this.$vs.notify({
          title: 'Ops',
          text: message,
          color:'danger',
          position:'top-right'
        });
      }
    }
  }
 </script>
 