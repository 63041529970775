<template>
   <div class="d-md-flex">
      <SidebarLeft />
      <div class="pages">
       <div class="container-fluid">
         <div class="page-title">
           <h5>Renovar Assinatura</h5> 
           <span>{{ $store.state.library.name }}</span>
         </div>
         <div class="">
           <div class="card" style="padding:20px">
             <div class="row">
               <div class="col-md-12">
                  <div class="text-center">
                     <img src="@/assets/icons/credit-card.svg" alt="" width="70">
                  </div>
                 <div class="text-center mt-3">
                     <p>
                        A sua assinatura 
                        <strong v-if="this.$dayjs(limits.expires_at).isAfter(new Date(), 'day')" class="text-primary">expira em {{ contractExpires }}</strong>
                        <strong v-if="this.$dayjs(limits.expires_at).isSame(new Date(), 'day')" class="text-primary">expira hoje</strong>
                        <strong v-if="this.$dayjs(limits.expires_at).isBefore(new Date(), 'day')" class="text-danger">expirou em {{ contractExpires }}</strong>. <br>
                        Renove agora para continuar
                        administrando a sua biblioteca.
                     </p>
                   
                 </div>

                 <div class="mt-5 col-md-8 mx-auto">
                     <div class="mt-4">
                        <label for="">Escolha um Plano</label>
                        <select 
                           @change="setSelectedPlan($event)" 
                           class="form-select mb-3" aria-label="Selecione o plano">
                           <option 
                                 v-for="plan, index in plans" 
                                 v-bind:key="index"
                                 :value="index">
                                 {{plan.name}}</option>
                        </select>
                     </div>

                     <div class="mt-4">
                        <label>Preço</label>
                        <table class="table table-bordered">
                           <tbody class="text-center">
                              <tr>
                                 <td>Valor</td>
                                 <td>Renovação</td>
                                 <td>Forma de Pagamento</td>
                              </tr>
                              <tr class="fw-bold">
                                 <td>R$ {{ (selectedPlan.final_price).toFixed(2) }}</td>
                                 <td>Mensal</td>
                                 <td>Cartão de crédito</td>
                              </tr>
                           </tbody>
                        </table>
                     </div>

                     <div class="mt-4 table-responsive">
                        <label>Benefícios</label>
                        <table class="table table-bordered">
                           <tbody class="text-center">
                              <tr>
                                 <td>Materiais</td>
                                 <td>Leitores</td>
                                 <td>Todas as funcionalidades</td>
                              </tr>
                              <tr class="fw-bold">
                                 <td>{{ selectedPlan.books }}</td>
                                 <td>{{ selectedPlan.readers }}</td>
                                 <td>✅</td>
                              </tr>
                           </tbody>
                        </table>
                     </div>

                     <div class="mt-4 text-center">
                        <a
                           :href="selectedPlan.purchase_link"
                           type="button"
                           class="btn btn-primary">
                           assine agora</a>
                     </div>
                 </div>

                 <div class="mt-4 col-md-6 mx-auto text-center">
                     <p>Para pagar no boleto ou assinar o plano anual com desconto
                     <span @click="openWhatsApp" class="text-primary fw-bolder" role="button">fale com o nosso atendimento</span></p>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
      </div>
   </div>
 </template>
 
 <script>
   import SidebarLeft from '../../components/SidebarLeft'
 
   export default{
     name: 'RenovarPage',
     components: { SidebarLeft },
      data() {
         return {
            plans: [],
            selectedPlan: {
               id: 4,
               purchase_link: '',
            },
            limits: {
               expires_at: new Date(),
            },
            contract: {
               plan_id: null
            },
         }
      },
 
      mounted() {
         this.fetchPlans();
         this.fetchLastContract();
         this.contract.plan_id = this.selectedPlan.id;

         if (this.$dayjs(this.limits.expires_at).diff(new Date(), 'days') > 60) {
            return this.$router.push('/assinatura');  
         }
      },

      computed: {
         contractExpires() {
           return this.$dayjs(this.limits.expires_at).format('DD/MM/YYYY');
         },

         daysToExpire() { 
            return this.$dayjs(this.limits.expires_at).diff(new Date(), 'days');
         },

         installments() {
            return (this.selectedPlan.duration == 365) ?  12 : 1;
         }
      },
 
      methods: {
         setSelectedPlan(event) {
            this.selectedPlan = this.plans[event.target.value];
            this.contract.plan_id = this.selectedPlan.id;
         },

         openWhatsApp() {
            window.open('https://web.whatsapp.com/send?text=Ol%C3%A1!%20Quero%20contratar%20o%20Libmin&phone=551151963505', '_blank');
         },

         async create() {   
            this.$vs.loading();

            const data = {
               plan_id: this.selectedPlan.id
            };

            try {
            const response = await this.$axios.post(`/libraries/contracts`, data);
            
            this.$vs.notify({
               title:'Feito!',
               text: response.data.message,
               color:'success',
               position:'top-right'
            });
   
            this.$vs.loading.close();

            window.location.href = response.data.payment_link;

            } catch(error) {
               this.$vs.notify({
                  title:'Ops',
                  text: error.response.data.message,
                  color:'danger',
                  position:'top-right'
               });
               this.$vs.loading.close();
            }
         },
         
         async fetchPlans() {
            this.$vs.loading();

            try {
               const response = await this.$axios.get('/libraries/plans');
               this.plans = response.data;
               this.selectedPlan = response.data[0];
               this.$vs.loading.close();

            } catch (error) {
               this.$vs.notify({
                  title:'Ops',
                  text: error.response.data.message,
                  color:'danger',
                  position:'top-right'
               });
               this.$vs.loading.close();
            }
         },

         async fetchLastContract() {
            this.$vs.loading();

            try {
               const response = await this.$axios.get('/libraries/contracts?last');
               this.limits = response.data;

               this.$vs.loading.close();

            } catch (error) {
               this.$vs.notify({
                  title:'Ops',
                  text: error.response.data.message,
                  color:'danger',
                  position:'top-right'
               });
               this.$vs.loading.close();
            }
         }
      },
   }
 </script>