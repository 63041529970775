<template>
  <div>
    <div class="card mb-3 shadow-sm">
      <div class="text-center" style="padding:12px">
        <span class="material-icons-outlined">{{ icon }}</span>
        <p>{{ title }} <br>
        {{used}} / {{ available }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardUsedLimites',
  props: {
    title: {
      type: String,
      required: true
    },
    available: {
      type: String,
      required: true
    },
    used: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
  },
  
}
</script>
