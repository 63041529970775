<template>
  <nav>
    <div class="sidebar-left menu-toggle">
      <div class="logo">
        <router-link to="/home">
          <img src="@/assets/images/logo-white.png" alt="Bibliotecas" width="132">
        </router-link>
      </div>
      <div class="menu">
        <ul class="nav flex-column">
          <li class="nav-item">
            <router-link to="/home" class="nav-link">
              <span class="material-icons-round">grid_view</span>
              <span class="menu_title">Início</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/acervo" class="nav-link">
              <span class="material-icons-round">library_books</span>
              <span class="menu_title">Acervo</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/leitores" class="nav-link">
              <span class="material-icons-round">people</span>
              <span class="menu_title">Leitores</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/emprestimos" class="nav-link">
              <span class="material-icons-round">store</span>
              <span class="menu_title">Empréstimos</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/lista-espera" class="nav-link">
              <span class="material-icons-round">hourglass_top</span>
              <span class="menu_title">Lista de Espera</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/equipe" class="nav-link">
              <span class="material-icons-round">manage_accounts</span>
              <span class="menu_title">Equipe</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/colecoes" class="nav-link">
              <span class="material-icons-round">post_add</span>
              <span class="menu_title">Coleções</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/bibliotecas/editar" class="nav-link">
              <span class="material-icons-round">apartment</span>
              <span class="menu_title">Editar Biblioteca</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/assinatura" class="nav-link">
              <span class="material-icons-round">credit_card</span>
              <span class="menu_title">Assinatura</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/meus-dados" class="nav-link">
              <span class="material-icons-round">file_download</span>
              <span class="menu_title">Dados e Relatórios</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/ajuda" class="nav-link">
              <span class="material-icons-round">support</span>
              <span class="menu_title">Preciso de Ajuda</span>
            </router-link>
          </li>
          <hr style="color:#fff; width:70%; margin: 0 auto; margin-bottom:20px; margin-top:20px" />
          <li class="nav-item">
            <a href @click.prevent="logout" class="nav-link">
              <span class="material-icons-round">logout</span>
              <span class="menu_title">Sair</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <button v-if="isMobile" class="btn btn-light openMenu" @click="openMenu()">
      <span class="material-icons-outlined">menu</span>
    </button>

    <div v-if="!isCurrent" class="dropdown">
      <a 
        class="btn-circle btn-primary" 
        id="dropdownMenuButton1" 
        data-bs-toggle="dropdown" 
        aria-expanded="false"
        style="cursor:pointer; z-index:1"
      >
        <span class="material-icons-round">add</span>
      </a>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li><router-link 
            class="dropdown-item" 
            :to="{ path: '/acervo/cadastrar'}">
            Novo Livro</router-link></li>
          <li><router-link 
            class="dropdown-item" 
            :to="{ path: '/acervo/digital/cadastrar'}">
            Novo Material Digital</router-link></li>
        <li><router-link 
            class="dropdown-item" 
            :to="{ path: '/leitores/cadastrar'}">
            Novo Leitor</router-link></li>
        <li><router-link 
            class="dropdown-item" 
            :to="{ path: '/emprestimos/cadastrar'}">
            Novo Empréstimo</router-link></li>
          <li><router-link 
            class="dropdown-item" 
            :to="{ path: '/colecoes/criar'}">
            Nova Coleção</router-link></li>
          <li><router-link 
            class="dropdown-item" 
            :to="{ path: '/equipe/criar'}">
            Novo Assistente</router-link></li>
          <li><router-link 
            class="dropdown-item" 
            :to="{ path: '/lista-espera/cadastrar'}">
            Adicionar à lista de espera</router-link></li>
        <li><router-link 
            class="dropdown-item" 
            to="/acervo/etiquetas">
            Imprimir Etiquetas</router-link></li>
      </ul>
    </div>
  </nav>
</template>

<script>
  export default{
    data() {
      return {
        menuClass: false,
        isMobile: true,
        isCurrent: false,
      }
    },

    created() {
      this.isMobile = this.isMobileFunction();
    },

    mounted() {
      if(this.isMobile) {
        this.openMenu();
      }
      if(this.$route.name === 'acervo-cadastrar') {
        this.isCurrent = true;
      }
    },
    
    computed: {
      currentUser() {
        return this.$store.state.auth.user;
      },

      showAdminBoard() {
        if (this.currentUser && this.currentUser.roles) {
          return this.currentUser.roles.includes('ROLE_ADMIN');
        }

        return false;
      },

      showModeratorBoard() {
        if (this.currentUser && this.currentUser.roles) {
          return this.currentUser.roles.includes('ROLE_MODERATOR');
        }

        return false;
      },
    },

    methods: {
      logout() {
        localStorage.clear();
        this.$store.commit('logout');
        this.$router.push('/');
      },
      
      openMenu() {
        //if(this.menuClass == false) {
          let element = document.querySelector(".menu-toggle");
          element.classList.toggle("d-none");
        //}
      },

      isMobileFunction() {
        return ( window.innerWidth <= 760 )
      }
    }

  }
</script>

<style lang="scss">
  .sidebar-left{
    width: 294px;
    min-height: 100vh;
    height: 100%;
    background-color: #002979;
    position: relative;

    .logo{
      width: 132px;
      padding: 40px 0;
      margin: 0 2.5rem;
    }

    .menu{
      .nav-item {
        font-size: 20px;
      }

      .router-link-active{
        background: rgba(0,41,121,1);
        background: linear-gradient(90deg, rgba(255,255,255,1) 2%, rgba(0,41,121,1) 2%, rgba(0,41,121,1) 100%);
      }
      
      .nav-link{
        color: #fff;
        // padding: 0.4rem 2.5rem;
        
        .menu_title{
          position: relative;
          top: -5px;
          left: 10px;
        }
      }
    }
  }
  
  .openMenu {
    box-shadow: 2px 2px #888888;
  }
</style>