<template>
  <div class="d-md-flex">
    <SidebarLeft />
    <div class="pages">
      <div class="container-fluid">
        <div class="page-title">
          <h5>Assinatura</h5>
          <span>Detalhes do seu plano</span>
        </div>
        
        <div class="top-30"></div>

        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div v-if="contract.id != null" class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Plano</th>
                      <th scope="col">Início</th>
                      <th scope="col">Valor</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ contract.plan.name }}</td>
                      <td>
                        {{ $dayjs(contract.created_at).format('DD/MM/YYYY') }}
                      </td>
                      <td>R$ {{ (contract.final_price).toFixed(2) }}</td>
                      <td>{{ paymentStatusTranslate(contract.status) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else class="p-5">
                <div class="text-center">
                  Nenhum material encontrado
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import SidebarLeft from '../../components/SidebarLeft'

  export default{
    name: 'AssinaturaComponent',
    components: {
    SidebarLeft,
},
    data() {
      return {
        contract: {
          plan: {
            name: ''
          },
          expires_at: ''
        },
      }
    },

    created() {
      this.getLimits();
    },

    computed: {
      contractExpire() {
        return this.$dayjs(this.contract.expires_at).format('DD/MM/YYYY');
      },
      daysToExpire() { 
        return this.$dayjs(this.contract.expires_at).diff(new Date(), 'days');
      }
    },

    methods: {
      async getLimits() {
        this.$vs.loading();
        
        try{
          const response = await this.$axios.get(`/libraries/contracts?last`);
          this.contract = response.data;

        } catch (error) {
          this.$vs.notify({
            title: 'Erro',
            text: error.response.data.message,
            color: 'danger',
            position: 'top-right',
          });
        } finally {
          this.$vs.loading.close();
        }
      },

      paymentStatusTranslate(status) {
        switch (status) {
          case 1: return 'Pagamento Pendente';
          case 2: return 'Pago';
          case 3: return 'Cancelado';
        }
      }
    }
    
  }
</script>