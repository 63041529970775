<template>
  <div class="d-flex">
    <SidebarLeft />
    <div class="pages">
      <div class="container-fluid">
        <div class="page-title">
          <h5>Matrícula de Leitor</h5>
          <span>{{ $store.state.library.name }}</span>
        </div>
        <div class="row mb-4">
          <div class="card" style="padding:20px">

            <div class="row">
              
              <div class="col-md-6 mb-3">
                <label class="text-danger mb-1" for="">Nome do leitor</label>
                <input
                  class="form-control"
                  v-model="reader.name"
                  placeholder="Nome"
                  aria-label="Nome">
              </div>

              <div class="col-md-6 mb-3">
                <label class="mb-1" for="">Documento (opcional)</label>
                <input
                  class="form-control"
                  v-model="reader.document"
                  placeholder="RG, CPF, Matrícula ou outro"
                  aria-label="CPF">
              </div>

              <div class="col-md-6 mb-3">
                <label class="mb-1" for="">Email (opcional)</label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="Email"
                  v-model="reader.email">
              </div>

              <div class="col-md-6 mb-3">
                <label class="mb-1" for="">Telefone (opcional)</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Telefone"
                  v-model="reader.phone"
                  v-mask="'(##) #####-####'"
                  aria-label="Telefone">
              </div>
              
              <div class="col-md-6 mb-3">
                <label class="text-danger mb-1" for="">Crie um nome de usuário
                  <span class="tooltip-multiline"
                    data-tooltip="Pode ser qualquer nome (desde que ninguém já esteja usando).">ⓘ</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  v-model="reader.username"
                  placeholder="Ex: joao@minhabiblioteca">
              </div>

              <div class="col-md-6 mb-3">
                <label class="text-danger mb-1" for="">Crie uma senha
                  <span class="tooltip-multiline"
                    data-tooltip="A senha deve possuir pelo menos 5 caracteres. Sugerimos uma automaticamente.">ⓘ</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  v-model="reader.password">
              </div>

              <div class="col-md-6 mb-4">
                <label class="text-danger mb-1" for="">Tipo de acesso</label>
                <select
                  class="form-select"
                  v-model="reader.type_reader">
                  <option value="1">Comum (leitor padrão)</option>
                  <option value="2">Interno (equipe, professor, colaborador, etc)</option>
                </select>
              </div>

              <div class="col-md-12 mb-5">
                <button
                    @click="saveOn"
                    class="btn btn-primary px-4 btn-rounded">Matricular</button>
              </div>

              <div class="row">
                <div class="col-md-6 mb-2">
                  <span class="badge rounded-pill bg-primary me-2"> Info <span class="visually-hidden">nova funcionalidade</span></span>
                  Se o leitor não possuir um email ou telefone cadastrados, deverá obrigatoriamente usar o app da biblioteca para autorizar os empréstimos.
                </div>
                <div class="col-md-6 mb-2">
                  <span class="badge rounded-pill bg-primary me-2"> Info <span class="visually-hidden">nova funcionalidade</span></span>
                  Se você preferir, pode desativar a exigência do código de liberação dos empréstimos. Entre em contato com o nosso suporte.
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import SidebarLeft from '../../components/SidebarLeft.vue'

  export default{
    name: 'LeitoresCadastrarComponent',
    components: {
      SidebarLeft,
    },

    data() {
      return {
        reader: {
          email: '',
          name: '',
          phone: '',
          document: '',
          username: '',
          password: '',
          type_reader: '1',
        },
      }
    },

    watch: {
      'reader.name': 'setUsername',
      'reader.document': 'setUsername',
    },

    mounted() {
      this.passwordGenerate();
    },

    methods: {
      async saveOn() {

        if (this.reader.name.length < 3) {
          return this.messageError('Nome obrigatório');
        }

        if (this.reader.phone.length > 1 && this.reader.phone.length < 11) {
          return this.messageError('Telefone incorreto');
        }

        if (this.reader.username.length < 5) {
          return this.messageError('Usuário precisa ter pelo menos 5 caracteres');
        }

        if (this.reader.password.length < 5) {
          return this.messageError('Senha precisa ter pelo menos 5 caracteres');
        }

        if (!this.checkIfUsernameIsValid()) {
          return this.messageError('O nome de usuário não pode ter espaços');
        }

        this.$vs.loading();

        try {
          const response = await this.$axios.post(`/libraries/readers`, this.reader);
          this.$vs.notify({
            title:'Feito!',
            text: response.data.message,
            color:'success',
            position:'top-right'
          });
          this.$vs.loading.close();
          this.$router.push('/leitores');

        } catch (error) {
          this.messageError(error.response.data.message);
          this.$vs.loading.close();
          console.log(error);
        }
      },

      messageError(message) {
        return this.$vs.notify({
          title: 'Ops',
          text: message,
          color:'danger',
          position:'top-right'
        });
      },
      
      setUsername() {
        // Divide o nome em partes e pega a primeira parte, convertendo para minúsculas
        let name = this.reader.name.split(' ')[0].toLowerCase();
        // Remove caracteres não alfabéticos
        name = name.replace(/[^a-zA-Z]/g, '');
        // Remove caracteres não numéricos do documento e pega os últimos 2 dígitos
        let document = this.reader.document.replace(/[^0-9]/g, '');
        document = document.slice(-2);
        // Cria o nome de usuário base
        let username = `${name}${document}@${this.$store.state.library.code.toLowerCase()}`;
        this.reader.username = username;
      },

      checkIfUsernameIsValid() {
        if (this.reader.username.includes(' ')) {
          return false;
        }
        return true;
      },

      passwordGenerate() {
        let password = Math.random().toString(36).slice(-5);
        this.reader.password = password;
      },
    }
  }
</script>
