<template>
   <div class="d-flex">
      <SidebarLeft />
      <div class="pages">
         <div class="container-fluid">
            <div class="page-title">
               <h5>Conceder Empréstimo</h5> 
               <span>{{ $store.state.library.name }}</span>
            </div>
            
            <div class="row mb-3">
               <div class="col-md-12 mb-4">
                  <div class="card p-4">
                     <form @submit.prevent="findBook">
                        <div class="fw-bolder mb-2">1º passo</div>
                        <label for="title">Nome (ou parte) do livro</label>
                        <div class="input-group">
                           <input 
                              v-model="bookQuery.title"
                              type="text" 
                              class="form-control me-1" 
                              aria-describedby="basic-addon2">
                           <div class="input-group-append">
                              <button 
                              @click="findBook"
                              class="btn btn-outline-primary" type="button">Buscar</button>
                           </div>
                        </div>
                     </form>

                     <div v-if="books.length > 0">
                        <div 
                           v-for="book in books" :key="book.id" 
                           class="my-2 px-2 fw-light"
                           role="button"
                           @click="setBookId(book)"
                           :style="{
                              borderRadius: '8px',
                              backgroundColor: (book.id == form.book_id) 
                              ? '#CCC' 
                              : '#FFF'}">
                           {{ book.title }} - {{ book.author }} | Exemplares: {{book?.copies}}
                           <span v-if="!book?.available" class="badge rounded-pill bg-danger text-white px-2 ms-2">Indisponível para empréstimo</span>
                           <div class="border-bottom mt-2"></div>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="col-md-12 mb-4">
                  <div class="card p-4">
                     
                     <div v-for="(itemInList, index) in copyNumbersWithWaitingList" :key="index"
                        class="alert alert-danger d-flex align-items-center alert-dismissible fade show mb-3" role="alert" style="border-radius: 8px;">
                        <span class="material-icons-round">warning</span>
                        <div class="mt-1 ms-2">
                           <span class="fw-bolder">{{ itemInList }}</span> já reservou este material.
                        </div>
                        <button type="button" class="btn-close mt-1" style="height: 10px;" data-bs-dismiss="alert" aria-label="Close"></button>
                     </div>
                     
                     <div class="fw-bolder mb-2">2º passo</div>
                     <div v-if="listCopies.length > 0">
                        <label for="title">Selecione o exemplar</label>
                        <select v-model="form.copy_id" class="form-select">
                           <option v-for="copy in listCopies" :key="copy.id" :value="copy.id">
                              {{ copy.copy_number }}
                           </option>
                        </select>
                     </div>
                     <div v-else>
                        <label for="title">Selecione o exemplar</label>
                        <select v-model="form.copy_id" class="form-select">
                           <option v-for="i in totalCopies" :key="i" :value="i">{{ i }}</option>
                        </select>
                     </div>
                  </div>
               </div>
               
               <div class="col-md-12 mb-4">
                  <div class="card p-4">
                     <form @submit.prevent="findReader">
                        <div class="fw-bolder mb-2">3º passo</div>
                        <label for="reader" class="mb-1">Nome do leitor</label>
                        <div class="input-group">
                           <input 
                              v-model="readerQuery.name"
                              type="text" 
                              class="form-control me-1" 
                              aria-describedby="basic-addon2">
                           <div class="input-group-append">
                              <button 
                              @click="findReader"
                              class="btn btn-outline-primary" type="button">Buscar</button>
                           </div>
                        </div>
                     </form>
                      <div class="mt-3">
                        <div v-for="reader in readers" :key="reader.id" class="form-check mb-2">
                          <input 
                            class="form-check-input" 
                            type="radio" 
                            :value="reader.user_id" 
                            v-model="form.user_id" 
                            :id="`reader-${reader.id}`">
                          <label class="form-check-label text-dark" :for="`reader-${reader.id}`">
                            <div>{{ reader.user.name }} - {{ reader.user.external_id ?? reader.user.document }}</div>
                            <div>{{ reader.custom_description?.slice(0, 160) ?? reader.user.username }}</div>
                          </label>
                        </div>
                      </div>
                  </div>
               </div>
            
               <div class="col-md-12 mb-4">
                  <div class="card p-4">
                     <div class="fw-bolder mb-2">
                        4º passo (opcional)
                        <span class="tooltip-multiline" data-tooltip="Este é um campo para anotações adicionais. O leitor pode ver esta informação.">ⓘ</span>
                     </div>
                     <textarea v-model="form.annotations" 
                        class="form-control"
                        rows="3" 
                        placeholder="Se houver necessidade de adicionar alguma informação para este empréstimo, este é o local.">
                     </textarea>
                  </div>
               </div>

            
               <div class="col-md-12">
                  <div class="card p-4">
                     <div class="fw-bolder mb-2">5º passo</div>
                     <p>Ao confirmar, o seu leitor receberá o código de liberação no e-mail e no aplicativo dele.</p>
                     <div>
                        <button @click="saveOn" class="btn btn-primary" type="button">Confirmar empréstimo</button>
                     </div>
                  </div>
               </div>
            </div>
         
         </div>
      </div>
   </div>
</template>
 
<script>
  import SidebarLeft from '../../components/SidebarLeft.vue'

  export default{
    name: 'EmprestimosCadastrarPage',
    components: {
      SidebarLeft,
    },
 
    data() {
      return {
         form: {
            book_id: '',
            user_id: '',
            copy_id: '',
            annotations: '',
         },
         totalCopies: 0,
         listCopies: [],
         bookQuery: {
            title: '',
            filter: 'title',
         },
         books: [],
         readerQuery: {
            name: '',
            filter: 'name',
         },
         readers: [],
         copyNumbersWithWaitingList: [],
      }
    },

    mounted() {
      this.findBookById();
    },

    methods: {
      async saveOn() {  
         if (this.form.user_id.length < 1 || this.form.book_id.length < 1 || this.form.copy_id.length < 1) {   
            this.$vs.notify({
               title:'Ops',
               text: 'Informe os campos corretamente',
               color:'danger',
               position:'top-right'
            });
            return null;
         }
         
         this.$vs.loading();
        
         try {
            const response = await this.$axios.post(`/libraries/loans`, this.form);
            this.$vs.notify({
               title:'Feito!',
               text: response.data.message,
               color:'success',
               position:'top-right'
            });
            this.$vs.loading.close();
            if (response.data.data.status === 'authorized') {
               this.$router.push(`/emprestimos/${response.data.data.id}/ficha`);
               return;
            }
            this.$router.push('/emprestimos');
            
         } catch (error) {
            this.$vs.notify({
               title:'Ops',
               text: error.response.data.message ?? 'Error',
               color:'danger',
               position:'top-right'
            });
            this.$vs.loading.close();
            console.log(error);
         }
      },

      async findReader() {
         
        this.$vs.loading();
        let query = '';
        if(this.readerQuery.name) {
          query = '&name=' + this.readerQuery.name;
        }
        query += `&filter=${this.readerQuery.filter}`;
        query += `&status=active`;
        
         try {
            const response = await this.$axios.get(`/libraries/readers?${query}`);
            this.readers = response.data;
            this.$vs.loading.close();
         
         } catch(error) {
            this.$vs.notify({
               title:'Ops',
               text: error.response.data?.message ?? 'Erro',
               color:'danger',
               position:'top-right'
            });
            this.$vs.loading.close();
         }
      },

      async findBook() {
        
        if(this.bookQuery.title.length < 3) {
          return null;
        }

        this.$vs.loading();

        this.$axios.get(`/libraries/books?filter=title&name=${this.bookQuery.title}`)
        .then(response => {
          this.books = response.data;
          this.$vs.loading.close();

        }).catch(err => {
            this.$vs.notify({
              title: 'Erro',
              text: err.response.data.message ?? 'Error',
              color:'danger',
              position:'top-right'
            })
        })

        this.$vs.loading.close();
      },

      async findBookById() {
        
         if(!this.$route.query.book_id) {
            return null;
         }

         this.$vs.loading();

         this.$axios.get(`/libraries/books?filter=book_id&name=${this.$route.query.book_id}`)
         .then(response => {
            if (response.data.length < 1) {
               this.$vs.notify({
                  title:'Ops',
                  text: 'Exemplar não localizado. Preencha os passos abaixo',
                  color:'warning',
                  position:'top-right'
               });
               return null;
            }
            this.books = response.data;
            this.form.book_id = response.data[0].id;
            this.listCopies = response.data[0].list_copies;
            this.waitingList = response.data[0].waiting_list;
            this.form.copy_id = this.listCopies.find(element => element.id == this.$route.query.copy_id).id ?? null;
            this.findBooksInWaitingLists();
            this.$vs.loading.close();

         }).catch(err => {
            this.$vs.loading.close();
            console.log(err);
            this.$vs.notify({
              title: 'Erro',
              text: err.response?.data.message ?? 'Error generic',
              color:'danger',
              position:'top-right'
            })
        })
      },

      setBookId(book) {
         if (book.available === false) {
            this.$vs.notify({
               title:'Ops',
               text: 'Você não pode emprestar este material',
               color:'danger',
               position:'top-right'
            });
            return null;
         }
         this.form.book_id = book.id;
         this.totalCopies = book.copies;
         this.listCopies = book.list_copies;
      },

      findBooksInWaitingLists() {
         this.copyNumbersWithWaitingList = [];
         for (const item of this.waitingList) {
            if (item.reader_id) {
               this.copyNumbersWithWaitingList.push(item.reader?.user?.name ?? 'Leitor');
            }
         }
      }
   }
}
 </script>
 