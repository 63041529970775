<template>
  <div class="main-login">
    <div class="container top-60">
      <div class="col-sm-12 text-center ">
        <img src="@/assets/images/logo.png" alt="Logo Bibliotecas" width="160px">
      </div>
    </div>
    <div class="mt-60" style="margin-top:60px">
      <div class="container card-login">
        <div class="row top-15">
          <div class="col-md-6 d-sm-none d-md-block">
            <div class="text-center">
              <img src="@/assets/images/password-recovery.jpg" alt="" width="330">
            </div>
          </div>
          <div class="col-md-6">
            <div class="card-login-right">
              <h1>Recuperar Acesso</h1>
              <form v-if="!success" @submit.prevent="passwordRecovery" class="top-30">
                <div class="mb-3">
                  <label for="email" class="form-label">Seu E-mail</label>
                  <input 
                    v-model="user.email"
                    type="email" 
                    class="form-control" 
                    name="email" 
                    v-validate="'required'"
                    placeholder="seu-email@gmail.com" />
                </div>
                
                <div class="mb-4 d-grid">
                  <button :disabled="loading" class="btn btn-primary" type="submit">{{textBtnEntrar}}</button>
                </div>
              </form>

              <div class="text-center">
                <span class="text-danger mt-3">{{message}}</span>
              </div>

              <div class="row mb-3 top-15">
                <div class="col-md-12 text-center">
                  <a v-on:click="toIndex" style="cursor:pointer; color:#9c9c9c; font-weight:400; text-decoration:none">voltar para o login</a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import User from '../../models/user';

export default {
  name: 'PasswordRecoveryComponent',

  data() {
    return {
      user: new User('', ''),
      loading: false,
      message: '',
      textBtnEntrar: 'Enviar',
      success: false,
    };
  },

  computed: {
    loggedIn() {
      return this.$store.state.userLogged;
    }
  }, 

  created() {
    if (this.loggedIn) {
      this.$router.push('/home');
    }
  },

  methods: {
    passwordRecovery() {
      this.textBtnEntrar = 'Aguarde...'; 
      this.loading = true;

      if (this.user.email.length == 0) {
        this.message = 'Por favor, informe seu e-mail';
        this.loading = false;
        this.textBtnEntrar = 'Enviar';
        return;
      }

      if (this.user.email) {
        axios.post(
          `${process.env.VUE_APP_API_URL}/libraries/auth/forgot-password`,
          { "email": this.user.email}
        ).then(response => {
          this.loans = response.data
          this.message = response.data.message
          this.textBtnEntrar = "Enviado"
          this.user.email = ''
          this.success = true;
        }).catch(( err )=>  {
          this.message = err.response.data.error
          this.textBtnEntrar = "Tente novamente"
          this.loading = false;
          this.success = false;
        });
      }
    },

    toIndex() {
      this.$router.push('/')
    }
  }
};
</script>

<style lang="scss" src="./style.scss" />