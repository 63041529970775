<template>
   <div class="d-md-flex">
      <SidebarLeft />
      <div class="pages">
       <div class="container-fluid">
         <div class="page-title">
           <h5>Planos Libmin</h5> 
           <span>{{ $store.state.library.name }}</span>
         </div>
         <div class="">
           <div class="card" style="padding:20px">
             <div class="mt-4">
               <div class="row">
                  <div 
                     v-for="plan in plans" :key="plan.id" 
                     class="col-md-4 mb-3 text-center">
                     <div class="p-4" style="border: 1px solid #ccc; border-radius:12px">
                        <div class="fw-bolder" style="font-size: 22px;">{{ plan.name }}</div>
                        <div class="my-1" style="font-size: 18px;">R$ {{ (plan.price) }}/mês</div>
                        
                        <div class="mt-3 text-start">
                           <div class="mb-1">
                              <span class="material-icons-outlined text-success" style="font-size:16px">done</span>
                              {{ plan.books }} livros</div>
                           <div class="mb-1">
                              <span class="material-icons-outlined text-success" style="font-size:16px">done</span>
                              {{ plan.readers }} leitores
                           </div>
                           <div class="mb-1">
                              <span class="material-icons-outlined text-success" style="font-size:16px">done</span>
                              App para os leitores
                           </div>
                           <div class="mb-1">
                              <span class="material-icons-outlined text-success" style="font-size:16px">done</span>
                              Pagamento mensal
                           </div>
                        </div>

                        <div class="mt-4">
                           <a 
                              :href="plan.purchase_link"
                              type="button"
                              class="btn btn-sm btn-primary">
                              assine agora</a>
                        </div>
                     </div>
                  </div>

                 <div class="mt-4 col-md-6 mx-auto text-center">
                     <p>Para pagar com boleto ou assinar o plano anual com desconto fale com o nosso 
                     <span @click="openWhatsApp" class="text-primary fw-bolder" role="button">atendimento no WhatsApp</span></p>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
      </div>
   </div>
 </template>
 
 <script>
   import SidebarLeft from '../../components/SidebarLeft'
 
   export default{
     name: 'RenovarPage',
     components: { SidebarLeft },
      data() {
         return {
            plans: [],
            selectedPlan: {
               id: 4,
               purchase_link: '',
            },
            limits: {
               expires_at: new Date(),
            },
            contract: {
               plan_id: null
            },
         }
      },
 
      mounted() {
         this.fetchPlans();
         this.fetchLastContract();
         this.contract.plan_id = this.selectedPlan.id;

         if (this.$dayjs(this.limits.expires_at).diff(new Date(), 'days') > 60) {
            return this.$router.push('/assinatura');  
         }
      },

      computed: {
         contractExpires() {
           return this.$dayjs(this.limits.expires_at).format('DD/MM/YYYY');
         },

         daysToExpire() { 
            return this.$dayjs(this.limits.expires_at).diff(new Date(), 'days');
         },

         installments() {
            return (this.selectedPlan.duration == 365) ?  12 : 1;
         }
      },
 
      methods: {
         setSelectedPlan(event) {
            this.selectedPlan = this.plans[event.target.value];
            this.contract.plan_id = this.selectedPlan.id;
         },

         openWhatsApp() {
            window.open('https://web.whatsapp.com/send?text=Ol%C3%A1!%20Quero%20contratar%20o%20Libmin&phone=551151963505', '_blank');
         },

         async create() {   
            this.$vs.loading();

            const data = {
               plan_id: this.selectedPlan.id
            };

            try {
            const response = await this.$axios.post(`/libraries/contracts`, data);
            
            this.$vs.notify({
               title:'Feito!',
               text: response.data.message,
               color:'success',
               position:'top-right'
            });
   
            this.$vs.loading.close();

            window.location.href = response.data.payment_link;

            } catch(error) {
               this.$vs.notify({
                  title:'Ops',
                  text: error.response.data.message,
                  color:'danger',
                  position:'top-right'
               });
               this.$vs.loading.close();
            }
         },
         
         async fetchPlans() {
            this.$vs.loading();

            try {
               const response = await this.$axios.get('/libraries/plans');
               this.plans = response.data;
               this.selectedPlan = response.data[0];
               this.$vs.loading.close();

            } catch (error) {
               this.$vs.notify({
                  title:'Ops',
                  text: error.response.data.message,
                  color:'danger',
                  position:'top-right'
               });
               this.$vs.loading.close();
            }
         },

         async fetchLastContract() {
            this.$vs.loading();

            try {
               const response = await this.$axios.get('/libraries/contracts?last');
               this.limits = response.data;

               this.$vs.loading.close();

            } catch (error) {
               this.$vs.notify({
                  title:'Ops',
                  text: error.response.data.message,
                  color:'danger',
                  position:'top-right'
               });
               this.$vs.loading.close();
            }
         }
      },
   }
 </script>